import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from 'core/elements/button'
import Text from './Text'
import { prop, indexBy } from 'ramda'
import ListTable from 'core/components/listTable/ListTable'
import Theme from 'core/themes/model'

const columns = [{ id: 'name', label: 'Name' }]

const useStyles = makeStyles<Theme>((theme) => ({
  listWrapper: {
    display: 'grid',
    gridTemplateColumns: '382px auto 382px',
    justifyContent: 'space-between',
    minHeight: '300px',
  },
  search: {
    display: 'grid',
    gridGap: '15px',
    alignContent: 'flex-start',
  },
  listButtons: {
    flexDirection: 'column',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}))

const renderItemList = (data, handleToggle, itemType) => {
  return (
    <ListTable
      showCheckboxes
      multiSelection
      uniqueIdentifier={'uuid'}
      columns={columns}
      data={data}
      searchTargets={['name']}
      onSelect={(value) => handleToggle(value, itemType)}
      onSelectAll={(value) => handleToggle(value, itemType)}
    />
  )
}

interface CheckedItems<T> {
  left: { [key: string]: T }
  right: { [key: string]: T }
}
interface Props<T> {
  list: T[]
  onUpdate: (items: T[]) => void
}

enum ItemTypes {
  Left = 'left',
  Right = 'right',
}

export default function TransferList<T extends { uuid: string }>({ list, onUpdate }: Props<T>) {
  const classes = useStyles({})
  const [checked, setChecked] = React.useState<CheckedItems<T>>({
    left: {},
    right: {},
  })
  const [left, setLeft] = React.useState<CheckedItems<T>[ItemTypes.Left]>(
    indexBy(prop('uuid'), list),
  )
  const [right, setRight] = React.useState<CheckedItems<T>[ItemTypes.Right]>({})

  const handleToggle = (value, itemType) => {
    // convert input to array
    const items = Array.isArray(value) ? value : [value]
    const newChecked = { ...checked }
    if (items.length === 0) {
      newChecked[itemType] = {}
    } else {
      items.forEach((item) => {
        if (newChecked[itemType][item.uuid]) {
          delete newChecked[itemType][item.uuid]
        } else {
          newChecked[itemType][item.uuid] = true
        }
      })
    }
    setChecked(newChecked)
  }

  const handleMoveCheckedFromOneSide = (from, to) => {
    const itemsBySide = {
      right: { ...right },
      left: { ...left },
    }
    const itemsToMove = checked[from]
    for (const id of Object.keys(itemsToMove)) {
      itemsBySide[to][id] = itemsBySide[from][id]
      delete itemsBySide[from][id]
    }

    setRight(itemsBySide.right)
    setLeft(itemsBySide.left)
    setChecked({ ...checked, [from]: {} })

    onUpdate(Object.values(itemsBySide.right))
  }

  const rightItemsToRender = useMemo(() => Object.values(right), [right])
  const leftItemsToRender = useMemo(() => Object.values(left), [left])

  return (
    <div className={classes.listWrapper}>
      <div className={classes.search}>
        <Text>Select the nodes to include in this batch</Text>
        {renderItemList(leftItemsToRender, handleToggle, ItemTypes.Left)}
      </div>
      <div className={classes.listButtons}>
        <Button
          className={classes.button}
          onClick={() => handleMoveCheckedFromOneSide(ItemTypes.Left, ItemTypes.Right)}
          disabled={Object.getOwnPropertyNames(checked.left).length === 0}
          aria-label="move selected right"
          type="button"
        >
          &gt;
        </Button>
        <Button
          className={classes.button}
          onClick={() => handleMoveCheckedFromOneSide(ItemTypes.Right, ItemTypes.Left)}
          disabled={Object.getOwnPropertyNames(checked.right).length === 0}
          aria-label="move selected left"
          type="button"
        >
          &lt;
        </Button>
      </div>
      <div className={classes.search}>
        <Text> Selected nodes</Text>
        {renderItemList(rightItemsToRender, handleToggle, ItemTypes.Right)}
      </div>
    </div>
  )
}
