import { AppPlugins, CustomerTiers } from 'app/constants'
import { pathStrOr } from 'utils/fp'
import { sessionStoreKey } from 'core/session/sessionReducers'
import store from 'app/store'

export const isDecco = (features) => pathStrOr(false, 'experimental.kplane', features)

export const isDeccoEnv = () => {
  const state = store.getState()
  const { features } = state[sessionStoreKey]
  return isDecco(features)
}

export const isProductionEnv = process.env.NODE_ENV === 'production'

export const showZendeskChatOption = (lastStack, customerTier) =>
  (lastStack === AppPlugins.Kubernetes || lastStack === AppPlugins.MyAccount) &&
  customerTier === CustomerTiers.Enterprise &&
  isProductionEnv

export const convertListOfObjectsToMap = (key: string, objects: any[]) => {
  const result = new Map()
  for (const obj of objects) {
    if (obj[key]) {
      result.set(obj[key], obj)
    }
  }
  return result
}

export const devEnabled = () => window.localStorage.enableDevPlugin === 'true'
