import { preferencesActions } from 'core/session/preferencesReducers'
import config from 'app-config'

const urlBase = process.env.NODE_ENV !== 'production' ? config.apiHost : ''

const maybeUpdateLogo = (overridePrimaryLogo, overridePrimaryFavicon) => (dispatch) => {
  if (!overridePrimaryLogo) {
    return
  }
  const newFavicon = `${urlBase}${overridePrimaryFavicon}`
  const newLogo = `${urlBase}${overridePrimaryLogo}`
  dispatch(
    preferencesActions.updateLogo({
      logoUrl: newLogo,
      faviconUrl: newFavicon,
    }),
  )
}
export default maybeUpdateLogo
