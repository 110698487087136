import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'

import { formatDate } from 'utils/misc'

import SubmitButton from 'core/components/buttons/SubmitButton'
import DisplayKeyValues from 'core/components/DisplayKeyValues'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import CodeBlock from '../CodeBlock'
import CopyToClipboard from '../CopyToClipboard'
import SimpleLink from '../SimpleLink'
import { stopPropagation } from 'utils/fp'
import Modal from 'core/elements/modal'
import Button from 'core/elements/button'

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    display: 'grid',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  header: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr max-content',
  },
  title: {
    color: theme.palette.blue.main,
  },
  hr: {
    height: 1,
    width: '100%',
    backgroundColor: theme.components.card.border,
    margin: theme.spacing(2, 0),
  },
}))

enum NotificationViewTypes {
  Parsed = 'Parsed',
  Raw = 'Raw',
}

export default function NotificationDetails({ notification, onClose }) {
  const [nextViewType, setNextViewType] = useState(NotificationViewTypes.Raw)
  const classes = useStyles()
  const upperValues = [
    { key: 'Time', value: formatDate(notification.date) },
    {
      key: 'Status',
      value: notification?.data?.response?.status,
    },
    {
      key: 'Status Text',
      value: notification?.data?.response?.statusText,
    },
    { key: 'URL', value: notification?.responseConfig?.url },
    { key: 'Method', value: `${notification?.responseConfig?.method}`.toUpperCase() },
    { key: 'Summary', value: notification.message },
  ]
  /*
  {
      key: 'Stacktrace',
      value: notification?.data?.stack,
      render: (value) => <CodeBlock>{value}</CodeBlock>,
    },
  */
  const lowerValues = [
    {
      key: 'Cluster Name',
      value: notification?.cluster?.name || 'N/A',
    },
    { key: 'Cluster Uuid', value: notification?.cluster?.uuid || 'N/A' },
    { key: 'Resource', value: notification?.k8sResource || 'N/A' },
    { key: 'Resource Name', value: notification?.k8sResourceName || 'N/A' },
  ]
  const handleToggle = useCallback(() => {
    if (nextViewType === NotificationViewTypes.Parsed) {
      setNextViewType(NotificationViewTypes.Raw)
    } else {
      setNextViewType(NotificationViewTypes.Parsed)
    }
  }, [nextViewType])
  return (
    <Modal
      open
      panel="dialog"
      onClose={onClose}
      maxWidth={900}
      title={notification?.title}
      footer={<SubmitButton onClick={onClose}>Close</SubmitButton>}
      info={
        <div className={classes.infoContainer}>
          {nextViewType !== NotificationViewTypes.Raw && (
            <CopyToClipboard
              copyText={JSON.stringify(notification?.rawNotification, null, 2)}
              inline
              codeBlock={false}
              triggerWithChild
            >
              <Button type="button" icon="copy">
                Copy
              </Button>
            </CopyToClipboard>
          )}
          <SimpleLink src={null} onClick={handleToggle} icon="search">
            View {nextViewType}
          </SimpleLink>
        </div>
      }
    >
      {/* {nextViewType !== NotificationViewTypes.Parsed && <div className={classes.hr} />} */}
      {nextViewType !== NotificationViewTypes.Parsed && (
        <article className={classes.content}>
          <DisplayKeyValues keyValuePairs={upperValues} />
          <div className={classes.hr} />
          <DisplayKeyValues keyValuePairs={lowerValues} />
          <br />
          <CodeBlock overflow fill>
            {JSON.stringify(notification?.responseData, null, 2)}
          </CodeBlock>
        </article>
      )}
      {nextViewType !== NotificationViewTypes.Raw && (
        <article className={classes.content}>
          {/* <CopyToClipboard copyText={notification?.rawNotification} className={classes.code}> */}
          <CodeBlock overflow>{JSON.stringify(notification?.rawNotification, null, 2)}</CodeBlock>
          {/* </CopyToClipboard> */}
        </article>
      )}
    </Modal>
  )
}
