import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { pipe, prepend, take } from 'ramda'
import moment from 'moment'
import uuid from 'uuid'
import { ApiErrorMetadata } from 'api-client/model'

const maxNotifications = 100

export enum NotificationType {
  warning = 'warning',
  error = 'error',
  info = 'info',
}

export enum ErrorNotificationType {
  ClusterError = 'cluster-errors',
  Platform9Event = 'platform9-events',
}

export const notificationTypeArr = Object.keys(NotificationType).filter(
  (notificationType) => !(parseInt(notificationType) >= 0),
)

export interface NotificationData {
  apiErrorMetadata: ApiErrorMetadata
  apiClassMetadata: any
  response: any
  params: any
  isClusterError: boolean
}

export interface Notification {
  id: string
  ts: number
  title: string
  message: string
  data: NotificationData
  date: string
  type: NotificationType
  silent: boolean
  unread: boolean
}

export interface NotificationState {
  notifications: Notification[]
}

export const initialState: NotificationState = {
  notifications: [],
}

const {
  name: notificationStoreKey,
  reducer: notificationReducers,
  actions: notificationActions,
} = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    registerNotification: (
      state,
      {
        payload: { title, message, type, data, silent = false, unread = true },
      }: PayloadAction<{
        title: string
        message: string
        type: NotificationType
        data: any
        silent?: boolean
        unread?: boolean
      }>,
    ) => {
      const { notifications } = state
      const addNotification = pipe<Notification[], Notification[], Notification[]>(
        take(maxNotifications - 1),
        prepend({
          id: uuid.v4(),
          ts: new Date().valueOf(),
          title,
          message,
          data,
          date: moment().format(),
          type,
          silent,
          unread,
        }),
      )
      return {
        notifications: addNotification(notifications),
      }
    },
    markAsRead: (state, { payload: notifyType = undefined }) => {
      const isBeingRead = getValidaterFn(notifyType)
      const notifications = []
      for (const notification of state.notifications) {
        const isUnread = notification.unread && !isBeingRead(notification)
        notifications.push({ ...notification, unread: isUnread })
      }
      return {
        ...state,
        notifications,
      }
    },
    clearNotificationById: (state, { payload: id }) => {
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.id !== id),
      }
    },
    clearNotifications: (state, { payload: notifyType }) => {
      const newState = clearNotifyByTarget(state.notifications, notifyType)
      return newState
    },
    clearAllNotifications: () => {
      return initialState
    },
  },
})

const isClusterError = (notification) => !!notification?.data?.isClusterError
const isNotClusterError = (notification) => !notification?.data?.isClusterError

const getValidaterFn = (targetType) =>
  targetType === ErrorNotificationType.ClusterError ? isClusterError : isNotClusterError

const clearNotifyByTarget = (allNotifications, targetType) => {
  const shouldRemoveFn = getValidaterFn(targetType)
  const notifications = []
  for (const notification of allNotifications) {
    if (!shouldRemoveFn(notification)) {
      notifications.push(notification)
    }
  }
  return {
    notifications,
  }
}

export { notificationStoreKey, notificationActions }
export default notificationReducers
