import { RootState } from 'app/store'
import { createSelectorCreator, defaultMemoize } from 'reselect'
import { emptyObj, objIfNilOrEmpty } from 'app/utils/fp'
import { mergeLeft } from 'ramda'
import { memoizedObj } from 'utils/misc'
import { moizeMaxSize } from 'app/constants'

export const selectParamsFromProps = <P>(defaultParams = emptyObj as P) => (
  _: RootState,
  params: P = emptyObj as P,
): P => {
  // @fixme fix these typings
  // @ts-ignore
  return objIfNilOrEmpty(memoizedObj(mergeLeft(params, defaultParams))) as P
}

export const createSharedSelector = createSelectorCreator(defaultMemoize, { maxSize: moizeMaxSize })
