import { AppPlugins, helpUrlDefinition, pluginRoutePrefix } from 'app/constants'
import { IDeploymentDetailsPageTabs } from 'k8s/components/deployments/model'
import { IPodDetailsPageTabs } from 'k8s/components/pods/model'
import { IReplicaSetDetailsPageTabs } from 'k8s/components/replica-sets/model'
import { IServiceDetailsPageTabs } from 'k8s/components/services/model'
import { IStatefulSetDetailsPageTabs } from 'k8s/components/stateful-sets/model'
import { ICronjobDetailsPageTabs, IJobDetailsPageTabs } from 'k8s/components/cronjobs/model'

import { Route } from 'core/plugins/route'
import { IDaemonSetDetailsPageTabs } from 'k8s/components/daemon-sets/models'
import { IDeployedAppDetailsPageTabs } from 'k8s/components/app-catalog/deployed-apps/model'
import { INetworkPluginDetailsPageTabs } from 'k8s/components/networking/plugins/model'
import { IConfigMapsTabs } from 'k8s/components/config-maps/selectors'
import { INetworkAttachmentDefinitionsDetailsPageTabs } from 'k8s/components/networking/network/model'
import { IHostConfigurationDetailsPageTabs } from 'k8s/components/networking/host-configs/model'
import { ISecretsDetailsPageTabs } from 'k8s/components/configuration/secrets/model'
import { IResourceQuotaDetailsPageTabs } from 'k8s/components/configuration/resource-quotas/model'
import { ILimitRangeDetailsPageTabs } from 'k8s/components/configuration/limit-ranges/model'
import { IHorizontalPodAutoscalerDetailsPageTabs } from 'k8s/components/configuration/horizontal-pod-autoscaler/model'
import { IPodDisruptionBudgetDetailsPageTabs } from 'k8s/components/configuration/pod-disruption-budgets/model'
import { IEndpointDetailsPageTabs } from 'k8s/components/network/endpoints/model'
import { IIngressDetailsPageTabs } from 'k8s/components/ingresses/model'
import { INetworkPolicyDetailsPageTabs } from 'k8s/components/network/network-policies/model'

const defaultParams = {
  plugin: AppPlugins.Kubernetes,
}

const metadata = {
  showGlobalFilters: true,
}

export const kubernetesRoutes = {
  dashboard: {
    root: Route.register({
      url: `${pluginRoutePrefix}/dashboard/overview`,
      name: 'Overview',
      id: 'dashboardOverview',
      breadcrumbs: new Map([
        ['dashboard', 'null'],
        ['overview', 'dashboardOverview'],
      ]),
      metadata,
      defaultParams,
    }),
    edit: Route.register({
      url: `${pluginRoutePrefix}/dashboard/overview/edit`,
      name: 'Dashboard:Overview:Edit',
      defaultParams,
    }),
    alarms: Route.register({
      url: `${pluginRoutePrefix}/dashboard/alarms`,
      id: 'alarmsList',
      breadcrumbs: new Map([
        ['dashboard', 'null'],
        ['alarms', 'alarmsList'],
      ]),
      defaultParams,
      name: 'Dashboard:Alarms',
    }),
  },

  apiAccess: {
    apiServices: Route.register({
      url: `${pluginRoutePrefix}/api-access/api-services`,
      id: 'apiAccessServicesList',
      breadcrumbs: new Map([
        ['access', null],
        ['api-services', 'apiAccessServicesList'],
      ]),
      name: 'ApiAccess:APIServices',
      metadata,
      defaultParams,
    }),
    kubeConfig: Route.register({
      url: `${pluginRoutePrefix}/api-access/kubeconfig`,
      id: 'apiAccessKubeconfigDetails',
      breadcrumbs: new Map([
        ['access', null],
        ['kubeconfig', 'apiAccessKubeconfigDetails'],
      ]),
      name: 'ApiAccess:KubeConfig',
      metadata,
      defaultParams,
    }),
    terraform: Route.register({
      url: `${pluginRoutePrefix}/api-access/terraform`,
      id: 'apiAccessTerraformDetails',
      breadcrumbs: new Map([
        ['access', null],
        ['terraform', 'apiAccessTerraformDetails'],
      ]),
      name: 'ApiAccess:Terraform',
      metadata,
      defaultParams,
    }),
  },
  notifications: {
    list: Route.register<{ notificationType: string }>({
      url: `${pluginRoutePrefix}/notifications/:notificationType`,
      name: 'Notifications:List',
      metadata,
      defaultParams,
    }),
    detail: Route.register<{ notificationType: string; id: string }>({
      url: `${pluginRoutePrefix}/notifications/:notificationType/:id`,
      name: 'Notifications:Details',
      metadata,
      defaultParams,
    }),
  },
  repositories: {
    list: Route.register({
      url: `${pluginRoutePrefix}/apps/repositories`,
      id: 'repositoriesList',
      breadcrumbs: new Map([
        ['apps', null],
        ['helm-repositories', 'repositoriesList'],
      ]),
      metadata,
      defaultParams,
      name: 'Repositories:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/apps/repositories/add`,
      breadcrumbs: new Map([
        ['apps', null],
        ['helm-repositories', 'repositoriesList'],
        ['add', 'repositoriesList'],
      ]),
      metadata,
      defaultParams,
      name: 'Repositories:Add',
    }),
    edit: Route.register({
      url: `${pluginRoutePrefix}/apps/repositories/edit/:id`,
      breadcrumbs: new Map([
        ['apps', null],
        ['helm-repositories', 'repositoriesList'],
        ['edit', 'repositoriesList'],
      ]),
      metadata,
      defaultParams,
      name: 'Repositories:Edit',
    }),
  },
  apps: {
    list: Route.register({
      url: `${pluginRoutePrefix}/apps/app-catalog`,
      id: 'appCatalogList',
      breadcrumbs: new Map([
        ['apps', null],
        ['app-catalog', 'appCatalogList'],
      ]),
      name: 'Apps:List',
      metadata,
      defaultParams,
    }),
    deploy: Route.register({
      url: `${pluginRoutePrefix}/apps/app-catalog/deploy/:repository/:name`,
      name: 'Apps:Deploy',
      breadcrumbs: new Map([
        ['apps', null],
        ['app-catalog', 'appCatalogList'],
      ]),
      metadata,
      defaultParams,
    }),
    detail: Route.register({
      url: `${pluginRoutePrefix}/apps/:clusterId/:release/:id`,
      id: 'appCatalogDetails',
      breadcrumbs: new Map([
        ['apps', null],
        [':id', 'appCatalogDetails'],
      ]),
      metadata,
      defaultParams,
      name: 'Apps:Details',
    }),
    deployed: {
      list: Route.register({
        url: `${pluginRoutePrefix}/apps/deployed-apps`,
        id: 'deployedAppsList',
        breadcrumbs: new Map([
          ['apps', null],
          ['deployed-apps', 'deployedAppsList'],
        ]),
        metadata,
        defaultParams,
        name: 'Apps:Deployed',
      }),
      edit: Route.register({
        url: `${pluginRoutePrefix}/apps/:clusterId/:namespace/deployed-apps/edit/:repository/:chart/:name`,
        name: 'Apps:Edit',
        breadcrumbs: new Map([
          ['apps', null],
          ['deployed-apps', 'deployedAppsList'],
          ['edit', 'deployedAppsList'],
        ]),
        metadata,
        defaultParams,
      }),
      details: Route.register<{
        clusterId: string
        namespace: string
        repository: string
        name: string
        tab?: IDeployedAppDetailsPageTabs
      }>({
        id: 'deployedAppDetails',
        url: `${pluginRoutePrefix}/apps/:clusterId/:namespace/deployed-apps/:repository/:chart/:name/:tab`,
        breadcrumbs: new Map([
          ['apps', null],
          ['deployed-apps', 'deployedAppsList'],
          [':name', 'deployedAppDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'Apps:Deployed:Details',
        tab: 'tab',
      }),
    },
  },
  pods: {
    list: Route.register({
      url: `${pluginRoutePrefix}/workloads/pods`,
      id: 'podsList',
      breadcrumbs: new Map([
        ['workloads', null],
        ['pods', 'podsList'],
      ]),
      metadata,
      defaultParams,
      name: 'Pods:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/workloads/pods/add`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['pods', 'podsList'],
        ['add', 'podsList'],
      ]),
      metadata,
      defaultParams,
      name: 'Pods:Add',
    }),
    details: Route.register<{ clusterId: string; id: string; tab?: IPodDetailsPageTabs }>({
      id: 'podDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/pods/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['pods', 'podsList'],
        [':id', 'podDetails'],
      ]),
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'Pods:Details',
      tab: 'tab',
    }),
    container: {
      details: Route.register<{ clusterId: string; id: string; containerName: string }>({
        id: 'podContainerDetails',
        url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/pods/:id/containers/:containerName`,
        breadcrumbs: new Map([
          ['workloads', null],
          ['pods', 'podsList'],
          [':id', 'podDetails'],
          [':containerName', 'podContainerDetails'],
        ]),
        defaultParams,
        name: 'Pods:Details:Containers:Details',
      }),
    },
  },
  services: {
    list: Route.register({
      id: 'servicesList',
      url: `${pluginRoutePrefix}/workloads/services`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['services', 'servicesList'],
      ]),
      metadata,
      defaultParams,
      name: 'Services:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/workloads/services/add`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['services', 'servicesList'],
        ['add', 'servicesList'],
      ]),
      metadata,
      defaultParams,
      name: 'Services:Add',
    }),
    details: Route.register<{ clusterId: string; id: string; tab?: IServiceDetailsPageTabs }>({
      id: 'serviceDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/services/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['services', 'servicesList'],
        [':id', 'serviceDetails'],
      ]),
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'Services:Details',
    }),
  },
  deployments: {
    list: Route.register({
      id: 'deploymentsList',
      url: `${pluginRoutePrefix}/workloads/deployments`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['deployments', 'deploymentsList'],
      ]),
      metadata,
      defaultParams,
      name: 'Deployments:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/workloads/deployments/add`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['deployments', 'deploymentsList'],
        ['add', 'deploymentsList'],
      ]),
      metadata,
      defaultParams,
      name: 'Deployments:Add',
    }),
    details: Route.register<{ clusterId: string; id: string; tab?: IDeploymentDetailsPageTabs }>({
      id: 'deploymentDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/deployments/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['deployments', 'deploymentsList'],
        [':id', 'deploymentDetails'],
      ]),
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'Deployments:Details',
    }),
  },
  statefulSets: {
    list: Route.register({
      id: 'statefulSetsList',
      url: `${pluginRoutePrefix}/workloads/stateful-sets`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['stateful-sets', 'statefulSetsList'],
      ]),
      metadata,
      defaultParams,
      name: 'StatefulSets:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/workloads/stateful-sets/add`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['stateful-sets', 'statefulSetsList'],
        ['add', 'statefulSetsList'],
      ]),
      metadata,
      defaultParams,
      name: 'StatefulSets:Add',
    }),
    details: Route.register<{ clusterId: string; id: string; tab?: IStatefulSetDetailsPageTabs }>({
      id: 'statefulSetDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/stateful-sets/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['stateful-sets', 'statefulSetsList'],
        [':id', 'statefulSetDetails'],
      ]),
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'StatefulSets:Details',
    }),
    container: Route.register<{ clusterId: string; id: string; containerName: string }>({
      id: 'statefulSetContainerDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/stateful-sets/:id/containers/:containerName`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['stateful-sets', 'statefulSetsList'],
        [':id', 'statefulSetDetails'],
        [':containerName', 'statefulSetContainerDetails'],
      ]),
      metadata,
      defaultParams,
      name: 'StatefulSets:Details:Containers:Details',
    }),
  },
  replicaSets: {
    list: Route.register({
      id: 'replicaSetsList',
      url: `${pluginRoutePrefix}/workloads/replica-sets`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['replica-sets', 'replicaSetsList'],
      ]),
      metadata,
      defaultParams,
      name: 'ReplicaSets:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/workloads/replica-sets/add`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['replica-sets', 'replicaSetsList'],
        ['add', 'replicaSetsList'],
      ]),
      metadata,
      defaultParams,
      name: 'ReplicaSets:Add',
    }),
    details: Route.register<{ clusterId: string; id: string; tab?: IReplicaSetDetailsPageTabs }>({
      id: 'replicaSetDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/replica-sets/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['replica-sets', 'replicaSetsList'],
        [':id', 'replicaSetDetails'],
      ]),
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'ReplicaSets:Details',
    }),
    container: Route.register<{ clusterId: string; id: string; containerName: string }>({
      id: 'replicaSetContainerDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/replica-sets/:id/containers/:containerName`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['replica-sets', 'replicaSetsList'],
        [':id', 'replicaSetDetails'],
        [':containerName', 'replicaSetContainerDetails'],
      ]),
      metadata,
      defaultParams,
      name: 'ReplicaSets:Details:Containers:Details',
    }),
  },
  daemonSets: {
    list: Route.register({
      id: 'daemonSetsList',
      url: `${pluginRoutePrefix}/workloads/daemon-sets`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['daemon-sets', 'daemonSetsList'],
      ]),
      metadata,
      defaultParams,
      name: 'DaemonSets:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/workloads/daemon-sets/add`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['daemon-sets', 'daemonSetsList'],
        ['add', 'daemonSetsList'],
      ]),
      metadata,
      defaultParams,
      name: 'DaemonSets:Add',
    }),
    details: Route.register<{ clusterId: string; id: string; tab?: IDaemonSetDetailsPageTabs }>({
      id: 'daemonSetDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/daemon-sets/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['daemon-sets', 'daemonSetsList'],
        [':id', 'daemonSetDetails'],
      ]),
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'DaemonSets:Details',
    }),
    container: Route.register<{ clusterId: string; id: string; containerName: string }>({
      id: 'daemonSetContainerDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/daemon-sets/:id/containers/:containerName`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['daemon-sets', 'daemonSetsList'],
        [':id', 'daemonSetDetails'],
        [':containerName', 'daemonSetContainerDetails'],
      ]),
      metadata,
      defaultParams,
      name: 'DaemonSets:Details:Containers:Details',
    }),
  },
  cronjobs: {
    list: Route.register({
      id: 'cronJobsList',
      url: `${pluginRoutePrefix}/workloads/cron-jobs`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['cron-jobs', 'cronJobsList'],
      ]),
      metadata,
      defaultParams,
      name: 'Cronjobs:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/workloads/cron-jobs/add`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['cron-jobs', 'cronJobsList'],
        ['add', 'cronJobsList'],
      ]),
      metadata,
      defaultParams,
      name: 'Cronjobs:Add',
    }),
    details: Route.register<{ clusterId: string; id: string; tab?: ICronjobDetailsPageTabs }>({
      id: 'cronJobDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/cron-jobs/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['cron-jobs', 'cronJobsList'],
        [':id', 'cronJobDetails'],
      ]),
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'Cronjobs:Details',
    }),
    container: Route.register<{ clusterId: string; id: string; containerName: string }>({
      id: 'cronJobContainerDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/cron-jobs/:id/containers/:containerName`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['cron-jobs', 'cronJobsList'],
        [':id', 'cronJobDetails'],
        [':containerName', 'cronJobContainerDetails'],
      ]),
      metadata,
      defaultParams,
      name: 'Cronjobs:Details:Containers:Details',
    }),
  },
  jobs: {
    add: Route.register({
      url: `${pluginRoutePrefix}/workloads/jobs/add`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['jobs', 'cronJobsList'],
        ['add', 'cronJobsList'],
      ]),
      metadata,
      defaultParams,
      name: 'Jobs:Add',
    }),
    details: Route.register<{ clusterId: string; id: string; tab?: IJobDetailsPageTabs }>({
      id: 'jobDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/jobs/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['jobs', 'cronJobsList'],
        [':id', 'jobDetails'],
      ]),
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'Jobs:Details',
    }),
    container: Route.register<{ clusterId: string; id: string; containerName: string }>({
      id: 'jobContainerDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/jobs/:id/containers/:containerName`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['jobs', 'cronJobsList'],
        [':id', 'jobDetails'],
        [':containerName', 'jobContainerDetails'],
      ]),
      metadata,
      defaultParams,
      name: 'Jobs:Details:Containers:Details',
    }),
  },
  namespaces: {
    list: Route.register({
      id: 'namespacesList',
      url: `${pluginRoutePrefix}/workloads/namespaces`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['namespaces', 'namespacesList'],
      ]),
      metadata,
      defaultParams,
      name: 'Namespaces:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/workloads/namespaces/add`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['namespaces', 'namespacesList'],
        ['add', 'namespacesList'],
      ]),
      metadata,
      defaultParams,
      name: 'Namespaces:Add',
    }),
  },
  storage: {
    storageClasses: {
      list: Route.register({
        id: 'storageClassesList',
        url: `${pluginRoutePrefix}/storage/storage-classes`,
        breadcrumbs: new Map([
          ['storage', null],
          ['storage-classes', 'storageClassesList'],
        ]),
        metadata,
        defaultParams,
        name: 'Storage:StorageClasses:List',
      }),
      details: Route.register<{ clusterId: string; id: string; tab?: string }>({
        id: 'storageClassDetails',
        url: `${pluginRoutePrefix}/storage/clusters/:clusterId/storage-classes/:id/:tab`,
        breadcrumbs: new Map([
          ['storage', null],
          ['storage-classes', 'storageClassesList'],
          [':id', 'storageClassDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'Storage:StorageClasses:Details',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/storage/storage-classes/add`,
        breadcrumbs: new Map([
          ['storage', null],
          ['storage-classes', 'storageClassesList'],
          ['add', 'storageClassesList'],
        ]),
        metadata,
        defaultParams,
        name: 'Storage:StorageClasses:Add',
      }),
    },
    csiDrivers: Route.register({
      id: 'csiDriversList',
      url: `${pluginRoutePrefix}/storage/csi-drivers`,
      breadcrumbs: new Map([
        ['storage', null],
        ['csi-drivers', 'csiDriversList'],
      ]),
      metadata,
      defaultParams,
      name: 'Storage:CSIDrivers:List',
    }),
    persistentVolumes: {
      list: Route.register({
        id: 'persistentVolumesList',
        url: `${pluginRoutePrefix}/storage/persistent-volumes`,
        breadcrumbs: new Map([
          ['storage', null],
          ['persistent-volumes', 'persistentVolumesList'],
        ]),
        metadata,
        defaultParams,
        name: 'Storage:PersistentVolumes:List',
      }),
      details: Route.register<{ clusterId: string; id: string; tab?: string }>({
        id: 'persistentVolumeDetails',
        url: `${pluginRoutePrefix}/storage/clusters/:clusterId/persistent-volumes/:id/:tab`,
        breadcrumbs: new Map([
          ['storage', null],
          ['persistent-volumes', 'persistentVolumesList'],
          [':id', 'persistentVolumeDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'Storage:PersistentVolumes:Details',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/storage/persistent-volumes/add`,
        breadcrumbs: new Map([
          ['storage', null],
          ['persistent-volumes', 'persistentVolumesList'],
          ['add', 'persistentVolumesList'],
        ]),
        metadata,
        defaultParams,
        name: 'Storage:PersistentVolumes:Add',
      }),
    },
    persistentVolumeClaims: {
      list: Route.register({
        id: 'persistentVolumeClaimsList',
        url: `${pluginRoutePrefix}/storage/persistent-volume-claims`,
        breadcrumbs: new Map([
          ['storage', null],
          ['persistent-volume-claims', 'persistentVolumeClaimsList'],
        ]),
        metadata,
        defaultParams,
        name: 'Storage:PersistentVolumeClaims:List',
      }),
      details: Route.register<{ clusterId: string; id: string; tab?: string }>({
        id: 'persistentVolumeClaimDetails',
        url: `${pluginRoutePrefix}/storage/clusters/:clusterId/persistent-volume-claims/:id/:tab`,
        breadcrumbs: new Map([
          ['storage', null],
          ['persistent-volume-claims', 'persistentVolumeClaimsList'],
          [':id', 'persistentVolumeClaimDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'Storage:PersistentVolumeClaims:Details',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/storage/persistent-volume-claims/add`,
        breadcrumbs: new Map([
          ['storage', null],
          ['persistent-volume-claims', 'persistentVolumeClaimsList'],
          ['add', 'persistentVolumeClaimsList'],
        ]),
        metadata,
        defaultParams,
        name: 'Storage:PersistentVolumeClaims:Add',
      }),
    },
    storageProviders: Route.register({
      id: 'storageProvidersList',
      url: `${pluginRoutePrefix}/storage/storage-providers`,
      breadcrumbs: new Map([
        ['storage', null],
        ['storage-providers', 'storageProvidersList'],
      ]),
      metadata,
      defaultParams,
      name: 'Storage:StorageProviders:List',
    }),
  },
  monitoring: {
    alarms: Route.register({
      url: `${pluginRoutePrefix}/monitoring/alarms`,
      id: 'monitoringAlarmsList',
      breadcrumbs: new Map([
        ['monitoring', null],
        ['alarms', 'monitoringAlarmsList'],
      ]),
      metadata,
      defaultParams,
      name: 'Monitoring:Alarms:List',
    }),
    rules: Route.register({
      url: `${pluginRoutePrefix}/monitoring/rules`,
      id: 'monitoringRulesList',
      breadcrumbs: new Map([
        ['monitoring', null],
        ['rules', 'monitoringRulesList'],
      ]),
      metadata,
      defaultParams,
      name: 'Monitoring:Rules:List',
    }),
  },
  rbac: {
    roles: Route.register({
      url: `${pluginRoutePrefix}/rbac/roles`,
      id: 'rbacRolesList',
      breadcrumbs: new Map([
        ['rbac', null],
        ['roles', 'rbacRolesList'],
      ]),
      metadata,
      defaultParams,
      name: 'RBAC:Roles:List',
    }),
    addRoles: Route.register({
      url: `${pluginRoutePrefix}/rbac/roles/add`,
      breadcrumbs: new Map([
        ['rbac', null],
        ['roles', 'rbacRolesList'],
        ['add', 'rbacRolesList'],
      ]),
      metadata,
      defaultParams,
      name: 'RBAC:Roles:Add',
    }),
    editRoles: Route.register({
      url: `${pluginRoutePrefix}/rbac/roles/edit/:id/cluster/:clusterId`,
      metadata,
      defaultParams,
      breadcrumbs: new Map([
        ['rbac', null],
        ['roles', 'rbacRolesList'],
        ['edit', 'rbacRolesList'],
      ]),
      name: 'RBAC:Roles:Edit',
    }),
    clusterRoles: Route.register({
      url: `${pluginRoutePrefix}/rbac/cluster-roles`,
      id: 'rbacClusterRolesList',
      breadcrumbs: new Map([
        ['rbac', null],
        ['cluster-roles', 'rbacClusterRolesList'],
      ]),
      metadata,
      defaultParams,
      name: 'RBAC:ClusterRoles:List',
    }),
    addClusterRoles: Route.register({
      url: `${pluginRoutePrefix}/rbac/cluster-roles/add`,
      breadcrumbs: new Map([
        ['rbac', null],
        ['cluster-roles', 'rbacClusterRolesList'],
        ['Add', 'rbacClusterRolesList'],
      ]),
      metadata,
      defaultParams,
      name: 'RBAC:ClusterRoles:Add',
    }),
    editClusterRoles: Route.register({
      url: `${pluginRoutePrefix}/rbac/cluster-roles/edit/:id/cluster/:clusterId`,
      breadcrumbs: new Map([
        ['rbac', null],
        ['cluster-roles', 'rbacClusterRolesList'],
        ['edit', 'rbacClusterRolesList'],
      ]),
      metadata,
      defaultParams,
      name: 'RBAC:ClusterRoles:Edit',
    }),
    roleBindings: Route.register({
      url: `${pluginRoutePrefix}/rbac/role-bindings`,
      id: 'rbacRoleBindingsList',
      breadcrumbs: new Map([
        ['rbac', null],
        ['role-bindings', 'rbacRoleBindingsList'],
      ]),
      metadata,
      defaultParams,
      name: 'RBAC:RoleBindings:List',
    }),
    addRoleBindings: Route.register({
      url: `${pluginRoutePrefix}/rbac/role-bindings/add`,
      breadcrumbs: new Map([
        ['rbac', null],
        ['role-bindings', 'rbacRoleBindingsList'],
        ['add', 'rbacRoleBindingsList'],
      ]),
      metadata,
      defaultParams,
      name: 'RBAC:RoleBindings:Add',
    }),
    editRoleBindings: Route.register({
      url: `${pluginRoutePrefix}/rbac/role-bindings/edit/:id/cluster/:clusterId`,
      breadcrumbs: new Map([
        ['rbac', null],
        ['role-bindings', 'rbacRoleBindingsList'],
        ['edit', 'rbacRoleBindingsList'],
      ]),
      metadata,
      defaultParams,
      name: 'RBAC:RoleBindings:Edit',
    }),
    clusterRoleBindings: Route.register({
      url: `${pluginRoutePrefix}/rbac/cluster-role-bindings`,
      id: 'rbacClusterRoleBindingsList',
      breadcrumbs: new Map([
        ['rbac', null],
        ['cluster-role-bindings', 'rbacClusterRoleBindingsList'],
      ]),
      metadata,
      defaultParams,
      name: 'RBAC:ClusterRoleBindings:List',
    }),
    addClusterRoleBindings: Route.register({
      url: `${pluginRoutePrefix}/rbac/cluster-role-bindings/add`,
      breadcrumbs: new Map([
        ['rbac', null],
        ['cluster-role-bindings', 'rbacClusterRoleBindingsList'],
        ['Add', 'rbacClusterRoleBindingsList'],
      ]),
      metadata,
      defaultParams,
      name: 'RBAC:ClusterRolesBindings:Add',
    }),
    editClusterRoleBindings: Route.register({
      url: `${pluginRoutePrefix}/rbac/cluster-role-bindings/edit/:id/cluster/:clusterId`,
      breadcrumbs: new Map([
        ['rbac', null],
        ['cluster-role-bindings', 'rbacClusterRoleBindingsList'],
        ['edit', 'rbacClusterRoleBindingsList'],
      ]),
      metadata,
      defaultParams,
      name: 'RBAC:ClusterRoleBindings:Edit',
    }),
  },
  help: Route.register({
    url: helpUrlDefinition,
    name: 'Help',
    metadata,
    defaultParams: {
      ...defaultParams,
      tab: 'support',
    },
  }),
  networking: {
    overview: Route.register({
      url: `${pluginRoutePrefix}/networking/overview`,
      id: 'networkingOverview',
      breadcrumbs: new Map([
        ['networking', null],
        ['overview', 'networkingOverview'],
      ]),
      metadata,
      defaultParams,
      name: 'Networking:Overview',
    }),
    network: Route.register({
      url: `${pluginRoutePrefix}/networking/network-attachment-definitions`,
      id: 'networkAttachmentDefinitions',
      breadcrumbs: new Map([
        ['networking', null],
        ['network-attachment-definitions', 'networkAttachmentDefinitions'],
      ]),
      metadata,
      defaultParams,
      name: 'Network',
    }),
    addNetwork: Route.register({
      url: `${pluginRoutePrefix}/networking/network-attachment-definitions/add`,
      breadcrumbs: new Map([
        ['networking', null],
        ['network-attachment-definitions', 'networkAttachmentDefinitions'],
        ['add', 'networkAttachmentDefinitions'],
      ]),
      metadata,
      defaultParams,
      name: 'Networking:Network:Add',
    }),
    networkDetails: Route.register<{
      clusterId: string
      id: string
      tab?: INetworkAttachmentDefinitionsDetailsPageTabs
    }>({
      id: 'networkAttachmentDefinitionsDetails',
      url: `${pluginRoutePrefix}/networking/clusters/:clusterId/network-attachment-definitions/:id/:tab`,
      breadcrumbs: new Map([
        ['networking', null],
        ['network-attachment-definitions', 'networkAttachmentDefinitions'],
        [':id', 'networkAttachmentDefinitionsDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'Networking:Network:Details',
      tab: 'tab',
    }),
    plugins: Route.register({
      url: `${pluginRoutePrefix}/networking/plugins`,
      id: 'networkPlugins',
      breadcrumbs: new Map([
        ['networking', null],
        ['Plugin Configurations', 'networkPlugins'],
      ]),
      metadata,
      defaultParams,
      name: 'Networking:Plugins',
    }),
    addPlugin: Route.register({
      url: `${pluginRoutePrefix}/networking/plugins/add`,
      breadcrumbs: new Map([
        ['networking', null],
        ['Plugin Configurations', 'networkPlugins'],
        ['add', 'networkPlugins'],
      ]),
      metadata,
      defaultParams,
      name: 'Networking:Plugins:Add',
    }),
    pluginDetails: Route.register<{
      clusterId: string
      id: string
      tab?: INetworkPluginDetailsPageTabs
    }>({
      id: 'networkPluginsDetails',
      url: `${pluginRoutePrefix}/networking/clusters/:clusterId/plugins/:id/:tab`,
      breadcrumbs: new Map([
        ['networking', null],
        ['Plugin Configurations', 'networkPlugins'],
        [':id', 'networkPluginsDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'Networking:Plugins:Details',
      tab: 'tab',
    }),
    hostConfigurations: Route.register({
      url: `${pluginRoutePrefix}/networking/host-configurations`,
      id: 'hostConfigurations',
      breadcrumbs: new Map([
        ['networking', null],
        ['Host Configurations', 'hostConfigurations'],
      ]),
      metadata,
      defaultParams,
      name: 'Networking:HostConfigurations',
    }),
    addHostConfiguration: Route.register({
      url: `${pluginRoutePrefix}/networking/host-configurations/add`,
      breadcrumbs: new Map([
        ['networking', null],
        ['Host Configurations', 'hostConfigurations'],
        ['add', 'hostConfigurations'],
      ]),
      metadata,
      defaultParams,
      name: 'Networking:HostConfigurations:Add',
    }),
    hostConfigurationDetails: Route.register<{
      clusterId: string
      id: string
      tab?: IHostConfigurationDetailsPageTabs
    }>({
      id: 'hostConfigurationDetails',
      url: `${pluginRoutePrefix}/networking/clusters/:clusterId/host-configurations/:id/:tab`,
      breadcrumbs: new Map([
        ['networking', null],
        ['Host Configurations', 'hostConfigurations'],
        [':id', 'hostConfigurationDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'Networking:HostConfigurations:Details',
      tab: 'tab',
    }),
    endpoints: {
      list: Route.register({
        url: `${pluginRoutePrefix}/networking/endpoints`,
        id: 'endpointsList',
        breadcrumbs: new Map([
          ['networking', null],
          ['Endpoints', 'endpointsList'],
        ]),
        metadata,
        defaultParams,
        name: 'Endpoints:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/networking/endpoints/add`,
        breadcrumbs: new Map([
          ['networking', null],
          ['Endpoints', 'endpointsList'],
          ['add', 'endpointsList'],
        ]),
        metadata,
        defaultParams,
        name: 'Networking:Endpoints:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: IEndpointDetailsPageTabs
      }>({
        id: 'endpointDetails',
        url: `${pluginRoutePrefix}/networking/clusters/:clusterId/endpoints/:id/:tab`,
        breadcrumbs: new Map([
          ['networking', null],
          ['Endpoints', 'endpointsList'],
          [':id', 'endpointDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'Endpoints:Details',
        tab: 'tab',
      }),
    },
    ingresses: {
      list: Route.register({
        url: `${pluginRoutePrefix}/networking/ingresses`,
        id: 'ingressesList',
        breadcrumbs: new Map([
          ['networking', null],
          ['Ingresses', 'ingressesList'],
        ]),
        metadata,
        defaultParams,
        name: 'Ingresses:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/networking/ingresses/add`,
        breadcrumbs: new Map([
          ['networking', null],
          ['Ingresses', 'ingressesList'],
          ['add', 'ingressesList'],
        ]),
        metadata,
        defaultParams,
        name: 'Networking:Ingresses:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: IIngressDetailsPageTabs
      }>({
        id: 'ingressDetails',
        url: `${pluginRoutePrefix}/networking/clusters/:clusterId/ingresses/:id/:tab`,
        breadcrumbs: new Map([
          ['networking', null],
          ['Ingresses', 'ingressesList'],
          [':id', 'ingressDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'Ingresses:Details',
        tab: 'tab',
      }),
    },
    networkPolicies: {
      list: Route.register({
        url: `${pluginRoutePrefix}/networking/network-policies`,
        id: 'networkPoliciesList',
        breadcrumbs: new Map([
          ['networking', null],
          ['Network Policies', 'networkPoliciesList'],
        ]),
        metadata,
        defaultParams,
        name: 'NetworkPolicies:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/networking/network-policies/add`,
        breadcrumbs: new Map([
          ['networking', null],
          ['Network Policies', 'networkPoliciesList'],
          ['add', 'networkPoliciesList'],
        ]),
        metadata,
        defaultParams,
        name: 'Networking:NetworkPolicies:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: INetworkPolicyDetailsPageTabs
      }>({
        id: 'networkPolicyDetails',
        url: `${pluginRoutePrefix}/networking/clusters/:clusterId/network-policies/:id/:tab`,
        breadcrumbs: new Map([
          ['networking', null],
          ['Network Policies', 'networkPoliciesList'],
          [':id', 'networkPolicyDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'NetworkPolicies:Details',
        tab: 'tab',
      }),
    },
  },
  configuration: {
    configMaps: {
      list: Route.register({
        url: `${pluginRoutePrefix}/configuration/config-maps`,
        id: 'configMapsList',
        breadcrumbs: new Map([
          ['configuration', null],
          ['Config Maps', 'configMapsList'],
        ]),
        metadata,
        defaultParams,
        name: 'ConfigMaps:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/configuration/config-maps/add`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Config Maps', 'configMapsList'],
          ['add', 'configMapsList'],
        ]),
        metadata,
        defaultParams,
        name: 'Configuration:ConfigMaps:Add',
      }),
      details: Route.register<{ clusterId: string; id: string; tab?: IConfigMapsTabs }>({
        id: 'configMapDetails',
        url: `${pluginRoutePrefix}/configuration/clusters/:clusterId/config-maps/:id/:tab`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Config Maps', 'configMapsList'],
          [':id', 'configMapDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'ConfigMaps:Details',
        tab: 'tab',
      }),
    },
    secrets: {
      list: Route.register({
        url: `${pluginRoutePrefix}/configuration/secrets`,
        id: 'secretsList',
        breadcrumbs: new Map([
          ['configuration', null],
          ['Secrets', 'secretsList'],
        ]),
        metadata,
        defaultParams,
        name: 'Secrets:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/configuration/secrets/add`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Secrets', 'secretsList'],
          ['add', 'secretsList'],
        ]),
        metadata,
        defaultParams,
        name: 'Configuration:Secrets:Add',
      }),
      details: Route.register<{ clusterId: string; id: string; tab?: ISecretsDetailsPageTabs }>({
        id: 'secretDetails',
        url: `${pluginRoutePrefix}/configuration/clusters/:clusterId/secrets/:id/:tab`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Secrets', 'secretsList'],
          [':id', 'secretDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'Secrets:Details',
        tab: 'tab',
      }),
    },
    resourceQuotas: {
      list: Route.register({
        url: `${pluginRoutePrefix}/configuration/resource-quotas`,
        id: 'resourceQuotasList',
        breadcrumbs: new Map([
          ['configuration', null],
          ['Resource Quotas', 'resourceQuotasList'],
        ]),
        metadata,
        defaultParams,
        name: 'ResourceQuotas:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/configuration/resource-quotas/add`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Resource Quotas', 'resourceQuotasList'],
          ['add', 'resourceQuotasList'],
        ]),
        metadata,
        defaultParams,
        name: 'Configuration:ResourceQuotas:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: IResourceQuotaDetailsPageTabs
      }>({
        id: 'resourceQuotaDetails',
        url: `${pluginRoutePrefix}/configuration/clusters/:clusterId/resource-quotas/:id/:tab`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Resource Quotas', 'resourceQuotasList'],
          [':id', 'resourceQuotaDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'ResourceQuotas:Details',
        tab: 'tab',
      }),
    },
    limitRanges: {
      list: Route.register({
        url: `${pluginRoutePrefix}/configuration/limit-ranges`,
        id: 'limitRangesList',
        breadcrumbs: new Map([
          ['configuration', null],
          ['Limit Ranges', 'limitRangesList'],
        ]),
        metadata,
        defaultParams,
        name: 'LimitRanges:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/configuration/limit-ranges/add`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Limit Ranges', 'limitRangesList'],
          ['add', 'limitRangesList'],
        ]),
        metadata,
        defaultParams,
        name: 'Configuration:LimitRanges:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: ILimitRangeDetailsPageTabs
      }>({
        id: 'limitRangeDetails',
        url: `${pluginRoutePrefix}/configuration/clusters/:clusterId/limit-ranges/:id/:tab`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Limit Ranges', 'limitRangesList'],
          [':id', 'limitRangeDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'LimitRanges:Details',
        tab: 'tab',
      }),
    },
    horizontalPodAutoscalers: {
      list: Route.register({
        url: `${pluginRoutePrefix}/configuration/horizontal-pod-autoscalers`,
        id: 'horizontalPodAutoscalersList',
        breadcrumbs: new Map([
          ['configuration', null],
          ['Horizontal Pod Autoscalers', 'horizontalPodAutoscalersList'],
        ]),
        metadata,
        defaultParams,
        name: 'HorizontalPodAutoscalers:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/configuration/horizontal-pod-autoscalers/add`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Horizontal Pod Autoscalers', 'horizontalPodAutoscalersList'],
          ['add', 'horizontalPodAutoscalersList'],
        ]),
        metadata,
        defaultParams,
        name: 'Configuration:HorizontalPodAutoscalers:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: IHorizontalPodAutoscalerDetailsPageTabs
      }>({
        id: 'horizontalPodAutoscalerDetails',
        url: `${pluginRoutePrefix}/configuration/clusters/:clusterId/horizontal-pod-autoscalers/:id/:tab`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Horizontal Pod Autoscalers', 'horizontalPodAutoscalersList'],
          [':id', 'horizontalPodAutoscalerDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'HorizontalPodAutoscalers:Details',
        tab: 'tab',
      }),
    },
    podDisruptionBudgets: {
      list: Route.register({
        url: `${pluginRoutePrefix}/configuration/pod-disruption-budgets`,
        id: 'podDisruptionBudgetsList',
        breadcrumbs: new Map([
          ['configuration', null],
          ['Pod Disruption Budgets', 'podDisruptionBudgetsList'],
        ]),
        metadata,
        defaultParams,
        name: 'PodDisruptionBudgets:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/configuration/pod-disruption-budgets/add`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Pod Disruption Budgets', 'podDisruptionBudgetsList'],
          ['add', 'podDisruptionBudgetsList'],
        ]),
        metadata,
        defaultParams,
        name: 'Configuration:PodDisruptionBudgets:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: IPodDisruptionBudgetDetailsPageTabs
      }>({
        id: 'podDisruptionBudgetDetails',
        url: `${pluginRoutePrefix}/configuration/clusters/:clusterId/pod-disruption-budgets/:id/:tab`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Pod Disruption Budgets', 'podDisruptionBudgetsList'],
          [':id', 'podDisruptionBudgetDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'PodDisruptionBudgets:Details',
        tab: 'tab',
      }),
    },
  },
}
