import { IDataKeys } from 'k8s/datakeys.model'
import { ActionConfig } from 'core/actions/Action'
import { ActionLike } from 'core/actions/ActionLike'

export default class ActionsSet<D extends keyof IDataKeys> {
  private static instances = new Map<keyof IDataKeys, ActionsSet<keyof IDataKeys>>()

  public static make<D extends keyof IDataKeys>(commonConfig: ActionConfig<D>): ActionsSet<D> {
    const { cacheKey } = commonConfig
    if (ActionsSet.instances.has(cacheKey)) {
      console.warn(`ActionSet with cacheKey ${cacheKey} already exists`)
    }
    const instance = new ActionsSet<D>(commonConfig)
    ActionsSet.instances.set(cacheKey, instance)

    // The following will always be true, but we must do it anyway for typesafety reasons
    // ie we must make sure the ActionSet instance "cacheKey" is "D"
    if (isActionsSet<D>(instance, cacheKey)) {
      return instance
    }
    // This will never happen
    return null
  }

  public readonly cacheKey: D
  private readonly actions = new Map<string, ActionLike<D>>()

  private constructor(private readonly commonConfig: ActionConfig<D>) {
    this.cacheKey = commonConfig.cacheKey
  }

  add = <A extends ActionLike<D>>(action: A): A => {
    if (this.actions.has(action.name)) {
      throw new Error(`Action "${action.name}" already exists in ActionsSet`)
    }
    action.updateConfig(this.commonConfig)
    this.actions.set(action.name, action)
    return action
  }
}

export function isActionsSet<D extends keyof IDataKeys>(
  instance: ActionsSet<keyof IDataKeys>,
  cacheKey: D,
): instance is ActionsSet<D> {
  return instance instanceof ActionsSet && instance.cacheKey === cacheKey
}
