import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Badge from './Badge'
import generateTestId from 'utils/test-helpers'
import { memoize } from 'utils/misc'
import { BadgesProps } from 'k8s/components/common/entity/labels-and-annotations/model'
import Button from '../button'
import { topMiddle, middleRight } from '../menu/defaults'
import TooltipListBody from '../tooltip/TooltipListBody'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import CopyToClipboard from 'core/components/CopyToClipboard'
import clsx from 'clsx'
import { hexToRgbaCss } from 'core/utils/colorHelpers'
import Tooltip from 'core/elements/tooltip/Tooltip'

const defaultMaxBadgesVisible = {
  panel: 6,
  table: 3,
  string: 6,
}

const tooltipListProps = {
  ...topMiddle,
  origin: 'right bottom',
}

const getDefaultEllipsisAt = memoize((entityType) =>
  entityType === 'annotations' ? 200 : entityType === 'labels' ? 15 : null,
)

const TooltipOverlay = ({ showAll, text, classes }) => {
  const [copied, setCopied] = useState(false)
  const clickedOverlay = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }
  return (
    <Tooltip message={showAll ? 'Copy' : null} {...middleRight}>
      <CopyToClipboard
        copyText={text}
        copyIcon={false}
        inline={false}
        className={clsx(classes.copyToClipboard, 'copyToClipboard')}
        triggerWithChild
      >
        <div className={classes.copyContainer} onClick={clickedOverlay}>
          <FontAwesomeIcon size="md" className={clsx(classes.tooltipText, classes.tooltipCopyIcon)}>
            {copied ? 'check' : 'copy'}
          </FontAwesomeIcon>
        </div>
      </CopyToClipboard>
    </Tooltip>
  )
}

export default function Badges({
  values: allItems = [],
  entityType = 'string',
  containerType = 'panel',
  variant,
  ellipsisAt: _ellipsisAt = getDefaultEllipsisAt(entityType),
  maxVisible: _maxVisible,
  showMoreButton = false,
  bold = false,
}: BadgesProps) {
  const maxVisible = _maxVisible || defaultMaxBadgesVisible[entityType]
  const [showAll, setShowAll] = useState(false)
  const ellipsisAt = containerType === 'table' ? (showAll ? null : 15) : _ellipsisAt
  const classes = useStyles({ containerType, showAll })

  const [itemsToShow, remainingLabels] = useMemo(() => {
    if (!showAll && allItems.length > maxVisible) {
      return [allItems.slice(0, maxVisible), allItems.slice(maxVisible)]
    }
    return [allItems, []]
  }, [showAll, allItems, maxVisible])

  const showButton = useMemo(() => {
    return showMoreButton && allItems.length > maxVisible
  }, [showMoreButton, allItems, maxVisible])
  return (
    <div data-testid={generateTestId(entityType)} className={classes.labelsOrAnnotations}>
      {itemsToShow.map(({ text, tooltipText, additionalText }) => (
        <Badge
          variant={variant}
          key={text}
          text={text}
          additionalText={additionalText}
          bold={bold}
          ellipsisAt={ellipsisAt}
          hideTooltip={showAll}
          tooltipProps={tooltipListProps}
          tooltipBody={<TooltipListBody items={[tooltipText]} showCopy={false} />}
          className={classes.badge}
          overlayComponent={<TooltipOverlay showAll={showAll} text={text} classes={classes} />}
        />
      ))}
      {(_maxVisible !== undefined || containerType === 'table') &&
        allItems.length > maxVisible &&
        !(showButton && showAll) && (
          <Badge
            variant={variant}
            text={`+${allItems.length - maxVisible}`}
            bold={bold}
            tooltipBody={<TooltipListBody items={remainingLabels} nameKey="text" />}
            tooltipProps={tooltipListProps}
          />
        )}
      {showButton && (
        <Button
          variant="secondary"
          className={classes.showMoreButton}
          onClick={() => setShowAll(!showAll)}
        >
          <FontAwesomeIcon size="md">{showAll ? 'chevron-up' : 'chevron-down'}</FontAwesomeIcon>
        </Button>
      )}
    </div>
  )
}

interface StyleProps {
  containerType: BadgesProps['containerType']
  showAll: boolean
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  labelsOrAnnotations: {
    display: 'flex',
    flexFlow: ({ containerType, showAll }) =>
      containerType === 'table' ? (showAll ? 'wrap' : 'nowrap') : 'wrap',
    maxWidth: ({ containerType }) => (containerType === 'table' ? '480px' : 'max-content'),
    overflow: 'auto',
    gap: 8,
  },
  showMoreButton: {
    borderRadius: 4,
    padding: '0px 8px',
    minHeight: 'initial',
    height: 26,
  },
  badge: {
    position: 'relative',
    '& > .tooltip-container > .copyToClipboard': {
      display: 'none',
    },
    '&:hover > .tooltip-container > .copyToClipboard': {
      display: 'initial',
    },
  },
  copyToClipboard: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  tooltipText: {
    color: theme.components.tooltip.text,
    maxWidth: 350,
  },
  copyContainer: {
    cursor: 'pointer',
    backgroundColor: hexToRgbaCss(theme.components.tooltip.copyBackground, 0.8),
    width: 26,
    height: 26,
    boxSizing: 'border-box',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    // For some reason border radius isn't matching badge
    // so setting top right radius as 2px
    borderRadius: '0px 2px 4px 0px',
  },
}))
