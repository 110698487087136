import { AppSelector } from 'app/store'
import { pathOr } from 'ramda'
import { Notification, notificationStoreKey } from 'core/notifications/notificationReducers'
import { INotificationsSelector } from './model'
import { capitalizeString } from 'utils/misc'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { pathStr } from 'utils/fp'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'

export const notificationsSelector: AppSelector<INotificationsSelector[]> = createSharedSelector(
  [
    (state): Notification[] =>
      pathOr<Notification[]>([], [notificationStoreKey, 'notifications'])(state),
    allClustersSelector,
  ],
  (rawNotifications, allClusters) => {
    return rawNotifications.map(
      (notification): INotificationsSelector => {
        const matchingCluster = allClusters.find(
          (cluster) => cluster.uuid === notification?.data?.params?.clusterId,
        )
        const k8sResourceAccessor = notification?.data?.apiErrorMetadata?.k8sResourceAccessor
        const k8sResourceName =
          k8sResourceAccessor && notification?.data
            ? pathStr(k8sResourceAccessor, notification?.data)
            : 'N/A'
        return {
          ...notification,
          cluster: matchingCluster,
          namespace: notification?.data?.params?.namespace,
          isClusterError: notification?.data?.isClusterError,
          k8sResource: notification?.data?.apiErrorMetadata?.k8sResource,
          k8sResourceName,
          managementApi: capitalizeString(notification?.data?.apiClassMetadata?.apiClass),
          responseData: notification?.data?.response?.data,
          responseConfig: notification?.data?.response?.config,
          rawNotification: {
            id: notification?.id,
            title: notification?.title,
            message: notification?.message,
            date: notification?.date,
            type: notification?.type,
            params: notification?.data?.params,
            response: notification?.data?.response,
          },
        }
      },
    )
  },
)
