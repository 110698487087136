import { AppPlugins, pluginRoutePrefix } from 'app/constants'
import { Route } from 'core/plugins/route'

export const metal3Routes = {
  comingSoonMetal3: Route.register({
    url: `${pluginRoutePrefix}/overview`,
    defaultParams: {
      plugin: AppPlugins.Metal3,
    },
    name: 'Metal3:Overview',
  }),
}
