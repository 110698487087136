import { ReactNode } from 'react'
import { Notification } from 'core/notifications/notificationReducers'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'

export enum MessageTypes {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

export interface MessageOptions {
  id: string
  text: string
  variant: MessageTypes
  isOpen: boolean
  onClose: () => void
}

export interface BannerContentOptions {
  id: string
  content: ReactNode
  dismissable: boolean
  onClose: () => void
}

export interface INotificationsSelector extends Notification {
  isClusterError: boolean
  k8sResource: string
  k8sResourceName: string
  managementApi: string
  namespace: string
  responseData: any
  responseConfig: any
  rawNotification: any
  cluster: CombinedClusterSelector
}
