import ApiClient from 'api-client/ApiClient'
import { UserPreferences } from 'app/constants'
import {
  preferencesActions,
  preferencesStoreKey,
  PreferencesState,
} from 'core/session/preferencesReducers'
import { sessionStoreKey, SessionState } from 'core/session/sessionReducers'
import { pathOr, prop, mergeRight } from 'ramda'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const { preferenceStore } = ApiClient.getInstance()

interface ScopedPreferences<T> {
  prefs: Partial<T>
  updatePrefs: (prefs: Partial<T>) => void
  getUserPrefs: (username: string) => Partial<T>
  fetchUserDefaults: (defaultsKey: UserPreferences) => any
  updateUserDefaults: (defaultsKey: UserPreferences, defaults: any, replace?: boolean) => void
  deleteUserDefaults: (defaultsKey: UserPreferences) => void
}

// @todo fix these typings
const useScopedPreferences = <P extends Record<string, unknown>>(
  key = 'root',
  defaultPrefs: Partial<P> = {},
): ScopedPreferences<P> => {
  const selectPrefsState = prop<string, PreferencesState>(preferencesStoreKey)
  const selectSessionState = prop<string, SessionState>(sessionStoreKey)
  const allPrefs = useSelector(selectPrefsState)
  const { username, userDetails } = useSelector(selectSessionState)
  const { id } = userDetails || {}
  const prefs = useMemo<Partial<P>>(
    () => ({
      ...defaultPrefs,
      ...pathOr<Partial<P>>({}, [username, key], allPrefs),
    }),
    [defaultPrefs, username, key, allPrefs],
  )
  const dispatch = useDispatch()
  const updatePrefs = useCallback<(prefs: Partial<P>) => void>(
    (prefs) => {
      if (!username) {
        console.error('Unable to update user preferences. Session has not been initialized')
        return
      }
      dispatch(
        preferencesActions?.updatePrefs({
          username,
          key,
          prefs,
        }),
      )
    },
    [username],
  )

  const getUserPrefs = useCallback(
    (username: string) => ({
      ...(defaultPrefs || {}),
      ...pathOr<Partial<P>>({}, [username, key], allPrefs),
    }),
    [defaultPrefs, allPrefs, key],
  )

  const fetchUserDefaults = async (defaultsKey: UserPreferences) => {
    // Removed check if the preference already exists, because it may have been updated
    // on another browser
    if (!defaultsKey) return
    try {
      const response: any = await preferenceStore.getUserPreference(id, defaultsKey)
      if (!response) return
      const defaults = JSON.parse(response.value)
      dispatch(
        preferencesActions?.updatePrefs({
          username,
          key: ['defaults', defaultsKey],
          prefs: defaults,
        }),
      )
    } catch (err) {
      dispatch(
        preferencesActions?.setPrefs({
          username,
          key: ['defaults', defaultsKey],
          prefs: null,
        }),
      )
    }
  }

  const updateUserDefaults = useCallback<
    (defaultsKey: UserPreferences, value: Partial<P>, replace: boolean) => void
  >(
    (defaultsKey, value, replace = false) => {
      if (!username) {
        console.error('Unable to update user preferences. Session has not been initialized')
        return
      }
      const updatedPrefs = replace ? value : mergeRight(prefs[defaultsKey] || {}, value)
      preferenceStore.setUserPreference(id, defaultsKey, updatedPrefs)

      if (replace) {
        dispatch(
          preferencesActions?.setPrefs({
            username,
            key: ['defaults', defaultsKey],
            prefs: value,
          }),
        )
      } else {
        dispatch(
          preferencesActions?.updatePrefs({
            username,
            key: ['defaults', defaultsKey],
            prefs: value,
          }),
        )
      }
    },
    [username, id, prefs],
  )

  const deleteUserDefaults = useCallback<(defaultsKey: UserPreferences) => void>(
    (defaultsKey) => {
      if (!username) {
        console.error('Unable to delete user preference value. Session has not been initialized')
        return
      }
      preferenceStore.deleteUserPreference(id, defaultsKey)
      dispatch(
        preferencesActions?.removePrefs({
          username,
          key: ['defaults', defaultsKey],
        }),
      )
    },
    [username, id, prefs],
  )

  return {
    prefs,
    updatePrefs,
    getUserPrefs,
    fetchUserDefaults,
    updateUserDefaults,
    deleteUserDefaults,
  }
}

export default useScopedPreferences
