import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Checkbox from 'core/components/Checkbox'
import Tooltip from 'core/elements/tooltip'
import PropTypes from 'prop-types'
import { assoc } from 'ramda'
import React from 'react'
import { compose } from 'utils/fp'
import { memoize } from 'utils/misc'
import generateTestId from 'utils/test-helpers'
import { bottomLeft, bottomRight } from 'core/elements/menu/defaults'

const styles = (theme) => ({
  root: {
    // backgroundColor: theme.palette.grey[50],
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    '& > tr': {
      height: 46,
    },
  },
  checkboxCell: {
    display: ['flex', '!important'],
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkbox: {
    paddingRight: 4,
  },
  headLabel: {
    color: theme.palette.grey[500],
    whiteSpace: 'nowrap',
    ...theme.typography.caption2,

    '& .MuiTableSortLabel-icon': {
      color: [theme.palette.grey[500], '!important'],
    },
    '&.MuiTableSortLabel-active': {
      color: theme.palette.grey[700],
    },
  },
  cellLabel: {
    color: [theme.palette.text.primary, '!important'],
  },
  checkAllCell: {
    paddingRight: 0,
    minWidth: 60,
  },
})

class ListTableHead extends React.PureComponent {
  createSortHandler = memoize((id) => (event) => {
    const { onRequestSort } = this.props
    if (onRequestSort) {
      onRequestSort(event, id)
    }
  })

  createDragHandler = memoize((id) => (e) => {
    e.dataTransfer.setData('columnId', id)
    this.setState(assoc('dragging', true))
  })

  createDropHandler = memoize((id) => (e) => {
    const sourceColumnId = e.dataTransfer.getData('columnId')
    this.props.onColumnsSwitch(sourceColumnId, id)
  })

  handleDragOver = (e) => e.preventDefault()

  render() {
    const {
      classes,
      canDragColumns,
      blankFirstColumn,
      checked,
      columns,
      numSelected,
      onSelectAllClick,
      order,
      orderBy,
      rowCount,
      showCheckboxes,
      multiSelection,
    } = this.props

    const renderHeaderCheckbox = !blankFirstColumn && showCheckboxes
    const headerCheckbox = !renderHeaderCheckbox ? null : (
      <TableCell
        padding="checkbox"
        key="_checkAll"
        className={clsx(classes.cellLabel, classes.checkAllCell)}
      >
        {multiSelection && (
          <label className={classes.checkboxCell}>
            <Checkbox
              className={classes.checkbox}
              indeterminate={!checked && numSelected > 0 && numSelected < rowCount}
              checked={checked}
              onChange={onSelectAllClick}
              color="primary"
            />
            {numSelected > 0 ? <span>({numSelected})</span> : null}
          </label>
        )}
      </TableCell>
    )

    const firstBlank = blankFirstColumn ? (
      <TableCell padding="checkbox" key="_checkAll" className={classes.cellLabel} />
    ) : null

    return (
      <TableHead className={classes.root}>
        <TableRow>
          {headerCheckbox}
          {firstBlank}
          {columns.map((column, idx) => (
            <TableCell
              data-testid={generateTestId(column.id)}
              className={classes.cellLabel}
              draggable={canDragColumns}
              onDragStart={canDragColumns ? this.createDragHandler(column.id) : null}
              onDragOver={canDragColumns ? this.handleDragOver : null}
              onDrop={canDragColumns ? this.createDropHandler(column.id) : null}
              key={column.id}
              numeric={column.numeric}
              padding={column.disablePadding ? 'none' : 'normal'}
              sortDirection={column.sort !== false && orderBy === column.id ? order : false}
            >
              <Tooltip
                message={column.tooltip || column.label || ''}
                align={column.numeric ? bottomLeft.align : bottomRight.align}
              >
                {!column.disableSorting ? (
                  <TableSortLabel
                    className={classes.headLabel}
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label} {idx === 0 ? `(${rowCount})` : ''}
                  </TableSortLabel>
                ) : (
                  <span className={classes.headLabel}>
                    {column.label} {idx === 0 ? `(${rowCount})` : ''}
                  </span>
                )}
              </Tooltip>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
}

ListTableHead.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      tooltip: PropTypes.string,
      display: PropTypes.bool,
      excluded: PropTypes.bool,
      disableSorting: PropTypes.bool,
    }),
  ).isRequired,
  canDragColumns: PropTypes.bool,
  onColumnsSwitch: PropTypes.func,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  showCheckboxes: PropTypes.bool,
  blankFirstColumn: PropTypes.bool,
}

ListTableHead.defaultProps = {
  numSelected: 0,
}

export default compose(withStyles(styles))(ListTableHead)
