import React, { useCallback, useEffect, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { partition } from 'ramda'
import moment from 'moment'
import { styled } from '@material-ui/styles'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import { ErrorNotificationType, notificationActions } from 'core/notifications/notificationReducers'
import { cloudProviderTypes } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import { capitalizeString } from 'utils/misc'
import { switchCase } from 'utils/fp'
import { routes } from 'core/utils/routes'
import { notificationsSelector } from './selectors'
import SimpleLink from '../SimpleLink'
import NotificationDetails from './notification-details'
import Grid from 'core/elements/grid'
import { INotificationsSelector } from 'core/components/notifications/model'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import {
  ClusterTypes,
  HealthStatus,
  TransientStatus,
} from 'app/plugins/infrastructure/components/clusters/model'
import ApiServerHealthCell from 'app/plugins/infrastructure/components/clusters/cluster-cells/ApiServerHealthCell'
import ConnectionStatusCell from 'app/plugins/infrastructure/components/clusters/cluster-cells/ConnectionStatusCell'
import Platform9ComponentsStatusCell from 'app/plugins/infrastructure/components/clusters/cluster-cells/Platform9ComponentsStatusCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import StatusesCell from 'app/plugins/infrastructure/components/clusters/capi/grid-cells/StatusesCell'

const NotificationTableCell = styled(({ value, route = undefined }) => {
  if (!route) {
    return (
      <Text variant="body2" component="span" lineClamp={2}>
        {value || '-'}
      </Text>
    )
  }
  return (
    <SimpleLink src={route} lineClamp={2}>
      {value || '-'}
    </SimpleLink>
  )
})({
  maxWidth: 150,
  maxHeight: 90,
  overflow: 'hidden',
  wordBreak: 'break-word',
})

const getNotificationTypeStatus = (status) => {
  const variant = switchCase(
    {
      warning: 'warning',
      error: 'error',
      info: 'primary',
    },
    'unknown',
  )(status)
  return { variant, label: capitalizeString(status) }
}

const renderCell = (value) => <NotificationTableCell value={value} />
const renderLinkCell = (value, { id }, notificationType) => (
  <NotificationTableCell
    value={value}
    route={routes.notifications.detail.path({ id, notificationType })}
  />
)

function ClusterHealthCell(
  props: GridCellProps<INotificationsSelector, HealthStatus | TransientStatus>,
) {
  const {
    item: { cluster },
    value,
  } = props
  if (!value) return <NotificationTableCell value={value} />
  if (cluster?.clusterType === ClusterTypes.Capi) {
    return <StatusesCell item={cluster} />
  }

  return (
    <>
      <ConnectionStatusCell {...props} item={cluster} />
      {cluster?.clusterType === ClusterTypes.Normal && (
        <Platform9ComponentsStatusCell {...props} item={cluster} />
      )}
      <ApiServerHealthCell {...props} item={cluster} />
    </>
  )
}

const getColumns = (columns, notificationType): GridViewColumn<INotificationsSelector>[] => [
  {
    key: 'date',
    id: 'date',
    label: 'Date',
    formatFn: (value) => moment(value).format('LLL'),
    sortWith: (prevDate, nextDate) => (moment(prevDate).isBefore(nextDate) ? 1 : -1),
  },
  {
    key: 'type',
    id: 'type',
    label: 'Type',
    CellComponent: createGridStatusCell({
      dataFn: getNotificationTypeStatus,
    }),
  },
  {
    key: 'title',
    id: 'title',
    label: 'Title',
    render: (value, data) => renderLinkCell(value, data, notificationType),
  },
  {
    key: 'message',
    id: 'message',
    label: 'Message',
    render: (value, data) => renderLinkCell(value, data, notificationType),
  },
  ...columns,
  { key: 'cluster.name', id: 'cluster.name', label: 'Cluster Name', render: renderCell },
  {
    key: 'cluster.cloudProviderType',
    id: 'cluster.cloudProviderType',
    label: 'Cluster Type',
    render: (type) => renderCell(cloudProviderTypes[type] || capitalizeString(type)),
  },
  { key: 'cluster', id: 'cluster', label: 'Cluster Health', CellComponent: ClusterHealthCell },
  { key: 'cluster.version', id: 'cluster.version', label: 'K8s Version', render: renderCell },
]

const clusterErrorColumns: GridViewColumn<INotificationsSelector>[] = getColumns(
  [
    { key: 'k8sResource', id: 'k8sResource', label: 'K8s Resource', render: renderCell },
    { key: 'namespace', id: 'namespace', label: 'Namespace', render: renderCell },
  ],
  ErrorNotificationType.ClusterError,
)

const pf9EventColumns: GridViewColumn<INotificationsSelector>[] = getColumns(
  [{ key: 'managementApi', id: 'managementApi', label: 'Management Api', render: renderCell }],
  ErrorNotificationType.Platform9Event,
)

const ClearButton = styled(Button)({
  marginRight: 16,
})

const NotificationsListView = ({ label, data, columns, onClear, notificationType, onDelete }) => {
  return (
    <Grid<INotificationsSelector>
      uniqueIdentifier="id"
      label={label}
      // onDelete={onDelete}
      extraToolbarContent={
        <ClearButton onClick={() => onClear(notificationType)}>Clear All</ClearButton>
      }
      columns={columns}
      data={data}
    />
  )
}

export default function NotificationsPage() {
  const { match, history } = useReactRouter()
  const notifications = useSelector(notificationsSelector)
  const dispatch = useDispatch()
  const [clusterErrors, pf9Events] = useMemo(
    () => partition((notification) => notification.isClusterError, notifications),
    [notifications],
  )

  const selectedNotification = useMemo(
    () => notifications.find((notification) => notification.id === match.params.id),
    [match.params.id, notifications],
  )

  useEffect(() => {
    handleMarkTabRead(match.params.notificationType)
  }, [])

  const handleMarkTabRead = useCallback((notificationType) => {
    dispatch(notificationActions.markAsRead(notificationType))
  }, [])

  const clearNotifications = useCallback((notificationType) => {
    dispatch(notificationActions.clearNotifications(notificationType))
  }, [])
  const handleDeleteOne = useCallback(([{ id }]) => {
    dispatch(notificationActions.clearNotificationById(id))
  }, [])
  const handleClose = useCallback(() => {
    const notificationType = selectedNotification?.isClusterError
      ? ErrorNotificationType.ClusterError
      : ErrorNotificationType.Platform9Event
    history.push(routes.notifications.list.path({ notificationType }))
  }, [selectedNotification])

  return (
    <>
      {selectedNotification && (
        <NotificationDetails notification={selectedNotification} onClose={handleClose} />
      )}
      <Tabs
        route={routes.notifications.list}
        routeKey="notificationType"
        previewInHeader
        onClick={handleMarkTabRead}
      >
        <Tab value={ErrorNotificationType.ClusterError} label="Cluster Errors">
          <NotificationsListView
            label="Cluster Errors"
            data={clusterErrors}
            columns={clusterErrorColumns}
            onClear={clearNotifications}
            onDelete={handleDeleteOne}
            notificationType={ErrorNotificationType.ClusterError}
          />
        </Tab>
        <Tab value={ErrorNotificationType.Platform9Event} label="Platform9 Events">
          <NotificationsListView
            label="Platform9 Events"
            data={pf9Events}
            columns={pf9EventColumns}
            onClear={clearNotifications}
            onDelete={handleDeleteOne}
            notificationType={ErrorNotificationType.Platform9Event}
          />
        </Tab>
      </Tabs>
    </>
  )
}
