import React, { useCallback, FC } from 'react'
import useToggler from 'core/hooks/useToggler'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import clsx from 'clsx'
import { middleLeft } from 'core/elements/menu/defaults'

const useStyles = makeStyles<Theme>((theme) => ({
  text: {
    color: theme.components.dropdown.color,
  },
  icon: {
    marginTop: 2,
    fontWeight: 400,
  },
  dropdownButton: {
    display: 'grid',
    gridTemplateColumns: '18px auto',
    padding: '8px 16px',
    alignItems: 'center',
    gap: 8,
    cursor: 'pointer',
    '&:hover': {
      background: theme.components.dropdown.selectedBackground,
    },
  },
  disabled: {
    color: theme.components.button.secondary.disabledColor,
  },
  noClick: {
    cursor: 'not-allowed',
  },
}))

interface Props<T> {
  label: string
  DialogComponent: FC
  entity: T
  icon?: string
  solidIcon?: boolean
  disabled?: boolean
  customDialogProps?: any
  refreshFn?: () => void
  onDialogClose?: () => void
  tooltip?: string | React.ReactNode
}

// Todo: Potentially add all functionality from OverviewActions component
export default function DialogDropdownButton<T>({
  label,
  DialogComponent,
  icon,
  disabled = false,
  entity,
  customDialogProps,
  refreshFn,
  onDialogClose,
  tooltip,
}: Props<T>) {
  const classes = useStyles()
  const [dialogOpened, toggleDialogOpened, setDialogOpened] = useToggler()
  const handleDialogClose = useCallback(
    (success?: boolean) => {
      if (success === true && refreshFn) {
        refreshFn()
      }
      setDialogOpened(false)
      if (onDialogClose) {
        onDialogClose()
      }
    },
    [onDialogClose],
  )
  return (
    <>
      <Tooltip message={tooltip} align={middleLeft.align}>
        <div
          className={clsx(classes.dropdownButton, {
            [classes.noClick]: disabled,
          })}
          onClick={() => {
            if (disabled) {
              return
            }
            toggleDialogOpened()
          }}
        >
          <FontAwesomeIcon
            className={clsx(classes.text, classes.icon, {
              [classes.disabled]: disabled,
            })}
            size="md"
          >
            {icon}
          </FontAwesomeIcon>
          <Text
            variant="caption1"
            className={clsx(classes.text, {
              [classes.disabled]: disabled,
            })}
          >
            {label}
          </Text>
        </div>
      </Tooltip>
      {dialogOpened && (
        <DialogComponent
          onClose={handleDialogClose}
          rows={[entity]}
          {...(customDialogProps || {})}
        />
      )}
    </>
  )
}
