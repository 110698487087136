import { publicRoutes } from './publicRoutes'
import { kubernetesRoutes } from './kubernetesRoutes'
import { kubevirtRoutes } from './kubevirtRoutes'
import { argoRoutes } from './argoRoutes'
import { metal3Routes } from './metal3Routes'
import { accountRoutes } from './accountRoutes'
import { infrastructureRoutes } from './infrastructureRoutes'

export const routes = {
  ...publicRoutes,
  ...kubernetesRoutes,
  ...kubevirtRoutes,
  ...argoRoutes,
  ...metal3Routes,
  ...accountRoutes,
  ...infrastructureRoutes,
}
