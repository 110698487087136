import React, { PropsWithChildren, useCallback, useState } from 'react'
import useReactRouter from 'use-react-router'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import clsx from 'clsx'
import { SidebarCustomPanePortal } from 'core/elements/header/portals'
import PassiveHeaderLink from '../passive-header-link'
import { dashboardUrl } from 'app/constants'
import ConfirmationDialog from '../ConfirmationDialog'
export interface WizardMetaProps {
  backUrl?: any
  className?: any
  showExitConfirmationDialog?: boolean
}

export default function WizardMeta({
  children,
  backUrl = dashboardUrl,
  className,
  showExitConfirmationDialog = false,
}: PropsWithChildren<WizardMetaProps>) {
  const classes = useStyles()
  const { history } = useReactRouter()
  const [showDialog, setShowDialog] = useState(false)
  const handleDialogOpen = () => setShowDialog(true)
  const handleDialogClose = () => setShowDialog(false)
  const handleConfirmLeave = useCallback(() => history.goBack(), [history])

  return (
    <SidebarCustomPanePortal>
      <div className={clsx(classes.wizardMeta, className)}>
        <PassiveHeaderLink
          icon="chevron-left"
          text={showExitConfirmationDialog ? 'Cancel' : 'Go Back'}
          onClick={showExitConfirmationDialog ? handleDialogOpen : handleConfirmLeave}
          className={classes.titleLink}
        />
        {showExitConfirmationDialog && (
          <ConfirmationDialog
            open={showDialog}
            title="Leave this page?"
            text="You are leaving the page with unsaved changes. This action cannot be reverted."
            cancelText="Stay on Page"
            confirmText="Leave Page"
            onCancel={handleDialogClose}
            onConfirm={handleConfirmLeave}
          />
        )}
        {/* {icon ? <figure className={classes.wizardFigure}>{icon}</figure> : null} */}
        {children}
      </div>
    </SidebarCustomPanePortal>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  wizardMeta: {
    padding: '20px 0px 20px 32px',
    display: 'grid',
    gridAutoRows: 'max-content',
    gridGap: 16,
  },
  titleLink: {
    marginBottom: 34,
    width: 'max-content',
    '& > *': {
      color: [theme.components.wizard.step.bubbleLabel, '!important'],
    },

    '&:hover > *': {
      color: [theme.components.wizard.step.bubbleActiveLabel, '!important'],
    },
  },
}))
