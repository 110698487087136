import React, { useCallback, FC } from 'react'
import useToggler from 'core/hooks/useToggler'
import Button from '../button'

// export interface GridDialogProps<T> {
//   onClose: (success?: boolean) => void
//   rows: T[]
// }

interface Align {
  vertical: string
  horizontal: string
}

interface Props<T> {
  label: string
  DialogComponent: FC
  entity: T
  icon?: string
  disabled?: boolean
  customDialogProps?: any
  refreshFn?: () => void
  info?: string | React.ReactNode
  infoPlacement?: Align
}

export default function DialogButton<T>({
  label,
  DialogComponent,
  icon,
  disabled = false,
  entity,
  customDialogProps,
  refreshFn,
  info,
  infoPlacement,
}: Props<T>) {
  const [dialogOpened, toggleDialogOpened, setDialogOpened] = useToggler()
  const handleDialogClose = useCallback((success?: boolean) => {
    if (success === true && refreshFn) {
      refreshFn()
    }
    setDialogOpened(false)
  }, [])
  return (
    <>
      <Button
        disabled={disabled}
        variant="secondary"
        onClick={toggleDialogOpened}
        icon={icon}
        info={info}
        infoPlacement={infoPlacement}
      >
        {label}
      </Button>
      {dialogOpened && (
        <DialogComponent
          onClose={handleDialogClose}
          rows={[entity]}
          {...(customDialogProps || {})}
        />
      )}
    </>
  )
}
