import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DDUHealth } from 'api-client/model'
import { assocPath, mergeLeft, Dictionary } from 'ramda'

interface SystemStatus {
  taskState: DDUHealth['task_state']
  desiredServices: DDUHealth['desired_services']
  readyServices: DDUHealth['ready_services']
  serviceDetails: DDUHealth['service_details']
}

export interface ClientState {
  endpoints: Dictionary<string>
  breadcrumbParams: Dictionary<string>
  frame: {
    sidebarState: 'expanded' | 'collapsed'
    sidebarPane: 'custom' | 'default'
  }
  systemStatus: SystemStatus
}
export const initialState: ClientState = {
  endpoints: {},
  breadcrumbParams: {},
  frame: {
    sidebarState: 'expanded',
    sidebarPane: 'default',
  },
  systemStatus: {
    taskState: 'unknown',
    desiredServices: 0,
    readyServices: 0,
    serviceDetails: {},
  },
}

const { name: clientStoreKey, reducer: clientReducers, actions: clientActions } = createSlice({
  name: 'client',
  initialState,
  reducers: {
    initClient: (state, { payload }: PayloadAction<Partial<ClientState>>) => {
      return mergeLeft(payload, initialState)
    },
    updateBreadcrumbParams: (state, { payload }: PayloadAction<Dictionary<string>>) => {
      return mergeLeft({ breadcrumbParams: payload }, state)
    },
    resetBreadcrumbParams: (state) => {
      return mergeLeft({ breadcrumbParams: {} }, state)
    },
    updateFrameValues: (state, { payload }: PayloadAction<ClientState['frame']>) => {
      return mergeLeft({ frame: payload }, state)
    },
    setSidebarState: (state, { payload }: PayloadAction<ClientState['frame']['sidebarState']>) => {
      return mergeLeft({ frame: { ...state.frame, sidebarState: payload } }, state)
    },
    setSidebarPane: (state, { payload }: PayloadAction<ClientState['frame']['sidebarPane']>) => {
      return mergeLeft({ frame: { ...state.frame, sidebarPane: payload } }, state)
    },
    setSystemStatus: (state, { payload }: PayloadAction<DDUHealth>) => {
      return {
        ...state,
        systemStatus: {
          taskState: payload.task_state,
          desiredServices: payload.desired_services,
          readyServices: payload.ready_services,
          serviceDetails: payload.service_details,
        },
      }
    },
    setEndpoint: (state, { payload }: PayloadAction<{ clientKey: string; value: string }>) => {
      return assocPath(['endpoints', payload.clientKey], payload.value, state)
    },
    updateClient: (state, { payload }: PayloadAction<Partial<ClientState>>) => {
      return mergeLeft(payload, state)
    },
    destroyClient: () => {
      return initialState
    },
  },
})

export { clientStoreKey, clientActions }
export default clientReducers
