import axios from 'axios'
import { isNilOrEmpty, pathStrOr } from 'utils/fp'
import Bugsnag from 'utils/bugsnag'
import { DocumentMetaCls } from 'core/components/DocumentMeta'
import { driftScriptContent, segmentScriptContent } from 'utils/tracking'
import { head, pick, propEq } from 'ramda'
import Watchdog from 'core/watchdog'
import maybeUpdateLogo from 'core/watchdog/maybe-update-logo'
import systemHealthCheck from 'core/watchdog/system-health'
import {
  activateUserUrl,
  CustomerTiers,
  forgotPasswordUrl,
  GlobalPreferences,
  loginUrl,
  loginWithCookieUrl,
  loginWithSsoUrl,
  resetPasswordThroughEmailUrl,
  resetPasswordUrl,
  ssoEnabledTiers,
  AppPlugins,
} from 'app/constants'
import store from 'app/store'
import ApiClient from 'api-client/ApiClient'
import { getCookieValue } from 'utils/misc'
import { cacheActions } from 'core/caching/cacheReducers'
import { sessionActions } from 'core/session/sessionReducers'
import { notificationActions } from 'core/notifications/notificationReducers'
import { loadUserTenants } from 'account/components/userManagement/tenants/new-actions'
import { updateSession } from 'account/components/userManagement/users/new-actions'
import { CustomWindow } from 'app/polyfills/window'
import config from 'app-config'

const urlBase = process.env.NODE_ENV !== 'production' ? config.apiHost : ''
declare let window: CustomWindow

const { preferenceStore, keystone } = ApiClient.getInstance()
const { dispatch } = store

const keysToPluck = [
  'deviceMemory',
  'hardwareConcurrency',
  'appCodeName',
  'appName',
  'appVersion',
  'cookieEnabled',
  'maxTouchPoints',
  'onLine',
  'platform',
  'product',
  'productSub',
  'vendor',
  'vendorSub',
  'webdriver',
]
const whiteListedUrls = [loginUrl, forgotPasswordUrl, resetPasswordUrl, activateUserUrl]
const isWhitelistedUrl = (pathname): boolean => {
  // Allow the following paths to load as entry point when user is not logged in
  return whiteListedUrls.includes(pathname)
}

export const getFeatures = async () => {
  const features = await axios.get(`${urlBase}/clarity/features.json`).catch(() => null)
  const customerTier = pathStrOr(CustomerTiers.Freedom, 'data.customer_tier', features)
  const sandboxFlag = pathStrOr(false, 'data.experimental.sandbox', features)
  const analyticsOff = pathStrOr(false, 'data.experimental.analyticsOff', features)
  const airgapped = pathStrOr(false, 'data.experimental.airgapped', features)
  const kplane = pathStrOr(false, 'data.experimental.kplane', features)
  const sso = pathStrOr(false, 'data.experimental.sso', features)
  const sandbox = pathStrOr(false, 'data.experimental.sandbox', features)
  const duVersion = pathStrOr('', 'data.releaseVersion', features)
  const overridePrimaryLogo = pathStrOr('', 'override_primary_logo', features)
  const overridePrimaryFavicon = pathStrOr('', 'override_primary_favicon', features)

  // pluginFeatures
  const infrastructureOff = pathStrOr(false, 'data.experimental.infrastructureOff', features)
  const kubernetesOff = pathStrOr(false, 'data.experimental.kubernetesOff', features)
  const kubevirtOff = pathStrOr(false, 'data.experimental.kubevirtOff', features)
  const metal3Off = pathStrOr(false, 'data.experimental.metal3Off', features)
  const argoOff = pathStrOr(false, 'data.experimental.argoOff', features)
  const accountOff = pathStrOr(false, 'data.experimental.accountOff', features)
  return {
    customerTier,
    sandboxFlag,
    sandbox,
    analyticsOff,
    airgapped,
    kplane,
    sso,
    duVersion,
    overridePrimaryLogo,
    overridePrimaryFavicon,
    plugins: [
      { id: AppPlugins.Infrastructure, isDisabled: infrastructureOff },
      { id: AppPlugins.Kubernetes, isDisabled: kubernetesOff },
      { id: AppPlugins.KubeVirt, isDisabled: kubevirtOff },
      { id: AppPlugins.Metal3, isDisabled: metal3Off },
      { id: AppPlugins.ArgoCD, isDisabled: argoOff },
      { id: AppPlugins.MyAccount, isDisabled: accountOff },
    ],
  }
}

export const loadLoginFeaturesAndTracking = async () => {
  /* eslint-disable */
  // Check for sandbox flag, if false identify the user in Segment using Keystone ID
  // This needs to be done here bc this needs to be done before login.
  // Features are requested again later for the specific logged-in region,
  // whereas this one is done on the master DU from which the UI is served.
  // Ignore exception if features.json not found (for local development)
  const {
    customerTier,
    sandboxFlag,
    analyticsOff,
    airgapped,
    kplane,
    sso,
    duVersion,
    overridePrimaryFavicon,
    overridePrimaryLogo,
  } = await getFeatures()

  Bugsnag.addMetadata('App', {
    customerTier,
    duVersion,
  })
  Bugsnag.addMetadata('Device', pick(keysToPluck, window?.navigator || {}))

  // Segment tracking
  if (!analyticsOff && !airgapped) {
    DocumentMetaCls.addScriptElementToDomBody({
      id: 'segmentCode',
      textContent: segmentScriptContent,
    })
  }

  // Drift tracking code for live demo
  if (sandboxFlag && !airgapped) {
    DocumentMetaCls.addScriptElementToDomBody({
      id: 'driftCode',
      textContent: driftScriptContent,
    })
  }

  if (!kplane) {
    // if traditional DU, maybe set custom theme.
    Watchdog.register({
      handler: maybeUpdateLogo(overridePrimaryLogo, overridePrimaryFavicon),
      frequency: 0, // Only set this once
    })
  }
  if (kplane) {
    Watchdog.register({ handler: systemHealthCheck, frequency: 1000 * 60 * 10 })
  }

  // Legacy DU & DDU have different conditions
  return {
    customerTier,
    ssoEnabled: (kplane && ssoEnabledTiers.includes(customerTier)) || (!kplane && sso),
  }
}

const getUserDetails = async (user) => {
  if (typeof window.analytics === 'undefined') return

  // Need this here again bc not able to use AppContainer state and ensure
  // that sandbox state would be set on time for both users logging in for
  // first time and for users who are already logged in
  const { customerTier, sandbox, kplane } = await getFeatures()

  // Identify the user in Segment using Keystone ID
  if (sandbox) {
    return window.analytics.identify()
  }

  const payload: any = {
    email: user.email,
  }
  if (
    kplane &&
    (customerTier === CustomerTiers.Growth || customerTier === CustomerTiers.Enterprise)
  ) {
    try {
      const response = await preferenceStore.getGlobalPreference(
        GlobalPreferences.CustomerExternalId,
      )
      const externalId = response?.value
      if (externalId) {
        payload.accountExternalId = externalId
        payload.contactExternalId = user.email
      }
    } catch (err) {}
  }
  window.analytics.identify(user.id, payload)
}

export const validateSession = async (getUserPrefs, session, history) => {
  const { pathname, hash } = history.location
  // Bypass the session check if we are accessing a whitelisted url
  if (isWhitelistedUrl(pathname) || hash.includes(resetPasswordThroughEmailUrl)) {
    return
  }

  // Validate and "refresh" the existing session
  const {
    issuedAt,
    username = session.username,
    unscopedToken,
    expiresAt,
    ssoLogin,
  } = await restoreSession(pathname, session, history)

  const { isSsoToken } = session

  if (unscopedToken) {
    const { currentTenant, currentRegion } = getUserPrefs(username)
    await setupSession({
      currentTenant,
      currentRegion,
      username,
      unscopedToken,
      expiresAt,
      issuedAt,
      isSsoToken: isSsoToken || ssoLogin,
    })
  } else {
    // Order matters
    dispatch(sessionActions.destroySession())
    dispatch(cacheActions.clearCache())
    dispatch(notificationActions.clearAllNotifications())
  }
}

export const setupSession = async ({
  currentTenant,
  currentRegion,
  username,
  unscopedToken,
  expiresAt,
  issuedAt,
  isSsoToken,
}) => {
  const tenants = await loadUserTenants()
  if (isNilOrEmpty(tenants)) {
    throw new Error('No tenants found, please contact support')
  }
  const activeTenant =
    tenants.find(propEq('id', currentTenant)) ||
    tenants.find(propEq('name', 'service')) ||
    head(tenants)
  const activeTenantId = activeTenant.id

  // Order matters
  const user = await updateSession({
    username,
    unscopedToken,
    expiresAt,
    issuedAt,
    isSsoToken,
    currentTenantId: activeTenantId,
    currentRegion,
  })
  if (user) {
    await getUserDetails(user)
  }
}

const restoreSession = async (
  pathname,
  session,
  history,
): Promise<{
  username?: string
  unscopedToken?: string
  expiresAt?: string
  issuedAt?: string
  ssoLogin?: boolean
  // eslint-disable-next-line @typescript-eslint/require-await
}> => {
  const { keystone } = ApiClient.getInstance()

  // When trying to login with cookie with pmkft
  if (pathname === loginWithCookieUrl) {
    const urlParams = new URLSearchParams(history.location.search)
    const scopedToken = urlParams.get('token') || getCookieValue('X-Auth-Token')

    // Start from scratch to make use of prebuilt functions
    // for standard login page
    return keystone.getUnscopedTokenWithToken(scopedToken)
  }

  if (pathname === loginWithSsoUrl) {
    return keystone.authenticateSso()
  }

  // Attempt to restore the session
  const { username, unscopedToken: currUnscopedToken } = session
  if (username && currUnscopedToken) {
    // We need to make sure the token has not expired.
    return keystone.renewToken(currUnscopedToken)
  }
  return {}
}
