import { either, equals, find, isNil, path, pickAll, pipe, reject } from 'ramda'
import { allKey } from 'app/constants'
import store from 'app/store'
import { arrayIfNil } from 'utils/fp'
import { memoizedObj } from 'utils/misc'
import { cacheStoreKey, loadingStoreKey, paramsStoreKey } from 'core/caching/cacheReducers'

const listFnWithDependencies = (listFn, deps) => async (params, refetch, updateLoadingState) => {
  const memoizedParams = memoizedObj(params)
  // Don't wait for the dependent data
  loadDependentData(deps, params, refetch, updateLoadingState, memoizedParams)
  return listFn(params)
}

async function loadDependentData(deps, params, refetch, updateLoadingState, memoizedParams) {
  for (const depLoader of deps) {
    const memoizedIndexedParams = depLoader.cache
      ? memoizedObj(
          pipe(pickAll(depLoader.indexBy), reject(either(isNil, equals(allKey))))(memoizedParams),
        )
      : memoizedParams

    if (refetch || !isDataCached(depLoader.cacheKey, memoizedIndexedParams)) {
      depLoader(params, refetch, updateLoadingState)
    }
  }
}

export default listFnWithDependencies

const isDataCached = (cacheKey, memoizedIndexedParams, state = store.getState()) =>
  pipe(
    path([cacheStoreKey, paramsStoreKey, cacheKey]),
    arrayIfNil,
    find(equals(memoizedIndexedParams)),
  )(state)
