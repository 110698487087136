import { IDataKeys } from 'k8s/datakeys.model'
import Action from 'core/actions/Action'
import store from 'app/store'
import { cacheActions } from 'core/caching/cacheReducers'

const { dispatch } = store

class DeleteAction<
  D extends keyof IDataKeys,
  P extends Record<string, unknown> = Record<string, unknown>,
  R = void
> extends Action<D, P, R> {
  public get name() {
    return 'delete'
  }

  protected async preProcess(params: P): Promise<void> {
    const { cacheKey } = this.config
    dispatch(cacheActions.setUpdating({ cacheKey, updating: true }))
  }

  protected postProcess = (result, params: P) => {
    const { cacheKey, uniqueIdentifier } = this.config

    dispatch(
      cacheActions.removeItem({
        uniqueIdentifier,
        cacheKey,
        params,
      }),
    )
    dispatch(cacheActions.setUpdating({ cacheKey, updating: false }))
  }

  protected handleError(err, params: P, options) {
    const { cacheKey } = this.config
    // Without this, if an error occurs loading can be stuck as true
    dispatch(cacheActions.setUpdating({ cacheKey, updating: false }))
    return super.handleError(err, params, options)
  }
}

export default DeleteAction
