import React from 'react'
import useReactRouter from 'use-react-router'
import { makeStyles } from '@material-ui/styles'
import FormPageContainer from 'core/containers/FormPageContainer'
// import { Grid, Paper, TextField } from '@material-ui/core'
import Button from 'core/elements/button'
import Text from 'core/elements/Text'
import useParams from 'core/hooks/useParams'
import Alert from 'core/components/Alert'
import { loginUrl } from 'app/constants'
import ApiClient from 'api-client/ApiClient'
import Input from 'core/elements/input/Input'

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 400,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 24,
  },
  formTitle: {
    textAlign: 'center',
  },
  fields: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    gap: 16,
  },
  resetPwdButton: {
    width: '100%',
    marginTop: 8,
  },
}))

const ForgotPasswordPage = (props) => {
  const { params, updateParams } = useParams({
    loading: false,
    isError: false,
    emailId: '',
    isResetSuccessful: false,
    errorMessage: 'Reset password failed',
  })
  const { history } = useReactRouter()
  const classes = useStyles()
  const { clemency } = ApiClient.getInstance()

  const handleEmailChange = () => (event) => {
    updateParams({
      emailId: event.target.value,
    })
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault()

    if (params.isResetSuccessful) {
      history.push(loginUrl)
      return
    }

    updateParams({
      loading: true,
    })

    try {
      const response = await clemency.requestNewPassword(params.emailId)

      response.code === 200
        ? updateParams({ isResetSuccessful: true, loading: false })
        : updateParams({ isError: true, loading: false })
    } catch (err) {
      updateParams({ isError: true, loading: false })
    }
  }

  const SubmitButton = ({ label, ...rest }) => (
    <Button type="submit" className={classes.resetPwdButton} {...rest}>
      {label}
    </Button>
  )

  return (
    <FormPageContainer className="forgot-password-page">
      <form className={classes.form} onSubmit={handleFormSubmit}>
        <Text variant="h3" className={classes.formTitle}>
          Password Reset
        </Text>
        <div className={classes.fields}>
          {!params.isResetSuccessful ? (
            <>
              <Input
                required
                id="email"
                label="Email"
                placeholder="Email"
                type="email"
                className={classes.textField}
                onChange={handleEmailChange()}
              />
              {params.isError && <Alert variant="error" message="Something went wrong" />}
              <SubmitButton label="Reset my password" loading={params.loading} />
            </>
          ) : (
            <>
              <Text component="p" variant="subtitle1">
                Your request was submitted successfully!
              </Text>
              <Text component="p">
                Check your email (<b>{params.emailId}</b>). You should receive instructions to reset
                your password shortly.
              </Text>
              <SubmitButton label="Return to login screen" />
            </>
          )}
        </div>
      </form>
    </FormPageContainer>
  )
}

export default ForgotPasswordPage
