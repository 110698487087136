import { AppPlugins, pluginRoutePrefix } from 'app/constants'
import { CapiDetailsPageTabs } from 'app/plugins/infrastructure/components/clusters/capi/model'
import { IClusterEditPageTabs } from 'app/plugins/infrastructure/components/clusters/model'
import { IEditImportedClusterPageTabs } from 'app/plugins/infrastructure/components/importedClusters/model'
import { Route } from 'core/plugins/route'

export const infrastructureRoutes = {
  infraDashboard: {
    root: Route.register({
      url: `${pluginRoutePrefix}/dashboard/overview`,
      name: 'Overview',
      id: 'dashboardOverview',
      breadcrumbs: new Map([
        ['dashboard', 'null'],
        ['overview', 'dashboardOverview'],
      ]),
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
    }),
    alarms: Route.register({
      url: `${pluginRoutePrefix}/dashboard/alarms`,
      id: 'alarmsList',
      breadcrumbs: new Map([
        ['dashboard', 'null'],
        ['alarms', 'alarmsList'],
      ]),
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
      name: 'Dashboard:Alarms',
    }),
  },
  cluster: {
    overview: Route.register({
      url: `${pluginRoutePrefix}/clusters/overview`,
      id: 'clustersOverview',
      breadcrumbs: new Map([
        ['clusters', null],
        ['overview', 'clustersOverview'],
      ]),
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
      name: 'Clusters:Overview',
    }),
    add: {
      root: Route.register({
        id: 'addCluster',
        url: `${pluginRoutePrefix}/clusters/add`,
        breadcrumbs: new Map([
          ['clusters', null],
          ['Create Cluster', 'addCluster'],
        ]),
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'Clusters:Add',
      }),
      aws: {
        capi: Route.register({
          url: `${pluginRoutePrefix}/clusters/aws/add/capi`,
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
          },
          name: 'Clusters:AddAws:Capi',
        }),
        'eks-capi': Route.register({
          url: `${pluginRoutePrefix}/clusters/aws/add/eks-capi`,
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
          },
          name: 'Clusters:AddAws:EksCapi',
        }),
        custom: Route.register({
          url: `${pluginRoutePrefix}/clusters/aws/add/custom`,
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
          },
          name: 'Clusters:AddAws:StandardCluster',
        }),
      },
      azure: {
        custom: Route.register({
          url: `${pluginRoutePrefix}/clusters/azure/add/custom`,
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
          },
          name: 'Clusters:AddAzure:Custom',
        }),
        'one-click': Route.register({
          url: `${pluginRoutePrefix}/clusters/azure/add/one-click`,
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
          },
          name: 'Clusters:AddAzure:OneClick',
        }),
      },
      bareOs: {
        virtual: {
          'one-click': Route.register({
            url: `${pluginRoutePrefix}/clusters/bareos/add/virtual/one-click`,
            defaultParams: {
              plugin: AppPlugins.Infrastructure,
            },
            name: 'Clusters:AddBareOs:Virtual:OneClick',
          }),
          'single-master': Route.register({
            url: `${pluginRoutePrefix}/clusters/bareos/add/virtual/single-master`,
            defaultParams: {
              plugin: AppPlugins.Infrastructure,
            },
            name: 'Clusters:AddBareOs:Virtual:SingleMaster',
          }),
          'multi-master': Route.register({
            url: `${pluginRoutePrefix}/clusters/bareos/add/virtual/multi-master`,
            defaultParams: {
              plugin: AppPlugins.Infrastructure,
            },
            name: 'Clusters:AddBareOs:Virtual:MultiMaster',
          }),
        },
        physical: {
          'one-click': Route.register({
            url: `${pluginRoutePrefix}/clusters/bareos/add/physical/one-click`,
            defaultParams: {
              plugin: AppPlugins.Infrastructure,
            },
            name: 'Clusters:AddBareOs:Physical:OneClick',
          }),
          'single-master': Route.register({
            url: `${pluginRoutePrefix}/clusters/bareos/add/physical/single-master`,
            defaultParams: {
              plugin: AppPlugins.Infrastructure,
            },
            name: 'Clusters:AddBareOs:Physical:SingleMaster',
          }),
          'multi-master': Route.register({
            url: `${pluginRoutePrefix}/clusters/bareos/add/physical/multi-master`,
            defaultParams: {
              plugin: AppPlugins.Infrastructure,
            },
            name: 'Clusters:AddBareOs:Physical:MultiMaster',
          }),
        },
      },
    },
    import: {
      root: Route.register({
        url: `${pluginRoutePrefix}/clusters/import`,
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'Clusters:Import',
      }),
      eks: Route.register({
        url: `${pluginRoutePrefix}/clusters/import/eks`,
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'Clusters:Import:EKS',
      }),
      aks: Route.register({
        url: `${pluginRoutePrefix}/clusters/import/aks`,
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'Clusters:Import:AKS',
      }),
      gke: Route.register({
        url: `${pluginRoutePrefix}/clusters/import/gke`,
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'Clusters:Import:GKE',
      }),
    },
    imported: {
      list: Route.register({
        url: `${pluginRoutePrefix}/clusters/imported`,
        id: 'importedClustersList',
        breadcrumbs: new Map([
          ['clusters', null],
          ['imported', 'infrastructureImportedClustersList'],
        ]),
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'Clusters:Imported:List',
      }),
      details: Route.register({
        url: `${pluginRoutePrefix}/clusters/imported/:id/:tab`,
        id: 'importedClusterDetails',
        breadcrumbs: new Map([
          ['clusters', null],
          ['imported', 'importedClustersList'],
          [':id', 'importedClusterDetails'],
        ]),
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
          tab: 'node-groups',
        },
        name: 'Clusters:Imported:ClusterDetails',
      }),
      edit: Route.register<{ id: string; tab?: IEditImportedClusterPageTabs }>({
        id: 'editImportedCluster',
        url: `${pluginRoutePrefix}/clusters/imported/edit/:id/:tab`,
        breadcrumbs: new Map([
          ['clusters', null],
          ['imported', 'importedClustersList'],
          [':id', 'editImportedCluster'],
        ]),
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
          tab: 'general',
        },
        name: 'Clusters:Imported:EditCluster',
      }),
    },
    managed: {
      list: Route.register({
        url: `${pluginRoutePrefix}/clusters/managed`,
        id: 'managedClustersList',
        breadcrumbs: new Map([
          ['clusters', null],
          ['managed', 'managedClustersList'],
        ]),
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'Clusters:Managed',
      }),
      qbert: {
        scaleMasters: Route.register({
          url: `${pluginRoutePrefix}/clusters/managed/qbert/:id/scale-masters`,
          breadcrumbs: new Map([
            ['clusters', null],
            ['managed', 'managedClustersList'],
            // ['scale-masters', 'legacyClustersList'],
          ]),
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
          },
          name: 'Clusters:Legacy:ScaleMasters',
        }),
        scaleWorkers: Route.register({
          url: `${pluginRoutePrefix}/clusters/managed/qbert/:id/scale-workers`,
          breadcrumbs: new Map([
            ['clusters', null],
            ['managed', 'managedClustersList'],
            // ['scale-workers', 'legacyClustersList'],
          ]),
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
          },
          name: 'Clusters:Legacy:ScaleWorkers',
        }),
        edit: Route.register<{ id: string; tab?: IClusterEditPageTabs }>({
          url: `${pluginRoutePrefix}/clusters/managed/qbert/edit/:id/:tab`,
          id: 'legacyClusterEdit',
          breadcrumbs: new Map([
            ['clusters', null],
            ['managed', 'managedClustersList'],
            [':id', 'legacyClusterEdit'],
          ]),
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
            tab: 'general',
          },
          name: 'Clusters:Legacy:Edit',
        }),
        detail: Route.register({
          url: `${pluginRoutePrefix}/clusters/managed/qbert/:id/:tab`,
          id: 'legacyClusterDetails',
          breadcrumbs: new Map([
            ['clusters', null],
            ['managed', 'managedClustersList'],
            [':id', 'legacyClusterDetails'],
          ]),
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
            tab: 'nodes',
          },
          name: 'Clusters:Legacy:Details',
        }),
        upgrade: Route.register({
          url: `${pluginRoutePrefix}/clusters/managed/qbert/:id/upgrade`,
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
          },
          name: 'Clusters:Legacy:Upgrade',
        }),
      },
      capi: {
        upgrade: Route.register({
          url: `${pluginRoutePrefix}/clusters/managed/capi/:id/upgrade`,
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
          },
          id: 'capiClusterUpgrade',
          breadcrumbs: new Map([
            ['clusters', null],
            ['managed', 'managedClustersList'],
            [':id', 'capiClusterDetails'],
            ['Upgrade', 'capiClusterUpgrade'],
          ]),
          name: 'Clusters:CAPI:Upgrade',
        }),
        details: Route.register({
          url: `${pluginRoutePrefix}/clusters/managed/capi/:id/:tab`,
          id: 'capiClusterDetails',
          breadcrumbs: new Map([
            ['clusters', null],
            ['managed', 'managedClustersList'],
            [':id', 'capiClusterDetails'],
          ]),
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
            tab: CapiDetailsPageTabs.Overview,
          },
          name: 'Clusters:CAPI:Details',
        }),
        upgradeProgress: Route.register({
          url: `${pluginRoutePrefix}/clusters/managed/capi/:id/:tab/upgrade-progress/:jobId`,
          id: 'capiClusterUpgradeProgress',
          breadcrumbs: new Map([
            ['clusters', null],
            ['managed', 'managedClustersList'],
            [':id', 'capiClusterDetails'],
          ]),
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
            tab: CapiDetailsPageTabs.Overview,
          },
          name: 'Clusters:CAPI:Details:UpgradeProgress',
        }),
        nodeGroups: Route.register({
          url: `${pluginRoutePrefix}/clusters/managed/capi/:id/:tab/:name`,
          id: 'capiClusterNodeGroupDetails',
          breadcrumbs: new Map([
            ['clusters', null],
            ['managed', 'managedClustersList'],
            [':id', 'capiClusterDetails'],
            [':name', 'capiClusterNodeGroupDetails'],
          ]),
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
            tab: CapiDetailsPageTabs.NodeGroups,
          },
          name: 'Clusters:CAPI:Details:NodeGroups',
        }),
        addNodeGroups: Route.register({
          url: `${pluginRoutePrefix}/clusters/managed/capi/:id/:tab/add`,
          defaultParams: {
            plugin: AppPlugins.Infrastructure,
            tab: CapiDetailsPageTabs.NodeGroups,
          },
          breadcrumbs: new Map([
            ['clusters', null],
            ['managed', 'managedClustersList'],
            [':id', 'capiClusterDetails'],
            [':name', 'capiClusterNodeGroupDetails'],
          ]),
          name: 'Clusters:CAPI:Details:AddNodeGroups',
        }),
      },
    },
  },
  nodes: {
    list: Route.register({
      url: `${pluginRoutePrefix}/nodes`,
      id: 'nodesList',
      breadcrumbs: new Map([['nodes', 'nodesList']]),
      name: 'Nodes:List',
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
    }),
    detail: Route.register({
      url: `${pluginRoutePrefix}/nodes/:id`,
      id: 'nodeDetails',
      breadcrumbs: new Map([
        ['nodes', 'nodesList'],
        [':id', 'nodeDetails'],
      ]),
      name: 'Nodes:Details',
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/nodes/add/:tab`,
      breadcrumbs: new Map([
        ['nodes', 'nodesList'],
        ['add', 'nodeDetails'],
      ]),
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
        tab: 'ova',
      },
      name: 'Nodes:Add',
    }),
    addAirgapped: Route.register({
      url: `${pluginRoutePrefix}/nodes/add-airgapped/:tab`,
      breadcrumbs: new Map([
        ['nodes', 'nodesList'],
        ['add', 'nodeDetails'],
      ]),
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
        tab: 'airCtl',
      },
      name: 'Nodes:Add',
    }),
  },
  eksNodes: {
    list: Route.register({
      url: `${pluginRoutePrefix}/eks-nodes`,
      id: 'eksNodesList',
      breadcrumbs: new Map([['eks-nodes', 'eksNodesList']]),
      name: 'EksNodes:List',
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
    }),
    detail: Route.register({
      url: `${pluginRoutePrefix}/eks-nodes/:id`,
      id: 'eksNodeDetails',
      breadcrumbs: new Map([
        ['eks-nodes', 'eksNodesList'],
        [':id', 'eksNodeDetails'],
      ]),
      name: 'EksNodes:Details',
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
    }),
  },
  cloudProviders: {
    aws: {
      list: Route.register({
        url: `${pluginRoutePrefix}/cloud-providers/aws`,
        id: 'awsCloudProvidersList',
        breadcrumbs: new Map([
          ['cloud-providers', null],
          ['AWS', 'awsCloudProvidersList'],
        ]),
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'CloudProviders:AWS:List',
      }),
      edit: Route.register({
        url: `${pluginRoutePrefix}/cloud-providers/aws/edit/:id`,
        breadcrumbs: new Map([
          ['cloud-providers', 'awsCloudProvidersList'],
          ['AWS', 'awsCloudProvidersList'],
          ['edit', 'awsCloudProvidersList'],
        ]),
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'CloudProviders:AWS:Edit',
      }),
    },
    azure: {
      list: Route.register({
        url: `${pluginRoutePrefix}/cloud-providers/azure`,
        id: 'azureCloudProvidersList',
        breadcrumbs: new Map([
          ['cloud-providers', null],
          ['azure', 'azureCloudProvidersList'],
        ]),
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'CloudProviders:Azure:List',
      }),
      edit: Route.register({
        url: `${pluginRoutePrefix}/cloud-providers/azure/edit/:id`,
        breadcrumbs: new Map([
          ['cloud-providers', 'azureCloudProvidersList'],
          ['azure', 'azureCloudProvidersList'],
          ['edit', 'azureCloudProvidersList'],
        ]),
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'CloudProviders:Azure:Edit',
      }),
    },
    google: {
      list: Route.register({
        url: `${pluginRoutePrefix}/cloud-providers/google`,
        id: 'googleCloudProvidersList',
        breadcrumbs: new Map([
          ['cloud-providers', null],
          ['google', 'googleCloudProvidersList'],
        ]),
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'CloudProviders:Google:List',
      }),
      edit: Route.register({
        url: `${pluginRoutePrefix}/cloud-providers/google/edit/:id`,
        breadcrumbs: new Map([
          ['cloud-providers', 'googleCloudProvidersList'],
          ['google', 'googleCloudProvidersList'],
          ['edit', 'googleCloudProvidersList'],
        ]),
        defaultParams: {
          plugin: AppPlugins.Infrastructure,
        },
        name: 'CloudProviders:Google:Edit',
      }),
    },
    add: Route.register({
      url: `${pluginRoutePrefix}/cloud-providers/add`,
      breadcrumbs: new Map([
        ['cloud-providers', 'awsCloudProvidersList'],
        ['add', 'awsCloudProvidersList'],
      ]),
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
      name: 'CloudProviders:Add',
    }),
  },
  rbacProfiles: {
    list: Route.register({
      url: `${pluginRoutePrefix}/rbac-profiles/profiles`,
      id: 'rbacProfilesList',
      breadcrumbs: new Map([
        ['rbac-profiles', null],
        ['profiles', 'rbacProfilesList'],
      ]),
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
      name: 'RbacProfiles:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/rbac-profiles/profiles/add`,
      breadcrumbs: new Map([
        ['rbac', null],
        ['profiles', 'rbacProfilesList'],
        ['Add', 'rbacProfilesList'],
      ]),
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
      name: 'RbacProfiles:Add',
    }),
    deploy: Route.register({
      url: `${pluginRoutePrefix}/rbac-profiles/profiles/deploy/:id`,
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
      name: 'RbacProfiles:Deploy',
    }),
    view: Route.register({
      url: `${pluginRoutePrefix}/rbac-profiles/profiles/view/:id/:tab`,
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
        tab: 'roles',
      },
      name: 'RbacProfiles:View',
    }),
    edit: Route.register({
      url: `${pluginRoutePrefix}/rbac-profiles/profiles/edit/:id/:tab`,
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
        tab: 'roles',
      },
      name: 'RbacProfiles:Edit',
    }),
    deleteBindings: Route.register({
      url: `${pluginRoutePrefix}/rbac-profiles/profiles/delete-bindings/:id`,
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
      name: 'RbacProfiles:DeleteBindings',
    }),
  },
  drift: {
    list: Route.register({
      url: `${pluginRoutePrefix}/rbac-profiles/drift`,
      id: 'rbacProfilesDriftList',
      breadcrumbs: new Map([
        ['rbac', null],
        ['profiles', 'rbacProfilesList'],
        ['drift', 'rbacProfilesDriftList'],
      ]),
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
      name: 'DriftAnalytics:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/rbac-profiles/drift/new`,
      breadcrumbs: new Map([
        ['rbac', null],
        ['profiles', 'rbacProfilesList'],
        ['drift', 'rbacProfilesDriftList'],
        ['add', 'rbacProfilesDriftList'],
      ]),
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
      name: 'DriftAnalytics:New',
    }),
    view: Route.register({
      url: `${pluginRoutePrefix}/rbac-profiles/drift/view/:id/:tab`,
      id: 'rbacProfilesDriftDetails',
      breadcrumbs: new Map([
        ['rbac', null],
        ['profiles', 'rbacProfilesList'],
        ['drift', 'rbacProfilesDriftList'],
        [':id', 'rbacProfilesDriftDetails'],
      ]),
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
        tab: 'roles',
      },
      name: 'DriftAnalytics:View',
    }),
    import: Route.register({
      url: `${pluginRoutePrefix}/rbac-profiles/drift/import`,
      defaultParams: {
        plugin: AppPlugins.Infrastructure,
      },
      name: 'DriftAnalytics:Import',
    }),
  },
}
