import { useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { useSelector } from 'react-redux'
import { RootState } from 'app/store'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { getSections } from 'core/plugins/helpers'
import { prop } from 'ramda'
import pluginManager from 'core/plugins/pluginManager'
import { matchLinkToPath } from 'core/plugins/route-helpers'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { determineCurrentStack } from 'core/containers/AuthController'
import { PluginOptions } from 'core/plugins/model'

const usePluginRouter = () => {
  const { prefs, updatePrefs } = useScopedPreferences()
  const { lastStack } = prefs
  const plugins = pluginManager.getPlugins()
  const { location } = useReactRouter()
  const { pathname, hash } = location
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const {
    userDetails: { role },
    features,
  } = session
  const pluginId = determineCurrentStack(location, lastStack)

  const currentPath = `${pathname}${hash}`
  const sections = getSections(plugins, role, features)

  const currentSection = useMemo(() => sections.find((section) => pluginId === section.id), [
    pluginId,
    sections,
  ])
  const currentLink = useMemo(() => currentSection.links.find(matchLinkToPath(currentPath)), [
    currentSection,
    currentPath,
  ])

  const currentOptions: PluginOptions = useMemo(() => {
    return plugins?.[pluginId]?.data?.options
  }, [pluginId, plugins])

  return {
    plugins,
    currentPluginId: pluginId,
    sections,
    currentSection,
    currentLink,
    currentPath,
    location,
    currentOptions,
  }
}

export default usePluginRouter
