import React, { useState, useCallback, useMemo } from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { prop, mergeLeft } from 'ramda'
import useReactRouter from 'use-react-router'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import pluginManager from 'core/plugins/pluginManager'
import { getSections, renderMainContent } from 'core/plugins/helpers'
import Header from 'core/elements/header/Header'
import Sidebar from 'core/elements/sidebar'
import FrameContext, { IFullFrameContext, IFrameContextRefs } from 'core/providers/frame-provider'
import { clientStoreKey, ClientState } from 'core/client/clientReducers'

interface Props {
  role: string
  features: any
  currentPluginId: string
  setPluginId: (id: string) => void
}

function DefaultFrame({ currentPluginId, features, role }: Props) {
  const { history, location } = useReactRouter()
  const { frame: { sidebarPane = 'default' } = {} } = useSelector(
    prop<string, ClientState>(clientStoreKey),
  )
  const [frameRefs, setFrameRefs] = useState<IFullFrameContext>({} as any)
  const setFrameContainerRef = useCallback(
    (payload: Partial<IFrameContextRefs>) => setFrameRefs((frames) => mergeLeft(frames, payload)),
    [frameRefs],
  )
  const plugins = pluginManager.getPlugins()
  const classes = useStyles({ sidebarPane })

  const sections = getSections(plugins, role, features)

  const handlePluginChange = (newPluginId) => {
    const pluginToShow = plugins[newPluginId]
    const defaultRoute = pluginToShow.getDefaultRoute()
    history.push(defaultRoute)
  }
  const frameValue = useMemo(
    () => ({
      ...frameRefs,
      setFrameContainerRef,
    }),
    [frameRefs, setFrameContainerRef],
  )

  return (
    <FrameContext.Provider value={frameValue}>
      <main className={classes.appFrame}>
        <Header />
        <Sidebar setPluginId={handlePluginChange} />
        <section className={clsx('content-main', classes.contentMain)}>
          {renderMainContent(plugins, role)}
        </section>
      </main>
    </FrameContext.Provider>
  )
}

const useStyles = makeStyles<Theme, { sidebarPane: string }>((theme: Theme) => ({
  appFrame: {
    position: 'relative',
    display: 'grid',
    backgroundColor: theme.components.frame.background,
    width: '100vw',
    height: '100vh',
    maxWidth: '100vw',
    maxHeight: '100vh',
    gridTemplateRows: 'minmax(65px, max-content) 1fr',
    gridTemplateColumns: 'max-content 1fr',
    gridTemplateAreas: ({ sidebarPane }) =>
      sidebarPane === 'custom'
        ? '"frame-nav frame-content"'
        : '"frame-nav frame-header" "frame-nav frame-content"',

    '& > header': {
      gridArea: 'frame-header',
      visibility: ({ sidebarPane }) => (sidebarPane === 'custom' ? 'hidden' : 'visible'),
      position: ({ sidebarPane }) => (sidebarPane === 'custom' ? 'absolute' : 'inherit'),
    },
    '& > aside': {
      gridArea: 'frame-nav',
    },
    '& > section': {
      gridArea: 'frame-content',
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '100%',
      height: 1,
      backgroundColor: theme.components.sidebar.border,
    },
  },
  contentMain: {
    display: 'grid',
    padding: '16px 32px',
    overflow: 'auto',
    gridAutoRows: 'max-content',

    // FF supports these fields but not webkit
    scrollbarColor: `${theme?.components?.scrollbar?.thumb} ${theme?.components?.frame?.background}`,
    scrollbarWidth: 'thin',

    '@media screen and (max-width: 768px)': {
      // iOS fix for momentum scrolling
      '-webkit-overflow-scrolling': 'touch',
    },
  },
  secondaryHeader: {
    zIndex: 1100,
  },
}))

export default DefaultFrame
