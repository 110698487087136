import serenityDefault from 'core/themes/serenity/serenityDefault'
import serenityLight from 'core/themes/serenity/serenityLight'
import serenityDark from 'core/themes/serenity/serenityDark'
import serenityUltraDark from 'core/themes/serenity/serenityUltraDark'
import serenityCustom from 'core/themes/serenity/serenityCustom'

export { default } from './serenityDefault'

export enum ThemeLabels {
  default = 'Default',
  light = 'Light',
  dark = 'Dark',
  'ultra-dark' = 'Ultra Dark',
  custom = 'Custom',
}

export const themesByKey: Record<keyof typeof ThemeLabels, typeof serenityDefault> = {
  light: serenityLight,
  default: serenityDefault,
  custom: serenityCustom,
  dark: serenityDark,
  'ultra-dark': serenityUltraDark,
}
