import { allKey } from 'app/constants'
import { cacheStoreKey, loadingStoreKey, paramsStoreKey } from 'core/caching/cacheReducers'

import useScopedPreferences from 'core/session/useScopedPreferences'
import { either, equals, find, isNil, path, pickAll, pipe, reject, pick } from 'ramda'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { arrayIfNil, emptyObj, emptyArr } from 'utils/fp'
import { memoizedObj } from 'utils/misc'

/**
 * @deprecated Use useListAction instead
 * Hook to load data using the specified loader function
 * @param {contextLoaderFn} loaderFn
 * @param {object} [params] Any set of params passed to the loader function
 * @returns {[array, boolean, function]} Returns an array with the loaded data, a loading boolean and a function to reload the data
 */
const useDataLoader = (loaderFn, params = emptyObj, options = emptyObj as any) => {
  // @ts-ignore
  const {
    loadOnDemand = false,
    defaultParams = emptyObj,
    loadingFeedback = true,
    requiredParams = undefined,
  } = options
  const { cacheKey, selectorCreator, indexBy, cache } = loaderFn
  const { prefs } = useScopedPreferences()
  const { currentTenant, currentRegion } = prefs

  // Memoize the params dependency as we want to make sure
  // it really changed and not just got a new reference
  const memoizedParams = memoizedObj(params)
  const memoizedIndexedParams = cache
    ? memoizedObj(pipe(pickAll(indexBy), reject(either(isNil, equals(allKey))))(memoizedParams))
    : memoizedParams

  const requiredParamsProvided = useMemo(
    () =>
      requiredParams &&
      Object.keys(pick(requiredParams, memoizedParams)).length === requiredParams.length,
    [requiredParams, memoizedParams],
  )

  const defaultSelector = useMemo(() => selectorCreator(defaultParams), [defaultParams])
  const selector = useCallback((state) => defaultSelector(state, memoizedParams), [
    defaultSelector,
    memoizedParams,
  ])

  // Try to retrieve the data from the store with the provided parameters
  const data = useSelector(selector) || (emptyArr as any)

  const paramsSelector = useMemo(
    () =>
      pipe(
        path([cacheStoreKey, paramsStoreKey, cacheKey]),
        arrayIfNil,
        find(equals(memoizedIndexedParams)),
      ),
    [cacheKey, memoizedIndexedParams],
  )
  const cachedParams = useSelector(paramsSelector)

  const loadingSelector = useMemo(() => path([cacheStoreKey, loadingStoreKey, cacheKey]), [
    cacheKey,
  ])
  const loading = useSelector(loadingSelector)

  // We use this ref to flag when the component has been
  // unmounted so we prevent further state updates
  const unmounted = useRef(false)

  // The following function will handle the calls to the data loading and
  // set the loading state variable to true in the meantime, while also taking care
  // of the sequantialization of multiple concurrent calls
  // It will set the result of the last data loading call to the "data" state variable
  const loadData = useCallback(
    async (refetch = false, updateLoadingState = loadingFeedback) => {
      if (refetch || isNil(data) || isNil(cachedParams)) {
        loaderFn(memoizedParams, refetch, updateLoadingState)
      }
    },
    [loaderFn, memoizedIndexedParams, cachedParams, loadingFeedback, data, memoizedParams],
  )

  // Load the data on component mount and every time the params change
  useEffect(() => {
    if ((!loadOnDemand && !requiredParams) || requiredParamsProvided) {
      loadData()
    }
  }, [memoizedIndexedParams, currentTenant, currentRegion, loadOnDemand, requiredParamsProvided])

  // When unmounted, set the unmounted ref to true to prevent further state updates
  useEffect(() => {
    return () => {
      unmounted.current = true
    }
  }, [])

  return [data, loading, loadData]
}

export default useDataLoader
