import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import useReactRouter from 'use-react-router'
import ApiClient from 'api-client/ApiClient'
import { makeStyles } from '@material-ui/styles'
import Button from 'core/elements/button'
import Text from 'core/elements/Text'
import Progress from 'core/components/progress/Progress'
import FormPageContainer from 'core/containers/FormPageContainer'
import { loginUrl, forgotPasswordUrl } from 'app/constants'
import { routes } from 'core/utils/routes'
import { Link } from 'react-router-dom'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(8),
    overflow: 'auto',
  },
  fields: {
    maxWidth: 400,
    gap: 16,
  },
  paper: {
    padding: theme.spacing(4),
  },
  img: {
    maxHeight: '70%',
    maxWidth: '70%',
    display: 'block',
    margin: 'auto',
  },
  paragraph: {
    margin: theme.spacing(1, 0),
  },
  alignMiddle: {
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
  },
}))

export const ActivateUserPage = (props) => {
  const [loading, setLoading] = useState(true)
  const classes = useStyles({})
  const { history, location } = useReactRouter()

  const searchParams = new URLSearchParams(location.search)
  const username = searchParams.get('username')
  const otp = searchParams.get('otp')

  useEffect(() => {
    const { clemency } = ApiClient.getInstance()
    const validateSecret = async () => {
      try {
        const response = await clemency.verifyActivateLink(username, otp)
        history.push(
          routes.password.reset.path({
            token: response.token,
            username,
          }),
        )
      } catch (e) {
        setLoading(false)
      }
    }
    validateSecret()
  }, [])

  return (
    <FormPageContainer>
      {loading && <Progress loading={loading} overlay={false} message="Processing..." />}
      {!loading && (
        <div className={classes.fields}>
          <Text variant="h3" className={classes.alignMiddle}>
            Password Reset Failed
          </Text>
          <Text variant="body1" className={clsx(classes.paragraph, classes.alignMiddle)}>
            Password reset link has expired, or the code is invalid.{' '}
            <Link to={forgotPasswordUrl}>Click here</Link> to request a new password reset link.
          </Text>
          <div className={classes.alignMiddle}>
            <Link to={loginUrl}>
              <Button className={classes.button}>Return to Login Screen</Button>
            </Link>
          </div>
        </div>
      )}
    </FormPageContainer>
  )
}

export default ActivateUserPage
