import { useCallback, useEffect, useRef, useReducer, Reducer } from 'react'
import { emptyObj } from 'utils/fp'
import { isEmpty } from 'ramda'
import { IDataKeys } from 'k8s/datakeys.model'
import UpdateAction from 'core/actions/UpdateAction'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import { ArrayElement } from 'core/actions/Action'

import { generateApiErrorPayload } from 'api-client/helpers'
import { ApiResponseErrorModel } from 'api-client/model'

const initialState = {
  updating: false,
  success: false,
  error: null,
  result: null,
}

interface UpdateState<R> {
  updating: boolean
  success: boolean
  error: ReturnType<typeof generateApiErrorPayload>
  result: R | void
}

interface UpdateReducerAction<R> {
  type: 'startUpdate' | 'endUpdate' | 'reset'
  payload?: {
    result?: R | void
    error?: ApiResponseErrorModel
    success?: boolean
  }
}

type UpdateActionReducer<R> = Reducer<UpdateState<R>, UpdateReducerAction<R>>

function updateActionReducer<R>(state: UpdateState<R>, { type, payload }: UpdateReducerAction<R>) {
  switch (type) {
    case 'reset':
      return initialState
    case 'startUpdate':
      return {
        updating: true,
        success: null,
        error: null,
        result: null,
      }
    case 'endUpdate':
    default:
      return {
        updating: false,
        result: payload?.result || null,
        error: generateApiErrorPayload(payload?.error || null),
        success: !!payload?.success,
      }
  }
}

const useUpdateAction = <
  D extends keyof IDataKeys,
  P extends Record<string, unknown> = Record<string, unknown>,
  R = ArrayElement<IDataKeys[D]>
>(
  action:
    | UpdateAction<D, P, R>
    | CreateAction<D, P, R>
    | DeleteAction<D, P, R>
    | CustomAction<D, P, R>,
) => {
  // We use this ref to flag when the component has been unmounted
  // to prevent further state updates
  const unmounted = useRef(false)

  // FIFO buffer of sequenced data updating promises
  // The aim of this is to prevent issues in the case two or more subsequent data updating requests
  // are performed with different params, and the previous one didn't have time to finish
  const updaterPromisesBuffer = useRef([])
  const [{ updating, success, result, error }, localDispatch] = useReducer<UpdateActionReducer<R>>(
    updateActionReducer,
    initialState,
  )
  const reset = useCallback(() => localDispatch({ type: 'reset' }), [localDispatch])

  // The following function will handle the calls to the data updating and
  // set the loading state variable to true in the meantime, while also taking care
  // of the sequencing of multiple concurrent calls
  const update = useCallback(
    async (params = emptyObj) => {
      let response = null
      let success = true
      let error = null
      // No need to update loading state if a request is already in progress
      if (isEmpty(updaterPromisesBuffer.current)) {
        localDispatch({ type: 'startUpdate' })
      }
      try {
        // Create a new promise that will wait for the previous promises in the buffer
        // before running the new request
        const currentPromise = (async () => {
          await Promise.all(updaterPromisesBuffer.current) // Wait for previous promises to resolve
          const result = await action.call(params, { propagateError: true })
          updaterPromisesBuffer.current.shift() // Delete the oldest promise in the sequence (FIFO)
          return result
        })()
        updaterPromisesBuffer.current.push(currentPromise)
        response = await currentPromise
        // With this condition, we ensure that all promises except the last one will be ignored
        if (isEmpty(updaterPromisesBuffer.current) && !unmounted.current) {
          localDispatch({ type: 'endUpdate', payload: { result, success: true, error: null } })
        }
      } catch (err) {
        updaterPromisesBuffer.current.shift() // Delete the oldest promise in the sequence (FIFO)
        localDispatch({ type: 'endUpdate', payload: { success: false, error: err } })
        success = false
        error = err.err
      }
      return { success, response, error }
    },
    [action],
  )

  useEffect(() => {
    return () => {
      // Set the unmounted ref to true to prevent further state updates
      unmounted.current = true
    }
  }, [])

  return { update, updating, error, success, result, reset }
}

export default useUpdateAction
