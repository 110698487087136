import { AppPlugins, pluginRoutePrefix } from 'app/constants'
import { Route } from 'core/plugins/route'

export const argoRoutes = {
  comingSoonArgo: Route.register({
    url: `${pluginRoutePrefix}/overview`,
    defaultParams: {
      plugin: AppPlugins.ArgoCD,
    },
    name: 'ArgoCD:ComingSoon',
  }),
}
