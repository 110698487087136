import React from 'react'
import { Redirect, Route } from 'react-router'
import { dashboardUrl, helpUrlDefinition, logoutUrl } from 'app/constants'
import LogoutPage from 'core/public/LogoutPage'
import DeveloperToolsEmbed from 'developer/components/DeveloperToolsEmbed'

export const renderPublicPages = () => (
  <>
    <Route path={logoutUrl} component={LogoutPage} />
    <Redirect to={dashboardUrl} />
  </>
)

export const renderDevTools = () => <DeveloperToolsEmbed />
