// Libs
import React, { useEffect, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { min, prop, partition } from 'ramda'
import clsx from 'clsx'

// Styles
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

// Utils
import { routes } from 'core/utils/routes'
import { isDecco } from 'core/utils/helpers'
import { convertTaskStateToStatus } from 'app/plugins/account/components/system-status/helpers'

// Hooks
import useToggler from 'core/hooks/useToggler'

// Reducers
import { RootState } from 'app/store'
import {
  ErrorNotificationType,
  NotificationState,
  notificationStoreKey,
} from 'core/notifications/notificationReducers'
import { ClientState, clientStoreKey } from 'core/client/clientReducers'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'

// Elements
import Text from 'core/elements/Text'
import Menu from 'core/elements/menu/Menu'
import MenuItem from 'core/elements/menu/MenuItem'
import IconButton from 'core/elements/button/IconButton'
import SimpleLink from '../SimpleLink'
import Badge from 'core/elements/badge'
import { capitalizeString } from 'utils/misc'
import { hexToRgbaCss } from 'core/utils/colorHelpers'

interface Props {
  className?: string
  hideDropdown?: boolean
}

const errorTimeout = 7500
const menuOffset = {
  vertical: 8,
  horizontal: -38, // diameter is 36 with 1px boder on each side
}

export default function NotificationsMenu({ className = undefined, hideDropdown = false }: Props) {
  const classes = useStyles()
  const [isOpen, __, setIsOpen] = useToggler(false)
  const [isNotificationActive, _, setIsNotificationActive] = useToggler(false)

  const { notifications } = useSelector(prop<string, NotificationState>(notificationStoreKey))
  const unreadCount = useMemo(() => notifications.filter((n) => n.unread).length, [notifications])
  const { systemStatus } = useSelector<RootState, ClientState>(prop(clientStoreKey))
  const { features } = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const lastTimeoutRef = useRef<NodeJS.Timeout>(null)
  const [lastNotification] = notifications || []

  const [clusterErrors, pf9Events] = useMemo(
    () => partition((notification) => !!notification?.data?.isClusterError, notifications),
    [notifications],
  )
  const handleClose = () => {
    setIsOpen(false)
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  useEffect(() => {
    const shouldShowTs = new Date().valueOf()
    if (!!lastNotification?.ts && shouldShowTs - lastNotification.ts < errorTimeout) {
      setIsNotificationActive(true)
      clearTimeout(lastTimeoutRef.current)
      lastTimeoutRef.current = setTimeout(() => {
        setIsNotificationActive(false)
      }, errorTimeout)
    }
  }, [lastNotification])

  const taskStateStatus = convertTaskStateToStatus(systemStatus?.taskState)
  return (
    <Menu
      id="notification-menu"
      origin="top right"
      offset={menuOffset}
      className={classes.menu}
      anchor={
        <>
          <IconButton
            className={clsx({ [classes.notifying]: isNotificationActive })}
            icon="inbox"
            info="Notifications"
            onClick={hideDropdown ? null : handleOpen}
            aria-describedby="simple-dropdown"
          />
          {unreadCount ? <span className={classes.notifCount}>{min(unreadCount, 99)}</span> : null}
        </>
      }
      open={isOpen}
      onClose={handleClose}
    >
      <div className={classes.dropdownContainer}>
        {isDecco(features) && (
          <div className={classes.badgeContainer}>
            <SimpleLink
              src={routes.accountStatus.root.path()}
              textDecoration="none"
              onClick={handleClose}
            >
              <Badge
                ellipsisAt={40}
                text="Management Plane Health"
                variant={taskStateStatus}
                tooltipBody={`System Task State: ${capitalizeString(
                  systemStatus?.taskState || 'unknown',
                )}`}
              />
            </SimpleLink>
          </div>
        )}
        <div className={classes.dropdownLinks}>
          <SimpleLink
            src={routes.notifications.list.path({
              notificationType: ErrorNotificationType.ClusterError,
            })}
            textDecoration="none"
            onClick={handleClose}
          >
            <MenuItem icon="triangle-exclamation">
              <Text variant="body2">{clusterErrors?.length || 0} Cluster Errors</Text>
            </MenuItem>
          </SimpleLink>
          <SimpleLink
            src={routes.notifications.list.path({
              notificationType: ErrorNotificationType.Platform9Event,
            })}
            textDecoration="none"
            onClick={handleClose}
          >
            <MenuItem icon="hexagon-exclamation">
              <Text variant="body2">{pf9Events?.length || 0} Platform9 Events</Text>
            </MenuItem>
          </SimpleLink>
        </div>
      </div>
    </Menu>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  menu: {
    '& .menu-popover': {
      minWidth: 230,
    },
  },
  badgeContainer: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
  },
  notifCount: {
    position: 'absolute',
    fontWeight: 'bold',
    right: -9,
    top: -5,
    fontSize: 10,
    color: theme.palette.grey[200],
    backgroundColor: theme.components.iconButton.badgeColor,
    borderRadius: '50%',
    borderColor: theme.components.iconButton.badgeColor,
    borderWidth: 1,
    borderStyle: 'solid',
    width: 16,
    height: 16,
    lineHeight: '16px',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  notifying: {
    background: `
      linear-gradient(
        45deg, 
        ${theme.components.iconButton.background}, 
        ${theme.components.iconButton.background}, 
        ${hexToRgbaCss(theme.components.graph.error, 0.5)}, 
        ${theme.components.graph.error},
        ${theme.components.graph.error}, 
        ${hexToRgbaCss(theme.components.graph.error, 0.5)}, 
        ${theme.components.iconButton.background}, 
        ${theme.components.iconButton.background}
      )`,
    backgroundSize: `500% 500%`,
    animation: '$slide-background 2.5s linear infinite',
  },
  '@keyframes slide-background': {
    '0%': {
      backgroundPosition: '100% 0%',
    },

    '35%': {
      backgroundPosition: '100% 80%',
    },
    '50%': {
      backgroundPosition: '100% 100%',
    },
    '65%': {
      backgroundPosition: '80% 100%',
    },
    '100%': {
      backgroundPosition: '0% 100%',
    },
  },
  dropdownContainer: {
    gap: '16px',
    display: 'grid',
    padding: '16px',
    gridTemplateRows: 'max-content 1fr',
  },
  dropdownLinks: {
    display: 'grid',
    gridAutoRows: 48,
  },
}))
