import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { compose } from 'ramda'
import clsx from 'clsx'
import generateTestId from 'utils/test-helpers'
import Input from 'core/elements/input/Input'

class SearchBar extends PureComponent {
  handleSearch = (event) => {
    this.props.onSearchChange(event.target.value)
  }

  handleClear = () => {
    this.props.onSearchChange('')
  }

  render() {
    const { searchTerm, className } = this.props
    return (
      searchTerm !== undefined && (
        //TODO: Add two icons here after Input is updated
        <Input
          data-testid={generateTestId('search')}
          icon="search"
          placeholder="Search"
          value={searchTerm}
          onChange={this.handleSearch}
          className={clsx(className)}
          type="search"
        />

        // <TextField
        //   data-testid={generateTestId('search')}
        //   variant="outlined"
        //   placeholder="Search"
        //   className={clsx(classes.SearchBar, className)}
        //   onChange={this.handleSearch}
        //   value={searchTerm}
        //   type="search"
        //   InputProps={{
        //     classes: pick(['root', 'adornedStart', 'adornedEnd'], classes),
        //     startAdornment: (
        //       <InputAdornment position="start">
        //         <FontAwesomeIcon className={`${classes.searchIcon} searchIcon`}>
        //           search
        //         </FontAwesomeIcon>
        //       </InputAdornment>
        //     ),
        //     endAdornment: (
        //       <InputAdornment
        //         position="end"
        //         style={{ visibility: searchTerm.length > 0 ? '' : 'hidden' }}
        //       >
        //         <ClearIcon
        //           className={classes.clearIcon}
        //           color="action"
        //           onClick={this.handleClear}
        //         />
        //       </InputAdornment>
        //     ),
        //   }}
        // />
      )
    )
  }
}

SearchBar.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  searchTerm: PropTypes.string,
}

export default SearchBar
