import React, { FC, useEffect, useRef, useState } from 'react'
import { GridDialogProps } from 'core/elements/grid/helpers/getGridDialogButton'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import DialogDropdownButton from './DialogDropdownButton'

const useStyles = makeStyles<Theme, { isOpen: boolean }>((theme) => ({
  dropdownContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  dropdownLabel: {
    display: 'inline-grid',
    gridTemplateColumns: 'auto max-content',
    alignItems: 'center',
    gap: 8,
    padding: '8px 16px',
    background: theme.components.button.secondary.background,
    border: `1px solid ${theme.components.button.secondary.border}`,
    cursor: 'pointer',
    borderRadius: 4,
  },
  text: {
    color: theme.components.dropdown.color,
  },
  dropdownButtons: {
    opacity: ({ isOpen }) => (isOpen ? 1 : 0),
    maxHeight: ({ isOpen }) => (isOpen ? '20rem' : '1rem'),
    transition: 'opacity .2s ease, max-height .2s ease',
    position: 'absolute',
    top: 40,
    background: theme.components.button.secondary.background,
    border: `1px solid ${theme.components.button.secondary.border}`,
    borderRadius: 4,
    display: 'grid',
    width: '100%',
    zIndex: 100,
  },
  dropdownButton: {
    display: 'grid',
    gridTemplateColumns: '18px auto',
    padding: '8px 16px',
    alignItems: 'center',
    gap: 8,
    cursor: 'pointer',
    '&:hover': {
      background: theme.components.dropdown.selectedBackground,
    },
  },
}))

interface Action<T> {
  label: string
  icon: string
  externalLink?: string
  DialogComponent?: FC<GridDialogProps<T>>
  disabledFn?: (item: T) => boolean
  hideFn?: (item: T) => boolean
  tooltipFn?: (item: T, disabled?: boolean) => string | React.ReactNode
  refreshFn?: () => void
  customDialogProps?: any
  onClick?: () => void
}

// Detecting clicks outside the dropdown
const clickOutside = (ref, onClickOutside) => {
  useEffect(() => {
    const modalRef = document.getElementById('modal-portal-root')
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && !modalRef.contains(event.target)) {
        onClickOutside()
      }
    }
    // Bind
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // dispose
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOutside])
}

interface Props<T> {
  actions: Action<T>[]
  entity: T
}

export default function ActionsDropdown<T>({ actions = [], entity }: Props<T>) {
  const [isOpen, setOpen] = useState(false)
  const classes = useStyles({ isOpen })

  const wrapperRef = useRef(null)
  clickOutside(wrapperRef, () => {
    setOpen(false)
  })

  return (
    <div className={classes.dropdownContainer} ref={wrapperRef}>
      <div className={classes.dropdownLabel} onClick={() => setOpen(!isOpen)}>
        <Text variant="caption1" className={classes.text}>
          More Actions
        </Text>
        <FontAwesomeIcon className={classes.text} solid size="sm">
          {isOpen ? 'caret-up' : 'caret-down'}
        </FontAwesomeIcon>
      </div>
      {isOpen && (
        <div className={classes.dropdownButtons}>
          {actions.map((action) => {
            const disabled = action?.disabledFn ? action.disabledFn(entity) : false
            const hidden = action?.hideFn ? action.hideFn(entity) : false
            const tooltip = action?.tooltipFn ? action.tooltipFn(entity, disabled) : ''
            if (hidden) {
              return null
            }
            return (
              <DialogDropdownButton
                key={action.label}
                label={action.label}
                icon={action.icon}
                DialogComponent={action.DialogComponent}
                onDialogClose={() => setOpen(false)}
                entity={entity}
                refreshFn={action?.refreshFn}
                customDialogProps={action?.customDialogProps}
                disabled={disabled}
                tooltip={tooltip}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
