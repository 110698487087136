import React from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import Text from 'core/elements/Text'
import { ArrayElement } from 'core/actions/Action'
import Badge, { BadgeVariant } from 'core/elements/badge/Badge'
import { string } from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import TooltipListBody from 'core/elements/tooltip/TooltipListBody'

interface BaseGridArrayCellProps<K extends unknown[]> {
  badgeVariant?: BadgeVariant
  maxItems?: number
  ellipsisAt?: number
}
interface GridArrayCellProps<T, K extends unknown[] = []>
  extends GridCellProps<T, K>,
    BaseGridArrayCellProps<K> {}

export default function GridBadgesArrayCell<T, K extends unknown[]>({
  value: items = [] as K,
  badgeVariant = 'default',
  maxItems = 3,
  ellipsisAt = 15,
}: GridArrayCellProps<T, K>) {
  const classes = useStyles()
  const itemsToDisplay = items.slice(0, maxItems)

  return (
    <div className={classes.cell}>
      <div className={classes.badges}>
        {itemsToDisplay.map((item: ArrayElement<K>, idx: number) => {
          const name = String(item)
          return (
            <Badge
              key={`${name}-${idx}`}
              text={name}
              variant={badgeVariant}
              ellipsisAt={ellipsisAt}
              bold={false}
            />
          )
        })}
      </div>
      {items.length > maxItems && (
        <div className={classes.additionalItemsCount}>
          <Badge
            variant={badgeVariant}
            text={`+${items.length - maxItems}`}
            tooltipBody={<TooltipListBody items={items} showCopy={false} />}
          />
        </div>
      )}
    </div>
  )
}

export function createGridBadgesArrayCell<T, K extends unknown[]>({
  badgeVariant,
  maxItems,
  ellipsisAt,
}: BaseGridArrayCellProps<K>) {
  return (props: GridCellProps<T, K>) => {
    return (
      <GridBadgesArrayCell
        {...props}
        badgeVariant={badgeVariant}
        maxItems={maxItems}
        ellipsisAt={ellipsisAt}
      />
    )
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    display: 'flex',
    maxWidth: '480px',
    gap: theme.spacing(1),
  },
  badges: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  additionalItemsCount: {
    alignSelf: 'end',
  },
}))
