import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { identity } from 'ramda'
import { ensureFunction } from 'utils/fp'
import { withRouter } from 'react-router-dom'
import generateTestId from 'utils/test-helpers'
import Tooltip from 'core/elements/tooltip'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 34,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    cursor: 'pointer',
    position: 'relative',
    bottom: -2,
    ...theme.typography.caption3,
  },
  actionIcon: {
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& i': {
      fontSize: 22,
    },
  },
  actionLabel: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    wordWrap: 'break-word',
    marginTop: 2,
    color: theme?.components?.typography?.default,
  },
  disabledAction: {
    cursor: 'not-allowed',
    color: theme.palette.grey[300],
    '& i': {
      color: 'inherit',
    },
  },
  divider: {
    width: 1,
    height: 32,
    backgroundColor: theme.palette.grey[200],
    margin: theme.spacing(0, 2),
    border: 'none',
  },
}))

export const ToolbarActionIcon = ({ icon }) => {
  const classes = useStyles()
  return (
    <div className={classes.actionIcon}>
      <FontAwesomeIcon>{icon}</FontAwesomeIcon>
    </div>
  )
}

const ListTableAction = withRouter(
  ({
    cond,
    priorityConditions = [],
    action,
    label,
    disabledInfo,
    dialog,
    icon,
    params,
    selected,
    onRefresh,
    routeTo,
    history,
    listTableParams,
  }) => {
    const { root, actionLabel, disabledAction } = useStyles()
    const [dialogOpened, setDialogOpened] = useState(false)
    const store = useSelector(identity)
    const priorityAction =
      selected.length &&
      priorityConditions.find((action) => {
        return action.cond(selected, store)
      })
    const isActionEnabled = selected.length && (!cond || cond(selected, store))
    const info = isActionEnabled || !disabledInfo ? label : ensureFunction(disabledInfo)(selected)
    const DialogComponent = priorityAction?.type === 'dialog' ? priorityAction?.dialog : dialog
    return (
      <Fragment>
        {(dialog || priorityAction?.dialog) && dialogOpened ? (
          <DialogComponent
            rows={selected}
            open={dialogOpened}
            listTableParams={listTableParams}
            onClose={(success) => {
              if (success === true && onRefresh) {
                onRefresh()
              }
              setDialogOpened(false)
            }}
            params={params}
            dialogRoute={priorityAction?.dialogRoute}
          />
        ) : null}
        <Tooltip
          data-testid={generateTestId(label)}
          key={label}
          message={selected.length ? info : ''}
        >
          <div
            className={clsx(root, {
              [disabledAction]: !isActionEnabled && !priorityAction,
            })}
            onClick={
              priorityAction
                ? () => {
                    if (priorityAction.type === 'dialog') {
                      setDialogOpened(true)
                    }
                    if (priorityAction.type === 'route') {
                      history.push(priorityAction.routeTo(selected))
                    }
                  }
                : isActionEnabled
                ? () => {
                    if (dialog) {
                      setDialogOpened(true)
                    }
                    if (action) {
                      action(selected)
                    }
                    if (routeTo) {
                      history.push(routeTo(selected))
                    }
                  }
                : null
            }
          >
            {typeof icon === 'string' ? <ToolbarActionIcon icon={icon} /> : icon}
            <div className={actionLabel}>{label}</div>
          </div>
        </Tooltip>
      </Fragment>
    )
  },
)

export const ListTableBatchActions = ({
  batchActions = [],
  selected = [],
  onRefresh = undefined,
  listTableParams = undefined,
}) => {
  const classes = useStyles()
  return (
    <>
      {batchActions.map((action) => (
        <React.Fragment key={action.label}>
          <ListTableAction
            key={action.label}
            {...action}
            onRefresh={onRefresh}
            selected={selected}
            listTableParams={listTableParams}
          />
          {action.showDivider && <hr className={classes.divider} />}
        </React.Fragment>
      ))}
    </>
  )
}

const priorityConditionPropType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  dialog: PropTypes.func,
  dialogRoute: PropTypes.func,
  routeTo: PropTypes.func,
  cond: PropTypes.func.isRequired,
})

export const listTableActionPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cond: PropTypes.func,
  priorityConditions: PropTypes.arrayOf(priorityConditionPropType),
  disabledInfo: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  // When clicking one of the batch action icons you can specify the logic as a dialog, route, or arbitrary function.
  // Only one of these should be used at a time.
  dialog: PropTypes.func, // a React class or function
  routeTo: PropTypes.func,
  action: PropTypes.func,
})

ListTableBatchActions.propTypes = {
  // The selected rows
  selected: PropTypes.arrayOf(PropTypes.object),

  // The actions to perform on the selected rows
  batchActions: PropTypes.arrayOf(listTableActionPropType),

  onRefresh: PropTypes.func,
}

export default ListTableBatchActions
