import React, { useCallback } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Text from 'core/elements/Text'
import generateTestId from 'utils/test-helpers'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { memoize } from 'utils/misc'

function CircularStepBubble({ active, completed, icon, first }) {
  const classes = useStepIconStyles()
  return (
    <Text
      variant="subtitle2"
      component="figure"
      className={clsx(classes.stepBubble, {
        first,
        active,
        completed,
      })}
    >
      {completed ? (
        <FontAwesomeIcon size="md" className={classes.stepIcon}>
          check
        </FontAwesomeIcon>
      ) : (
        icon
      )}
    </Text>
  )
}

interface Props {
  activeStep: number
  vertical?: boolean
  steps: Array<{ stepId: string; label: string }>
  latestFinishedStep: number
  setActiveStep: (stepId: string, stepNum: number) => void
}

export default function WizardStepper({
  vertical = true,
  activeStep,
  steps,
  latestFinishedStep,
  setActiveStep,
}: Props) {
  const classes = useWizardStepperStyles({ vertical })
  const handleClick = useCallback(
    memoize((step, idx) => () => {
      const active = activeStep === idx
      const unlocked = idx === latestFinishedStep
      const completed = idx < latestFinishedStep
      if (active) return
      if (completed || unlocked) {
        setActiveStep(step.stepId, idx)
      }
    }),
    [setActiveStep, activeStep, latestFinishedStep],
  )
  return (
    <ul className={classes.stepperRoot}>
      {steps.map((step, idx) => {
        const { stepId, label } = step
        const active = activeStep === idx
        const completed = idx < latestFinishedStep
        const unlocked = idx === latestFinishedStep
        return (
          <li className={classes.stepItem} key={stepId} onClick={handleClick(step, idx)}>
            <Text
              variant="caption1"
              className={clsx(classes.stepLabel, {
                active,
                completed,
              })}
              data-testid={generateTestId(stepId)}
            >
              {label}
            </Text>
            <CircularStepBubble
              first={idx === 0}
              active={active || unlocked}
              completed={completed}
              icon={idx + 1}
            />
          </li>
        )
      })}
    </ul>
  )
}

const useStepIconStyles = makeStyles<Theme>((theme) => ({
  stepBubble: {
    position: 'relative',
    width: 32,
    height: 32,
    fontWeight: 400,
    borderRadius: 100,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.components.wizard.step.bubbleText,
    backgroundColor: theme.components.wizard.step.bubbleBackground,
    border: `1px solid ${theme.components.wizard.step.bubbleBorder}`,

    '&.active, &.completed, &.active i, &.completed i': {
      color: theme.components.wizard.step.bubbleActiveText,
    },
    '&.active, &.completed': {
      backgroundColor: theme.components.wizard.step.bubbleActiveBackground,
      borderColor: theme.components.wizard.step.bubbleActiveBackground,
      '&:after': {
        backgroundColor: theme.components.wizard.step.bubbleActiveBackground,
      },
    },
    '&:not(.first):after': {
      content: '""',
      backgroundColor: theme.components.wizard.step.bubbleBackground,
      position: 'absolute',
      bottom: '100%',
      left: '50%',
      transform: 'translate(-1px, 0px)',
      width: 2,
      height: 34,
      zIndex: -1,
    },
  },
  stepIcon: {
    color: theme.components.wizard.step.bubbleText,
    marginTop: 2,
    fontWeight: 500,
  },
}))

const useWizardStepperStyles = makeStyles<Theme>((theme) => ({
  stepperRoot: {
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    display: 'grid',
    justifyItems: 'end',
    gap: 16,
  },
  stepItem: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    alignItems: 'center',
    gap: 12,
    padding: 8,
    width: 'max-content',
    transition: 'background-color .2s ease',
    borderRadius: 4,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.components.wizard.step.bubbleBackground,
    },
  },
  stepLabel: {
    whiteSpace: 'nowrap',
    color: theme.components.wizard.step.bubbleLabel,

    '&.active': {
      color: theme.components.wizard.step.bubbleActiveLabel,
    },
  },
}))
