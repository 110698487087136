import React from 'react'
import { formatDate, durationBetweenDates } from 'utils/misc'
import { makeStyles } from '@material-ui/styles'
import Tooltip from 'core/elements/tooltip'
import Text from 'core/elements/Text'
import { topMiddle } from 'core/elements/menu/defaults'
import { TooltipProps } from 'core/elements/tooltip/Tooltip'

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

interface DateCellProps {
  value: string
  format?: string
  showToolTip?: boolean
  difference?: boolean
  formatOpts?: {
    labels?: string[]
    renameLabels?: Record<string, string>
    pluralize?: boolean
  }
}

const DateCell: React.FC<DateCellProps> = ({
  value,
  format,
  formatOpts = {},
  difference = false,
  showToolTip = false,
}) => {
  const content = (
    <Text variant="body2" component="span">
      {difference ? durationBetweenDates(formatOpts)(value) : formatDate(value, format)}
    </Text>
  )
  if (showToolTip) {
    return <Tooltip message={durationBetweenDates({})(value)}>{content}</Tooltip>
  }

  return content
}

export default DateCell

interface DateAndTimeProps {
  value: string
  dateFormat?: string
  timeFormat?: string
  showToolTip?: boolean
  tooltipProps?: TooltipProps
}

const defaultTooltipProps = {
  ...topMiddle,
}

export const DateAndTime: React.FC<DateAndTimeProps> = ({
  value,
  dateFormat = 'MM/DD/YYYY',
  timeFormat = 'hh:mm A z',
  showToolTip = true,
  tooltipProps = defaultTooltipProps,
}) => {
  const { column } = useStyles({})
  if (!value) {
    return (
      <Text variant="body2" component="span">
        N/A
      </Text>
    )
  }
  const content = (
    <div className={column}>
      <Text variant="body2" component="span">
        {formatDate(value, dateFormat)}
      </Text>
      <Text variant="body2" component="span">
        {formatDate(value, timeFormat)}
      </Text>
    </div>
  )
  if (showToolTip) {
    return (
      <Tooltip {...tooltipProps} message={durationBetweenDates({})(value)}>
        {content}
      </Tooltip>
    )
  }

  return content
}
