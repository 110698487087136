import React from 'react'
import useReactRouter from 'use-react-router'
import Button from 'core/elements/button'
import Text from 'core/elements/Text'
import useParams from 'core/hooks/useParams'
import ApiClient from 'api-client/ApiClient'
import { IApiData } from 'api-client/Clemency'

import {
  requiredValidator,
  passwordValidator,
  matchFieldValidator,
} from 'core/utils/fieldValidators'
import TextField from 'core/components/validatedForm/TextField'
import Alert from 'core/components/Alert'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { loginUrl } from 'app/constants'
import FormPageContainer from 'core/containers/FormPageContainer'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import PasswordValidationDisplay from 'core/components/PasswordValidationDisplay'
import { InputIconProps } from 'core/elements/input/Input'

const useStyles = makeStyles<Theme>((theme) => ({
  paragraph: {
    textAlign: 'center',
  },
  formTitle: {
    textAlign: 'center',
    marginBottom: 24,
  },
  fields: {
    flexGrow: 1,
    display: 'flex',
    marginTop: '10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    gap: 16,
  },
}))
interface IState {
  loading: boolean
  isError: boolean
  emailId: string
  newPassword: string
  confirmPassword: string
  isResetPasswordSuccessful: boolean
  errorMessage: string
  isNewPasswordMasked: boolean
  isConfirmPasswordMasked: boolean
}

type PasswordMasked = 'isConfirmPasswordMasked' | 'isNewPasswordMasked'
type ITogglePasswordMask = (key: PasswordMasked) => () => void

const initialState: IState = {
  loading: false,
  isError: false,
  emailId: '',
  newPassword: '',
  confirmPassword: '',
  isResetPasswordSuccessful: false,
  errorMessage: 'Reset password failed',
  isNewPasswordMasked: false,
  isConfirmPasswordMasked: false,
}
const passwordValidators = [requiredValidator, passwordValidator]
const confirmPasswordValidator = [
  passwordValidator,
  matchFieldValidator('newPassword').withMessage('Passwords do not match'),
]
const { clemency } = ApiClient.getInstance()

const ResetPasswordPage = () => {
  const classes = useStyles({})
  const { history, location } = useReactRouter()
  const searchParams = new URLSearchParams(location.search)
  const emailId = searchParams.get('username')
  const token = searchParams.get('token')

  const { params, updateParams, getParamsUpdater } = useParams({
    ...initialState,
    emailId,
  })
  const togglePasswordMask: ITogglePasswordMask = (key) => () =>
    updateParams({ [key]: !params[key] })

  const getIconProps = (key): InputIconProps => ({
    onClick: togglePasswordMask(key),
    placement: 'end',
  })

  const handleFormSubmit = async () => {
    if (params.isResetPasswordSuccessful) {
      history.push(loginUrl)
      return
    }

    updateParams({
      loading: true,
    })

    try {
      const options: IApiData = {
        token: token,
        username: params.emailId,
        password: params.confirmPassword,
      }
      const response = await clemency.resetPassword(options)

      if (!response.success) {
        throw new Error('Unable to reset password')
      }

      updateParams({ isResetPasswordSuccessful: true, loading: false })
    } catch (err) {
      updateParams({ isError: true, loading: false })
    }
  }

  return (
    <FormPageContainer>
      <ValidatedForm elevated={false} onSubmit={handleFormSubmit}>
        {({ values }) => (
          <>
            <Text variant="h3" className={classes.formTitle}>
              Reset Password
            </Text>
            <div className={classes.fields}>
              {!params.isResetPasswordSuccessful ? (
                <>
                  <TextField
                    disabled
                    id="email"
                    label="Email"
                    type="email"
                    placeholder="Email"
                    value={params.emailId}
                  />
                  <TextField
                    required
                    id="newPassword"
                    label="New Password"
                    type={!params.isNewPasswordMasked ? 'password' : 'text'}
                    icon={!params.isNewPasswordMasked ? 'eye' : 'eye-slash'}
                    iconProps={getIconProps('isNewPasswordMasked')}
                    validations={passwordValidators}
                    onChange={getParamsUpdater('newPassword')}
                  />
                  <TextField
                    required
                    id="confirmPassword"
                    label="Confirm Password"
                    type={!params.isConfirmPasswordMasked ? 'password' : 'text'}
                    icon={!params.isConfirmPasswordMasked ? 'eye' : 'eye-slash'}
                    iconProps={getIconProps('isConfirmPasswordMasked')}
                    validations={confirmPasswordValidator}
                    onChange={getParamsUpdater('confirmPassword')}
                  />
                  {!params.isError ? (
                    <PasswordValidationDisplay values={values} />
                  ) : (
                    <Alert variant="error" message={params.errorMessage} />
                  )}
                  <Button loading={params.loading} type="submit">
                    Reset my password
                  </Button>
                </>
              ) : (
                <>
                  <Text className={classes.paragraph} component="p">
                    Your password has been reset successfully.
                  </Text>
                  <Button type="submit">Return to login screen</Button>
                </>
              )}
            </div>
          </>
        )}
      </ValidatedForm>
    </FormPageContainer>
  )
}

export default ResetPasswordPage
