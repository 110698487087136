import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import ApiClient from 'api-client/ApiClient'
import { propEq, prop, pipe, head, find, isEmpty } from 'ramda'
import { listUserTenants } from 'account/components/userManagement/tenants/new-actions'
import { useDispatch, useSelector } from 'react-redux'
import { cacheActions } from 'core/caching/cacheReducers'
import { sessionActions, sessionStoreKey, SessionState } from 'core/session/sessionReducers'
import useScopedPreferences from 'core/session/useScopedPreferences'
import ListMenu from 'core/elements/menu/ListMenu'
import MenuItem from 'core/elements/menu/MenuItem'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { userTenantsSelector } from 'account/components/userManagement/tenants/selectors'
import useListAction from 'core/hooks/useListAction'

const menuOffset = {
  vertical: -48, // height of anchor <MenuItem> is 48
  horizontal: 24,
}
const iconProps = {
  solid: true,
}

interface UserTenants {
  id: string
  name: string
  domain_id: string
  description: string
  enabled: boolean
  parent_id: string
  is_domain: boolean
  tags: any[]
  links: Links
}

interface Links {
  self: string
}

const TenantMenu = (props) => {
  const { keystone } = ApiClient.getInstance()
  const [tenantSearch, setTenantSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const { prefs, updatePrefs } = useScopedPreferences()
  const { currentTenant, currentRegion } = prefs
  const [selectedTenantName, setSelectedTenantName] = useState()

  const { loading: loadingTenants, reload: reloadTenants } = useListAction(listUserTenants)
  const tenants = useSelector(userTenantsSelector)

  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { isSsoToken } = session
  const dispatch = useDispatch()

  const [menuOpen, setMenuOpen] = useState(false)
  const timeoutRef = useRef(null)

  const curTenantName = useMemo(() => {
    if (selectedTenantName) {
      return selectedTenantName
    }
    if (currentTenant) {
      // The || is just in case the currentTenant in prefs no longer exists
      return (
        pipe<any, any, any>(find(propEq('id', currentTenant)), prop('name'))(tenants) ||
        head(tenants)?.name
      )
    }
    // match setupSession from AppContainer, set to service tenant if it exists
    const tenant = tenants.find(propEq('name', currentTenant || 'service')) || head(tenants)
    return tenant?.name
  }, [tenants, currentTenant, selectedTenantName])

  useEffect(() => {
    // Reload tenants when changing the current region
    if (isEmpty(tenants) && loadingTenants === undefined) {
      reloadTenants(true)
    }
  }, [currentRegion, tenants])

  useEffect(() => {
    if (curTenantName && tenants.length && !currentTenant) {
      // Set currentTenant in prefs on first-ever load
      const tenant = tenants.find((t) => curTenantName === t.name)
      updatePrefs({ currentTenant: tenant.id })
    }
  }, [curTenantName, tenants])

  const updateCurrentTenant = async (tenantName) => {
    setLoading(true)

    const tenant = tenants.find(propEq('name', tenantName))
    if (!tenant) {
      return
    }

    const { user, role, scopedToken } = await keystone.changeProjectScopeWithToken(
      tenant.id,
      isSsoToken,
    )

    setSelectedTenantName(tenantName)

    dispatch(cacheActions.clearCache())
    dispatch(
      sessionActions.updateSession({
        userDetails: { ...user, role },
        scopedToken,
      }),
    )

    setLoading(false)
  }

  const handleTenantSelect = useCallback(
    async (currentTenant) => {
      await updateCurrentTenant(currentTenant.name)
      updatePrefs({ currentTenant: currentTenant.id })
    },
    [tenants],
  )

  const tenantNames = useMemo(() => {
    const isUserTenant = (x) => x.description !== 'Heat stack user project'
    return (tenants || []).filter(isUserTenant).map((x) => ({ name: x.name, id: x.id }))
  }, [tenants])
  const handleMenuClose = useCallback(() => {
    // Small timeout to give the mouse time to reach the sub menu before we hide the menu
    timeoutRef.current = setTimeout(() => setMenuOpen(false), 250)
  }, [])
  const handleMenuOpen = useCallback(() => {
    clearTimeout(timeoutRef.current)
    setMenuOpen(true)
  }, [])

  return (
    <ListMenu
      list={tenantNames}
      onClick={handleTenantSelect}
      onClose={handleMenuClose}
      open={menuOpen}
      onMouseEnter={handleMenuOpen}
      onMouseLeave={handleMenuClose}
      offset={menuOffset}
      origin="right center"
      anchor={
        <MenuItem icon="caret-right" iconPlacement="end" iconProps={iconProps}>
          Tenant: {curTenantName || 'Current Tenant'}
        </MenuItem>
      }
    />
  )
}

export default TenantMenu
