import { AppPlugins, pluginRoutePrefix } from 'app/constants'
import {
  IVirtualMachineDetailsPageTabs,
  VirtualMachineCreateTypes,
  VirtualMachineResourceTypes,
} from 'app/plugins/kubevirt/components/virtual-machines/model'
import { IVirtualMachineInstanceDetailsPageTabs } from 'app/plugins/kubevirt/components/virtual-machines/vmi-model'
import { Route } from 'core/plugins/route'
import { IPodDetailsPageTabs } from 'k8s/components/pods/model'
import { IServiceDetailsPageTabs } from 'k8s/components/services/model'
import { INetworkPluginDetailsPageTabs } from 'k8s/components/networking/plugins/model'
import { INetworkAttachmentDefinitionsDetailsPageTabs } from 'k8s/components/networking/network/model'
import { IHostConfigurationDetailsPageTabs } from 'k8s/components/networking/host-configs/model'
import { IConfigMapsTabs } from 'k8s/components/config-maps/selectors'
import { ISecretsDetailsPageTabs } from 'k8s/components/configuration/secrets/model'
import { IResourceQuotaDetailsPageTabs } from 'k8s/components/configuration/resource-quotas/model'
import { ILimitRangeDetailsPageTabs } from 'k8s/components/configuration/limit-ranges/model'
import { IHorizontalPodAutoscalerDetailsPageTabs } from 'k8s/components/configuration/horizontal-pod-autoscaler/model'
import { IPodDisruptionBudgetDetailsPageTabs } from 'k8s/components/configuration/pod-disruption-budgets/model'
import { IEndpointDetailsPageTabs } from 'k8s/components/network/endpoints/model'
import { IIngressDetailsPageTabs } from 'k8s/components/ingresses/model'
import { INetworkPolicyDetailsPageTabs } from 'k8s/components/network/network-policies/model'

const defaultParams = {
  plugin: AppPlugins.KubeVirt,
}

const metadata = {
  showGlobalFilters: true,
}

export const kubevirtRoutes = {
  kubevirt: {
    dashboard: Route.register({
      url: `${pluginRoutePrefix}/dashboard/overview`,
      id: 'kubevirtDashboard',
      breadcrumbs: new Map([
        ['dashboards', null],
        ['overview', 'kubevirtDashboard'],
      ]),
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'Kubevirt:Dashboard',
    }),
    overview: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/virtual-machines`,
      id: 'vmsOverview',
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:Overview',
      metadata,
    }),
  },
  kubevirtCluster: {
    list: Route.register({
      url: `${pluginRoutePrefix}/dashboard/clusters`,
      id: 'kubevirtClustersList',
      breadcrumbs: new Map([
        ['dashboards', null],
        ['clusters', 'kubevirtClustersList'],
      ]),
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'Kubevirt:Clusters:List',
    }),
    detail: Route.register({
      url: `${pluginRoutePrefix}/dashboard/clusters/:id/:tab`,
      id: 'kubevirtClusterDetails',
      breadcrumbs: new Map([
        ['dashboards', null],
        ['clusters', 'kubevirtClustersList'],
        [':id', 'kubevirtClusterDetails'],
      ]),
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        tab: 'nodes',
      },
      name: 'Kubevirt:Clusters:Details',
    }),
  },
  kubevirtNodes: {
    list: Route.register({
      url: `${pluginRoutePrefix}/dashboard/nodes`,
      id: 'kubevirtNodesList',
      breadcrumbs: new Map([
        ['dashboards', null],
        ['nodes', 'kubevirtNodesList'],
      ]),
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'Kubevirt:Nodes:List',
    }),
    detail: Route.register({
      url: `${pluginRoutePrefix}/dashboard/nodes/:id`,
      id: 'kubevirtNodeDetails',
      breadcrumbs: new Map([
        ['dashboard', null],
        ['nodes', 'kubevirtNodesList'],
        [':id', 'kubevirtNodeDetails'],
      ]),
      name: 'Kubevirt:Nodes:Details',
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
    }),
  },
  virtualMachineInstances: {
    details: Route.register<{
      clusterId: string
      name: string
      tab?: IVirtualMachineInstanceDetailsPageTabs
    }>({
      id: 'vmiDetails',
      url: `${pluginRoutePrefix}/virtual-machines/clusters/:clusterId/virtual-machine-instances/:name/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['virtual machines', 'vmsOverview'],
        [':name', 'vmiDetails'],
      ]),
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        tab: 'overview',
      },
      name: 'VirtualMachineInstances:Details',
      tab: 'tab',
      metadata,
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/add/new`,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        createType: VirtualMachineResourceTypes.VirtualMachine,
      },
      name: 'VirtualMachineInstances:Add:NewVM',
      metadata,
    }),
  },
  // These routes will be changed when adding vm/vmi details, for now just
  // need them not to conflict with above routes
  virtualMachines: {
    details: Route.register<{
      clusterId: string
      id: string
      tab?: IVirtualMachineDetailsPageTabs
    }>({
      id: 'vmDetails',
      url: `${pluginRoutePrefix}/virtual-machines/clusters/:clusterId/virtual-machines/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['virtual machines', 'vmsOverview'],
        [':id', 'vmDetails'],
      ]),
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        tab: 'overview',
      },
      name: 'VirtualMachines:Details',
      tab: 'tab',
      metadata,
    }),
    create: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/create/new`,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:Create:NewVM',
      metadata,
    }),
    edit: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/clusters/:clusterId/vms/edit/:id`,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:VM:Edit',
      metadata,
    }),
  },
  liveMigrations: {
    overview: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/live-migrations`,
      id: 'liveMigrationsOverview',
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:LiveMigrations',
      metadata,
    }),
  },
  instanceTypes: {
    list: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/instance-types`,
      id: 'instanceTypesList',
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:InstanceTypes',
      metadata,
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/instance-types/new`,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:InstanceTypes:Add',
      metadata,
    }),
  },
  events: {
    list: Route.register({
      url: `${pluginRoutePrefix}/events`,
      id: 'kubevirtEventsList',
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'Kubevirt:Events',
    }),
  },
  // The url must be slightly different from kubernetes for all these alternate
  // kubevirt pages or else breadcrumbs do not work
  kubevirtStorage: {
    storageClasses: {
      list: Route.register({
        id: 'kubevirtStorageClassesList',
        url: `${pluginRoutePrefix}/storage/storage-classes`,
        breadcrumbs: new Map([
          ['storage', null],
          ['storage-classes', 'kubevirtStorageClassesList'],
        ]),
        metadata,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
        },
        name: 'Storage:StorageClasses:List',
      }),
      details: Route.register<{ clusterId: string; id: string; tab?: string }>({
        id: 'kubevirtStorageClassDetails',
        url: `${pluginRoutePrefix}/storage/clusters/:clusterId/storage-classes/:id/:tab`,
        breadcrumbs: new Map([
          ['storage', null],
          ['storage-classes', 'kubevirtStorageClassesList'],
          [':id', 'kubevirtStorageClassDetails'],
        ]),
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
          tab: 'overview',
        },
        name: 'Storage:StorageClasses:Details',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/storage/storage-classes/add`,
        breadcrumbs: new Map([
          ['storage', null],
          ['storage-classes', 'kubevirtStorageClassesList'],
          ['add', 'kubevirtStorageClassesList'],
        ]),
        metadata,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
        },
        name: 'Storage:StorageClasses:Add',
      }),
    },
    csiDrivers: Route.register({
      id: 'kubevirtCsiDriversList',
      url: `${pluginRoutePrefix}/storage/csi-drivers`,
      breadcrumbs: new Map([
        ['storage', null],
        ['csi-drivers', 'kubevirtCsiDriversList'],
      ]),
      metadata,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'Storage:CSIDrivers:List',
    }),
    persistentVolumes: {
      list: Route.register({
        id: 'kubevirtPersistentVolumesList',
        url: `${pluginRoutePrefix}/storage/persistent-volumes`,
        breadcrumbs: new Map([
          ['storage', null],
          ['persistent-volumes', 'kubevirtPersistentVolumesList'],
        ]),
        metadata,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
        },
        name: 'Storage:PersistentVolumes:List',
      }),
      details: Route.register<{ clusterId: string; id: string; tab?: string }>({
        id: 'kubevirtPersistentVolumeDetails',
        url: `${pluginRoutePrefix}/storage/clusters/:clusterId/persistent-volumes/:id/:tab`,
        breadcrumbs: new Map([
          ['storage', null],
          ['persistent-volumes', 'kubevirtPersistentVolumesList'],
          [':id', 'kubevirtPersistentVolumeDetails'],
        ]),
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
          tab: 'overview',
        },
        name: 'Storage:PersistentVolumes:Details',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/storage/persistent-volumes/add`,
        breadcrumbs: new Map([
          ['storage', null],
          ['persistent-volumes', 'kubevirtPersistentVolumesList'],
          ['add', 'kubevirtPersistentVolumesList'],
        ]),
        metadata,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
        },
        name: 'Storage:PersistentVolumes:Add',
      }),
    },
    persistentVolumeClaims: {
      list: Route.register({
        id: 'kubevirtPersistentVolumeClaimsList',
        url: `${pluginRoutePrefix}/storage/persistent-volume-claims`,
        breadcrumbs: new Map([
          ['storage', null],
          ['persistent-volume-claims', 'kubevirtPersistentVolumeClaimsList'],
        ]),
        metadata,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
        },
        name: 'Storage:PersistentVolumeClaims:List',
      }),
      details: Route.register<{ clusterId: string; id: string; tab?: string }>({
        id: 'kubevirtPersistentVolumeClaimDetails',
        url: `${pluginRoutePrefix}/storage/clusters/:clusterId/persistent-volume-claims/:id/:tab`,
        breadcrumbs: new Map([
          ['storage', null],
          ['persistent-volume-claims', 'kubevirtPersistentVolumeClaimsList'],
          [':id', 'kubevirtPersistentVolumeClaimDetails'],
        ]),
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
          tab: 'overview',
        },
        name: 'Storage:PersistentVolumeClaims:Details',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/storage/persistent-volume-claims/add`,
        breadcrumbs: new Map([
          ['storage', null],
          ['persistent-volume-claims', 'kubevirtPersistentVolumeClaimsList'],
          ['add', 'kubevirtPersistentVolumeClaimsList'],
        ]),
        metadata,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
        },
        name: 'Storage:PersistentVolumeClaims:Add',
      }),
    },
    dataVolumes: {
      list: Route.register({
        id: 'kubevirtDataVolumesList',
        url: `${pluginRoutePrefix}/storage/data-volumes`,
        breadcrumbs: new Map([
          ['storage', null],
          ['data-volumes', 'kubevirtDataVolumesList'],
        ]),
        metadata,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
        },
        name: 'Storage:DataVolumes:List',
      }),
      details: Route.register<{ clusterId: string; id: string; tab?: string }>({
        id: 'kubevirtDataVolumeDetails',
        url: `${pluginRoutePrefix}/storage/clusters/:clusterId/data-volumes/:id/:tab`,
        breadcrumbs: new Map([
          ['storage', null],
          ['data-volumes', 'kubevirtDataVolumesList'],
          [':id', 'kubevirtDataVolumeDetails'],
        ]),
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
          tab: 'overview',
        },
        name: 'Storage:DataVolumes:Details',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/storage/data-volumes/add`,
        breadcrumbs: new Map([
          ['storage', null],
          ['data-volumes', 'kubevirtDataVolumesList'],
          ['add', 'kubevirtDataVolumesList'],
        ]),
        metadata,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
        },
        name: 'Storage:DataVolumes:Add',
      }),
    },
  },
  kubevirtPods: {
    list: Route.register({
      url: `${pluginRoutePrefix}/workloads/pods`,
      id: 'kubevirtPodsList',
      breadcrumbs: new Map([
        ['workloads', null],
        ['pods', 'kubevirtPodsList'],
      ]),
      metadata,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'Pods:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/workloads/pods/add`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['pods', 'kubevirtPodsList'],
        ['add', 'kubevirtPodsList'],
      ]),
      metadata,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'Pods:Add',
    }),
    details: Route.register<{ clusterId: string; id: string; tab?: IPodDetailsPageTabs }>({
      id: 'kubevirtPodDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/pods/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['pods', 'kubevirtPodsList'],
        [':id', 'kubevirtPodDetails'],
      ]),
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        tab: 'overview',
      },
      name: 'Pods:Details',
      tab: 'tab',
    }),
    container: {
      details: Route.register<{ clusterId: string; id: string; containerName: string }>({
        id: 'kubevirtPodContainerDetails',
        url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/pods/:id/containers/:containerName`,
        breadcrumbs: new Map([
          ['workloads', null],
          ['pods', 'podsList'],
          [':id', 'kubevirtPodDetails'],
          [':containerName', 'kubevirtPodContainerDetails'],
        ]),
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
        },
        name: 'Pods:Details:Containers:Details',
      }),
    },
  },
  kubevirtServices: {
    list: Route.register({
      id: 'kubevirtServicesList',
      url: `${pluginRoutePrefix}/workloads/services`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['services', 'kubevirtServicesList'],
      ]),
      metadata,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'Services:List',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/workloads/services/add`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['services', 'kubevirtServicesList'],
        ['add', 'kubevirtServicesList'],
      ]),
      metadata,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'Services:Add',
    }),
    details: Route.register<{ clusterId: string; id: string; tab?: IServiceDetailsPageTabs }>({
      id: 'kubevirtServiceDetails',
      url: `${pluginRoutePrefix}/workloads/clusters/:clusterId/services/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['services', 'kubevirtServicesList'],
        [':id', 'kubevirtServiceDetails'],
      ]),
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        tab: 'overview',
      },
      name: 'Services:Details',
    }),
  },
  kubevirtNetworking: {
    overview: Route.register({
      url: `${pluginRoutePrefix}/networking/overview`,
      id: 'kubevirtNetworkingOverview',
      breadcrumbs: new Map([
        ['networking', null],
        ['overview', 'kubevirtNetworkingOverview'],
      ]),
      metadata,
      defaultParams,
      name: 'Networking:Overview',
    }),
    network: Route.register({
      url: `${pluginRoutePrefix}/networking/network-attachment-definitions`,
      id: 'kubevirtNetworkAttachmentDefinitions',
      breadcrumbs: new Map([
        ['networking', null],
        ['network-attachment-definitions', 'kubevirtNetworkAttachmentDefinitions'],
      ]),
      metadata,
      defaultParams,
      name: 'Network',
    }),
    addNetwork: Route.register({
      url: `${pluginRoutePrefix}/networking/network-attachment-definitions/add`,
      breadcrumbs: new Map([
        ['networking', null],
        ['network-attachment-definitions', 'kubevirtNetworkAttachmentDefinitions'],
        ['add', 'kubevirtNetworkAttachmentDefinitions'],
      ]),
      metadata,
      defaultParams,
      name: 'Networking:Network:Add',
    }),
    networkDetails: Route.register<{
      clusterId: string
      id: string
      tab?: INetworkAttachmentDefinitionsDetailsPageTabs
    }>({
      id: 'kubevirtNetworkAttachmentDefinitionsDetails',
      url: `${pluginRoutePrefix}/networking/clusters/:clusterId/network-attachment-definitions/:id/:tab`,
      breadcrumbs: new Map([
        ['networking', null],
        ['network-attachment-definitions', 'kubevirtNetworkAttachmentDefinitions'],
        [':id', 'kubevirtNetworkAttachmentDefinitionsDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'Networking:Network:Details',
      tab: 'tab',
    }),
    plugins: Route.register({
      url: `${pluginRoutePrefix}/networking/plugins`,
      id: 'kubevirtNetworkPlugins',
      breadcrumbs: new Map([
        ['networking', null],
        ['Plugin Configurations', 'kubevirtNetworkPlugins'],
      ]),
      metadata,
      defaultParams,
      name: 'Networking:Plugins',
    }),
    addPlugin: Route.register({
      url: `${pluginRoutePrefix}/networking/plugins/add`,
      breadcrumbs: new Map([
        ['networking', null],
        ['Plugin Configurations', 'kubevirtNetworkPlugins'],
        ['add', 'kubevirtNetworkPlugins'],
      ]),
      metadata,
      defaultParams,
      name: 'Networking:Plugins:Add',
    }),
    pluginDetails: Route.register<{
      clusterId: string
      id: string
      tab?: INetworkPluginDetailsPageTabs
    }>({
      id: 'kubevirtNetworkPluginsDetails',
      url: `${pluginRoutePrefix}/networking/clusters/:clusterId/plugins/:id/:tab`,
      breadcrumbs: new Map([
        ['networking', null],
        ['Plugin Configurations', 'kubevirtNetworkPlugins'],
        [':id', 'kubevirtNetworkPluginsDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'Networking:Plugins:Details',
      tab: 'tab',
    }),
    hostConfigurations: Route.register({
      url: `${pluginRoutePrefix}/networking/host-configurations`,
      id: 'kubevirtHostConfigurations',
      breadcrumbs: new Map([
        ['networking', null],
        ['Host Configurations', 'kubevirtHostConfigurations'],
      ]),
      metadata,
      defaultParams,
      name: 'Networking:HostConfigurations',
    }),
    addHostConfiguration: Route.register({
      url: `${pluginRoutePrefix}/networking/host-configurations/add`,
      breadcrumbs: new Map([
        ['networking', null],
        ['Host Configurations', 'kubevirtHostConfigurations'],
        ['add', 'kubevirtHostConfigurations'],
      ]),
      metadata,
      defaultParams,
      name: 'Newtorking:HostConfigurations:Add',
    }),
    hostConfigurationDetails: Route.register<{
      clusterId: string
      id: string
      tab?: IHostConfigurationDetailsPageTabs
    }>({
      id: 'hostConfigurationDetails',
      url: `${pluginRoutePrefix}/networking/clusters/:clusterId/host-configurations/:id/:tab`,
      breadcrumbs: new Map([
        ['networking', null],
        ['Host Configurations', 'kubevirtHostConfigurations'],
        [':id', 'hostConfigurationDetails'],
      ]),
      metadata,
      defaultParams: {
        ...defaultParams,
        tab: 'overview',
      },
      name: 'Networking:HostConfigurations:Details',
      tab: 'tab',
    }),
    endpoints: {
      list: Route.register({
        url: `${pluginRoutePrefix}/networking/endpoints`,
        id: 'kubevirtEndpointsList',
        breadcrumbs: new Map([
          ['networking', null],
          ['Endpoints', 'kubevirtEndpointsList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtEndpoints:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/networking/endpoints/add`,
        breadcrumbs: new Map([
          ['networking', null],
          ['Endpoints', 'kubevirtEndpointsList'],
          ['add', 'kubevirtEndpointsList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtNetworking:Endpoints:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: IEndpointDetailsPageTabs
      }>({
        id: 'kubevirtEndpointDetails',
        url: `${pluginRoutePrefix}/networking/clusters/:clusterId/endpoints/:id/:tab`,
        breadcrumbs: new Map([
          ['networking', null],
          ['Endpoints', 'kubevirtEndpointsList'],
          [':id', 'kubevirtEndpointDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'KubevirtEndpoints:Details',
        tab: 'tab',
      }),
    },
    ingresses: {
      list: Route.register({
        url: `${pluginRoutePrefix}/networking/ingresses`,
        id: 'kubevirtIngressesList',
        breadcrumbs: new Map([
          ['networking', null],
          ['Ingresses', 'kubevirtIngressesList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtIngresses:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/networking/ingresses/add`,
        breadcrumbs: new Map([
          ['networking', null],
          ['Ingresses', 'kubevirtIngressesList'],
          ['add', 'kubevirtIngressesList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtNetworking:Ingresses:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: IIngressDetailsPageTabs
      }>({
        id: 'kubevirtIngressDetails',
        url: `${pluginRoutePrefix}/networking/clusters/:clusterId/ingresses/:id/:tab`,
        breadcrumbs: new Map([
          ['networking', null],
          ['Ingresses', 'kubevirtIngressesList'],
          [':id', 'kubevirtIngressDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'KubevirtIngresses:Details',
        tab: 'tab',
      }),
    },
    networkPolicies: {
      list: Route.register({
        url: `${pluginRoutePrefix}/networking/network-policies`,
        id: 'kubevirtNetworkPoliciesList',
        breadcrumbs: new Map([
          ['networking', null],
          ['Network Policies', 'kubevirtNetworkPoliciesList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtNetworkPolicies:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/networking/network-policies/add`,
        breadcrumbs: new Map([
          ['networking', null],
          ['Network Policies', 'kubevirtNetworkPoliciesList'],
          ['add', 'kubevirtNetworkPoliciesList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtNetworking:NetworkPolicies:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: INetworkPolicyDetailsPageTabs
      }>({
        id: 'kubevirtNetworkPolicyDetails',
        url: `${pluginRoutePrefix}/networking/clusters/:clusterId/network-policies/:id/:tab`,
        breadcrumbs: new Map([
          ['networking', null],
          ['Network Policies', 'kubevirtNetworkPoliciesList'],
          [':id', 'kubevirtNetworkPolicyDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'KubevirtNetworkPolicies:Details',
        tab: 'tab',
      }),
    },
  },
  kubevirtConfiguration: {
    configMaps: {
      list: Route.register({
        url: `${pluginRoutePrefix}/configuration/config-maps`,
        id: 'kubevirtConfigMapsList',
        breadcrumbs: new Map([
          ['configuration', null],
          ['Config Maps', 'kubevirtConfigMapsList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtConfigMaps:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/configuration/config-maps/add`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Config Maps', 'kubevirtConfigMapsList'],
          ['add', 'kubevirtConfigMapsList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtConfiguration:ConfigMaps:Add',
      }),
      details: Route.register<{ clusterId: string; id: string; tab?: IConfigMapsTabs }>({
        id: 'kubevirtConfigMapDetails',
        url: `${pluginRoutePrefix}/configuration/clusters/:clusterId/config-maps/:id/:tab`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Config Maps', 'kubevirtConfigMapsList'],
          [':id', 'kubevirtConfigMapDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'KubevirtConfigMaps:Details',
        tab: 'tab',
      }),
    },
    secrets: {
      list: Route.register({
        url: `${pluginRoutePrefix}/configuration/secrets`,
        id: 'kubevirtSecretsList',
        breadcrumbs: new Map([
          ['configuration', null],
          ['Secrets', 'kubevirtSecretsList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtSecrets:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/configuration/secrets/add`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Secrets', 'kubevirtSecretsList'],
          ['add', 'kubevirtSecretsList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtConfiguration:Secrets:Add',
      }),
      details: Route.register<{ clusterId: string; id: string; tab?: ISecretsDetailsPageTabs }>({
        id: 'kubevirtSecretDetails',
        url: `${pluginRoutePrefix}/configuration/clusters/:clusterId/secrets/:id/:tab`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Secrets', 'kubevirtSecretsList'],
          [':id', 'kubevirtSecretDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'KubevirtSecrets:Details',
        tab: 'tab',
      }),
    },
    resourceQuotas: {
      list: Route.register({
        url: `${pluginRoutePrefix}/configuration/resource-quotas`,
        id: 'kubevirtResourceQuotasList',
        breadcrumbs: new Map([
          ['configuration', null],
          ['Resource Quotas', 'kubevirtResourceQuotasList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtResourceQuotas:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/configuration/resource-quotas/add`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Resource Quotas', 'kubevirtResourceQuotasList'],
          ['add', 'kubevirtResourceQuotasList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtConfiguration:ResourceQuotas:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: IResourceQuotaDetailsPageTabs
      }>({
        id: 'kubevirtResourceQuotaDetails',
        url: `${pluginRoutePrefix}/configuration/clusters/:clusterId/resource-quotas/:id/:tab`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Resource Quotas', 'kubevirtResourceQuotasList'],
          [':id', 'kubevirtResourceQuotaDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'KubevirtResourceQuotas:Details',
        tab: 'tab',
      }),
    },
    limitRanges: {
      list: Route.register({
        url: `${pluginRoutePrefix}/configuration/limit-ranges`,
        id: 'kubevirtLimitRangesList',
        breadcrumbs: new Map([
          ['configuration', null],
          ['Limit Ranges', 'kubevirtLimitRangesList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtLimitRanges:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/configuration/limit-ranges/add`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Limit Ranges', 'kubevirtLimitRangesList'],
          ['add', 'kubevirtLimitRangesList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtConfiguration:LimitRanges:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: ILimitRangeDetailsPageTabs
      }>({
        id: 'kubevirtLimitRangeDetails',
        url: `${pluginRoutePrefix}/configuration/clusters/:clusterId/limit-ranges/:id/:tab`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Limit Ranges', 'kubevirtLimitRangesList'],
          [':id', 'kubevirtLimitRangeDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'KubevirtLimitRanges:Details',
        tab: 'tab',
      }),
    },
    horizontalPodAutoscalers: {
      list: Route.register({
        url: `${pluginRoutePrefix}/configuration/horizontal-pod-autoscalers`,
        id: 'kubevirtHorizontalPodAutoscalersList',
        breadcrumbs: new Map([
          ['configuration', null],
          ['Horizontal Pod Autoscalers', 'kubevirtHorizontalPodAutoscalersList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtHorizontalPodAutoscalers:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/configuration/horizontal-pod-autoscalers/add`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Horizontal Pod Autoscalers', 'kubevirtHorizontalPodAutoscalersList'],
          ['add', 'kubevirtHorizontalPodAutoscalersList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtConfiguration:HorizontalPodAutoscalers:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: IHorizontalPodAutoscalerDetailsPageTabs
      }>({
        id: 'kubevirtHorizontalPodAutoscalerDetails',
        url: `${pluginRoutePrefix}/configuration/clusters/:clusterId/horizontal-pod-autoscalers/:id/:tab`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Horizontal Pod Autoscalers', 'kubevirtHorizontalPodAutoscalersList'],
          [':id', 'kubevirtHorizontalPodAutoscalerDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'KubevirtHorizontalPodAutoscalers:Details',
        tab: 'tab',
      }),
    },
    podDisruptionBudgets: {
      list: Route.register({
        url: `${pluginRoutePrefix}/configuration/pod-disruption-budgets`,
        id: 'kubevirtPodDisruptionBudgetsList',
        breadcrumbs: new Map([
          ['configuration', null],
          ['Pod Disruption Budgets', 'kubevirtPodDisruptionBudgetsList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtPodDisruptionBudgets:List',
      }),
      add: Route.register({
        url: `${pluginRoutePrefix}/configuration/pod-disruption-budgets/add`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Pod Disruption Budgets', 'kubevirtPodDisruptionBudgetsList'],
          ['add', 'kubevirtPodDisruptionBudgetsList'],
        ]),
        metadata,
        defaultParams,
        name: 'KubevirtConfiguration:PodDisruptionBudgets:Add',
      }),
      details: Route.register<{
        clusterId: string
        id: string
        tab?: IPodDisruptionBudgetDetailsPageTabs
      }>({
        id: 'kubevirtPodDisruptionBudgetDetails',
        url: `${pluginRoutePrefix}/configuration/clusters/:clusterId/pod-disruption-budgets/:id/:tab`,
        breadcrumbs: new Map([
          ['configuration', null],
          ['Pod Disruption Budgets', 'kubevirtPodDisruptionBudgetsList'],
          [':id', 'kubevirtPodDisruptionBudgetDetails'],
        ]),
        defaultParams: {
          ...defaultParams,
          tab: 'overview',
        },
        name: 'KubevirtPodDisruptionBudgets:Details',
        tab: 'tab',
      }),
    },
  },
}
