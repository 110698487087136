import { setActiveRegion } from 'api-client/helpers'
import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import useReactRouter from 'use-react-router'
import { propEq, find, pipe, head, prop, isEmpty } from 'ramda'
import ApiClient from 'api-client/ApiClient'
import { appUrlRoot } from 'app/constants'
import { useDispatch } from 'react-redux'
import useScopedPreferences from 'core/session/useScopedPreferences'
import ListMenu from 'core/elements/menu/ListMenu'
import MenuItem from 'core/elements/menu/MenuItem'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { useSelector } from 'react-redux'
import useListAction from 'core/hooks/useListAction'
import { listRegions } from 'app/plugins/infrastructure/components/regions/actions'
import { regionsSelector } from 'app/plugins/infrastructure/components/regions/selectors'

const currentSectionRegex = new RegExp(`^${appUrlRoot}/[^/]+/?[^/]*`, 'i')

const menuOffset = {
  vertical: -48, // height of anchor <MenuItem> is 48
  horizontal: 24,
}
const iconProps = {
  solid: true,
}

const RegionMenu = (props) => {
  const { keystone } = ApiClient.getInstance()
  const { history, location } = useReactRouter()
  const { pathname, hash = '' } = location
  const { prefs, updatePrefs } = useScopedPreferences()
  const { currentTenant, currentRegion } = prefs
  const [loading, setLoading] = useState(false)
  const { loading: loadingRegions, reload: reloadRegions } = useListAction(listRegions)
  const regions = useSelector(regionsSelector)
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { activeRegion } = session
  const dispatch = useDispatch()
  const [menuOpen, setMenuOpen] = useState(false)
  const timeoutRef = useRef(null)

  const curRegionId = useMemo(() => {
    if (currentRegion && find(propEq('id', currentRegion), regions)) {
      return currentRegion
    }
    return pipe(head, prop('id'))(regions)
  }, [regions, currentRegion, regions])

  useEffect(() => {
    // Reload region when changing the current tenant
    if (isEmpty(regions) && loadingRegions === undefined) {
      reloadRegions(true)
    }
  }, [currentTenant, regions])

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const handleRegionSelect = useCallback(
    async ({ id: regionId }) => {
      if (regionId === curRegionId) {
        return
      }
      updateRegion(regionId)
    },
    [curRegionId],
  )

  const updateRegion = useCallback(
    async (regionId) => {
      setLoading(true)
      try {
        const [currentSection = appUrlRoot] = currentSectionRegex.exec(pathname + hash) || []
        updatePrefs({ currentRegion: regionId })
        await setActiveRegion(regionId)

        // Redirect to the root of the current section (there's no need to reload all the app)
        // Must be done prior to updatePrefs until improved url management system is in place
        // bc of listener in AuthenticatedContainer which may also redirect
        history.push(currentSection)
      } catch (err) {
        console.error(err)
      }
      setLoading(false)
    },
    [regions, pathname, hash, curRegionId],
  )

  const handleMenuClose = useCallback(() => {
    // Small timeout to give the mouse time to reach the sub menu before we hide the menu
    timeoutRef.current = setTimeout(() => setMenuOpen(false), 250)
  }, [])
  const handleMenuOpen = useCallback(() => {
    clearTimeout(timeoutRef.current)
    setMenuOpen(true)
  }, [])

  const regionNames = useMemo(() => regions.map((x) => ({ name: x.id, id: x.id })), [regions])

  return (
    <ListMenu
      list={regionNames}
      onClick={handleRegionSelect}
      onClose={handleMenuClose}
      open={menuOpen}
      onMouseEnter={handleMenuOpen}
      onMouseLeave={handleMenuClose}
      offset={menuOffset}
      origin="right center"
      anchor={
        <MenuItem icon="caret-right" iconPlacement="end" iconProps={iconProps}>
          Region: {activeRegion || 'Current Region'}
        </MenuItem>
      }
    />
  )
}

export default RegionMenu
