import { IDataKeys } from 'k8s/datakeys.model'
import { isNil } from 'ramda'
import { ensureArray } from 'utils/fp'
import Action, { ActionConfig, ArrayElement } from 'core/actions/Action'
import { cacheActions } from 'core/caching/cacheReducers'
import store from 'app/store'

const { dispatch } = store

class CustomAction<
  D extends keyof IDataKeys,
  P extends Record<string, unknown> = Record<string, unknown>,
  R = ArrayElement<IDataKeys[D]>
> extends Action<D, P, R | void> {
  public get name() {
    return this.actionName
  }

  private readonly actionProcessor: (result: R | void, params: P) => Promise<void> | void

  constructor(
    private actionName: string,
    public readonly callback: (params: P) => Promise<R | void>,
    processor?: (result: R, params: P) => Promise<void> | void,
    config?: Partial<ActionConfig<D>>,
    defaultParams?: P,
  ) {
    super(callback, config, defaultParams)
    this.actionProcessor = processor
  }

  protected async preProcess(params: P): Promise<void> {
    const { cacheKey } = this.config
    dispatch(cacheActions.setUpdating({ cacheKey, updating: true }))
  }

  protected postProcess = async (result, params: P) => {
    const { cacheKey } = this.config

    if (this.actionProcessor) {
      await this.actionProcessor(result, params)
      // Default behavior if no custom processor is provided
    } else if (!isNil(result)) {
      dispatch(
        cacheActions.replaceAll({
          cacheKey,
          items: ensureArray(result),
        }),
      )
    }
    dispatch(cacheActions.setUpdating({ cacheKey, updating: false }))
  }

  protected handleError(err, params: P, options) {
    const { cacheKey } = this.config
    // Without this, if an error occurs loading can be stuck as true
    dispatch(cacheActions.setUpdating({ cacheKey, updating: false }))
    return super.handleError(err, params, options)
  }
}

export default CustomAction
