import moize from 'moize'
import { pipe, sortBy, when, is, identity, reverse, toLower } from 'ramda'
import { stringIfNil, pathStr } from 'utils/fp'
import { defaultUniqueIdentifier } from 'app/constants'

export enum OrderDirection {
  asc = 'asc',
  desc = 'desc',
}
type OrderDirectionString = keyof typeof OrderDirection

export type SortConfig = {
  orderBy?: string
  orderDirection?: OrderDirection | OrderDirectionString
}

// @deprecated use Grid sorting instead
type FunctionType = <T>(config: SortConfig) => (x: T[]) => T[]
const defaultConfig: SortConfig = {}
const createSorter: FunctionType = moize.deep(<T>(config = defaultConfig) => {
  const { orderBy = defaultUniqueIdentifier, orderDirection = 'asc' } = config
  const orderValue = orderBy ?? defaultUniqueIdentifier
  const sortFn = sortBy<T>(pipe(pathStr(orderValue), stringIfNil, when(is(String), toLower)))
  const directionFn = orderDirection === 'asc' ? identity : reverse

  return pipe<T[], T[], T[]>(sortFn, directionFn)
})

export default createSorter
