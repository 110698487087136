import { useEffect, useCallback, useState, useMemo } from 'react'
import { generateObjMemoizer } from 'utils/misc'

const useFetchApi = (loaderFn, params, ...args) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const memoizeParams = useMemo(() => generateObjMemoizer(), [])
  const memoizedParams = memoizeParams(params)
  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const result = await loaderFn(memoizedParams, ...args)
      setData(result)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }, [loaderFn, memoizedParams])

  useEffect(() => {
    loadData()
  }, [])

  return {
    error,
    loading,
    items: data,
    reload: loadData,
  }
}

export default useFetchApi
