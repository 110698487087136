import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  note: {
    borderTop: `1px solid ${theme.components.card.activeBorder}`,
    background: theme.components.card.activeBackground,
    padding: 16,
    display: 'grid',
    gap: 8,
  },
}))

interface Props {
  children: any
  className?: string
  title?: string
}

export default function Info({ children, className = undefined, title = '' }: Props) {
  const classes = useStyles()

  return (
    <div className={classes.note}>
      {title && <Text variant="caption1">{title}</Text>}
      <div>{children}</div>
    </div>
  )
}
