import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import clsx from 'clsx'
import ExternalLink from 'core/components/ExternalLink'

const useStyles = makeStyles<Theme>((theme) => ({
  dropdownContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  dropdownLabel: {
    display: 'inline-grid',
    gridTemplateColumns: 'auto max-content',
    alignItems: 'center',
    gap: 8,
    padding: '8px 16px',
    background: theme.components.button.secondary.background,
    border: `1px solid ${theme.components.button.secondary.border}`,
    cursor: 'pointer',
    borderRadius: 4,
  },
  text: {
    color: theme.components.dropdown.color,
  },
  dropdownButtons: {
    position: 'absolute',
    top: 40,
    background: theme.components.button.secondary.background,
    border: `1px solid ${theme.components.button.secondary.border}`,
    borderRadius: 4,
    display: 'grid',
    minWidth: '100%',
    width: 'fit-content',
    zIndex: 100,
    right: 0,
  },
  dropdownButton: {
    '&:hover': {
      textDecoration: 'none',
    },
    '& > span': {
      display: 'grid',
      gridTemplateColumns: '18px auto',
      padding: '8px 16px',
      alignItems: 'center',
      gap: 8,
      cursor: 'pointer',
      '&:hover': {
        background: theme.components.dropdown.selectedBackground,
      },
      '& > span': {
        whiteSpace: 'nowrap',
      },
    },
  },
  icon: {
    marginTop: 2,
    fontWeight: 400,
  },
}))

interface IButton {
  label: string
  icon: string
  onClick?: () => void
  externalLink?: string
}

// Detecting clicks outside the dropdown
const clickOutside = (ref, onClickOutside) => {
  useEffect(() => {
    const modalRef = document.getElementById('modal-portal-root')
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && !modalRef.contains(event.target)) {
        onClickOutside()
      }
    }
    // Bind
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // dispose
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOutside])
}

interface Props {
  buttons: IButton[]
}

const DropdownButton = ({ label, icon, onClick, externalLink }: IButton) => {
  const classes = useStyles()
  return externalLink ? (
    <ExternalLink className={classes.dropdownButton} url={externalLink}>
      <FontAwesomeIcon className={clsx(classes.text, classes.icon)} size="md">
        {icon}
      </FontAwesomeIcon>
      <Text variant="caption1" className={classes.text}>
        {label}
      </Text>
    </ExternalLink>
  ) : (
    <div className={classes.dropdownButton} onClick={onClick}>
      <FontAwesomeIcon className={clsx(classes.text, classes.icon)} size="md">
        {icon}
      </FontAwesomeIcon>
      <Text variant="caption1" className={classes.text}>
        {label}
      </Text>
    </div>
  )
}

// May be possible to combine this component with the ActionsDropdown component
// in the future, though that one is fairly more complicated
export default function DropdownButtons({ buttons = [] }: Props) {
  const classes = useStyles()
  const [isOpen, setOpen] = useState(false)

  const wrapperRef = useRef(null)
  clickOutside(wrapperRef, () => {
    setOpen(false)
  })

  return (
    <div className={classes.dropdownContainer} ref={wrapperRef}>
      <div className={classes.dropdownLabel} onClick={() => setOpen(!isOpen)}>
        <Text variant="caption1" className={classes.text}>
          More Actions
        </Text>
        <FontAwesomeIcon className={classes.text} solid size="sm">
          {isOpen ? 'caret-up' : 'caret-down'}
        </FontAwesomeIcon>
      </div>
      {isOpen && (
        <div className={classes.dropdownButtons}>
          {buttons.map((btn) => {
            return (
              <DropdownButton
                key={btn.label}
                label={btn.label}
                icon={btn.icon}
                onClick={btn.onClick}
                externalLink={btn.externalLink}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
