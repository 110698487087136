import React, { FunctionComponent, useEffect } from 'react'
import clsx from 'clsx'
import { IconButton, SnackbarContent } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'

import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/styles'
import { split, reject, pipe, map, toPairs } from 'ramda'
import { isNilOrEmpty } from 'utils/fp'
import { MessageTypes } from './model'
import Theme from 'core/themes/model'

const variantIcon = {
  [MessageTypes.success]: CheckCircleIcon,
  [MessageTypes.warning]: WarningIcon,
  [MessageTypes.error]: ErrorIcon,
  [MessageTypes.info]: InfoIcon,
}

interface ToastItemProps {
  message: string
  variant: MessageTypes
  onClose: () => void
  toastsTimeout: number

  [key: string]: any // ...rest props
}

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  success: {
    backgroundColor: theme.components.graph.success,
  },
  error: {
    backgroundColor: theme.components.graph.error,
  },
  info: {
    backgroundColor: theme.components.graph.primary,
  },
  warning: {
    backgroundColor: theme.components.graph.warning,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    display: 'inline-block',
  },
  close: {},
}))

const renderMessages: any = pipe<any, any, any, any, any>(
  split('\n'),
  reject(isNilOrEmpty),
  toPairs,
  map(([key, msg]) => <div key={key}>{msg}</div>),
)

const ToastItem: FunctionComponent<ToastItemProps> = ({
  message,
  variant,
  toastsTimeout,
  onClose,
  className,
  ...rest
}) => {
  const classes = useStyles({})
  const Icon = variantIcon[variant]
  useEffect(() => {
    const timeoutId = setTimeout(onClose, toastsTimeout + 2000)
    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <SnackbarContent
      className={clsx(classes.content, classes[variant], className)}
      message={
        <span className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          <div className={classes.text}>{renderMessages(message || '')}</div>
        </span>
      }
      action={
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          size="small"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      }
      {...rest}
    />
  )
}

export default ToastItem
