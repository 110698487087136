import React, { useMemo } from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import Badge, { BadgeVariant } from 'core/elements/badge/Badge'
import Tooltip from 'core/elements/tooltip/Tooltip'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/core/styles'

export interface StatusCellModel {
  variant: BadgeVariant
  label?: string
  tooltipBody?: string | React.ReactNode
}

interface StatusCellIconModel {
  icon?: string
  showIcon?: boolean
  iconTooltip?: string
}

interface BaseGridStatusCellProps<V> {
  dataFn: (value: V) => StatusCellModel
  iconDataFn?: (value: V) => StatusCellIconModel
}

interface GridStatusCellProps<T, V> extends GridCellProps<T, V>, BaseGridStatusCellProps<V> {}

export default function GridStatusCell<T, V = string>({
  value,
  dataFn,
  iconDataFn = undefined,
}: GridStatusCellProps<T, V>) {
  const classes = useStyles()

  const { variant, label = String(value), tooltipBody } = useMemo(() => dataFn(value), [value])
  const iconData = useMemo(() => iconDataFn && iconDataFn(value), [iconDataFn, value])

  return (
    <div className={classes.container}>
      <Badge variant={variant} text={label} tooltipBody={tooltipBody} />
      {iconData ? (
        <Tooltip message={iconData.iconTooltip}>
          <FontAwesomeIcon solid className={classes.icon}>
            {iconData.icon}
          </FontAwesomeIcon>
        </Tooltip>
      ) : null}
    </div>
  )
}

export function createGridStatusCell<T, V = string>({
  dataFn,
  iconDataFn = undefined,
}: BaseGridStatusCellProps<V>) {
  return (props: GridCellProps<T, V>) => {
    return <GridStatusCell<T, V> {...props} dataFn={dataFn} iconDataFn={iconDataFn} />
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'baseline',
  },
  icon: {
    color: theme.palette.yellow.main,
  },
}))
