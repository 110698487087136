import { customThemeKey, defaultThemeState } from 'core/session/themeReducers'
import { pathOr } from 'ramda'
import { createSelector } from '@reduxjs/toolkit'

export const themeSelector = createSelector(
  (state) => pathOr(defaultThemeState, [customThemeKey])(state),
  ({ theme, components }) => {
    return {
      ...theme,
      components,
    }
  },
)
