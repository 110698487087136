import { path, pick, uniq } from 'ramda'
import { cacheStoreKey, loadingStoreKey } from 'core/caching/cacheReducers'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import useDataLoader from './useDataLoader'
import { emptyObj } from 'utils/fp'
import { memoizedObj } from 'utils/misc'
import { entityNamesByKey } from 'k8s/DataKeys'

interface IUseDataLoaderWithMessage {
  loaderFn: any
  params?: { [key: string]: any }
  options?: { [key: string]: any }
  dependentCacheKeys?: string[]
}

// Views sometimes will need to check for loading individually with a selector
// E.g, if dependent data is loading async that we want to give affordance for
export const isDataLoadingSelector = (cacheKey) => (state): boolean =>
  path<boolean>([cacheStoreKey, loadingStoreKey, cacheKey])(state)

const useDataLoaderWithMessage = ({
  loaderFn,
  params = emptyObj,
  options = emptyObj,
  dependentCacheKeys = [],
}: IUseDataLoaderWithMessage) => {
  const { requiredParams = undefined } = options
  const memoizedParams = memoizedObj(params)
  const cacheKeys = useMemo(() => [...dependentCacheKeys, loaderFn.cacheKey], [
    loaderFn,
    dependentCacheKeys,
  ])
  const [data, _, reload] = useDataLoader(loaderFn, params, options)
  const loadingState = useSelector(path([cacheStoreKey, loadingStoreKey]))

  const requiredParamsProvided = useMemo(
    () =>
      !requiredParams ||
      Object.keys(pick(requiredParams, memoizedParams)).length === requiredParams.length,
    [requiredParams, memoizedParams],
  )

  const loadingCacheKeys = useMemo(
    () =>
      uniq(
        cacheKeys.reduce((accum, cacheKey) => {
          if (loadingState?.[cacheKey] || !requiredParamsProvided) {
            return [...accum, entityNamesByKey[cacheKey]]
          }
          return accum
        }, []),
      ),
    [cacheKeys, loadingState],
  )

  const loading = loadingCacheKeys.length > 0
  const message = loading ? `Loading ${loadingCacheKeys.join(', ')}...` : undefined

  return { data, reload, loading, message }
}

export default useDataLoaderWithMessage
