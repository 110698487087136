import React from 'react'
import { withStyles } from '@material-ui/styles'
import clsx from 'clsx'

const styles = (theme) => ({
  wizardButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
})

const WizardButtons = ({ classes, children }) => (
  <div className={classes.wizardButtons}>{children}</div>
)

export default withStyles(styles)(WizardButtons)
