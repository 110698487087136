import React, { useCallback } from 'react'
import Accordion from 'core/components/accordion/Accordion'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { equals, remove, update } from 'ramda'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'

const useStyles = makeStyles<Theme>((theme) => ({
  accordionBody: {
    padding: 32,
    border: `1px solid ${theme.components.accordion.activeBackground}`,
    display: 'grid',
    gap: 32,
  },
  accordionFields: {
    display: 'grid',
    gap: 32,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
  operationLabel: {
    width: 'fit-content',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 8,
  },
  icon: {
    color: theme.components.badge.primary.color,
  },
}))

const RepeatedFieldAccordion = ({
  title,
  id,
  valueArray = [],
  FieldsComponent,
  defaultValues = {},
  onChange,
  addText = 'Add New',
  removeText = 'Delete Item',
}) => {
  const classes = useStyles()

  const configuredValues = valueArray.filter((value) => {
    return !equals(value, defaultValues)
  })

  const addNewValue = useCallback(() => {
    onChange([...valueArray, defaultValues])
  }, [defaultValues, valueArray, onChange])

  const removeValue = useCallback(
    (index) => {
      onChange(remove(index, 1, valueArray))
    },
    [valueArray, onChange],
  )

  const updateField = useCallback(
    (field, index) => (value) => {
      const item = valueArray?.[index]
      const updatedItem = {
        ...item,
        [field]: value,
      }
      const updatedValues = update(index, updatedItem, valueArray)
      onChange(updatedValues)
    },
    [onChange, valueArray],
  )

  // To update more than one property at a time, must use this function
  const updateFields = useCallback(
    (index) => (values) => {
      const item = valueArray?.[index]
      const updatedItem = {
        ...item,
        ...values,
      }
      const updatedValues = update(index, updatedItem, valueArray)
      onChange(updatedValues)
    },
    [onChange, valueArray],
  )

  return (
    <Accordion title={`${title} (${configuredValues?.length})`} id={id}>
      <div className={classes.accordionBody}>
        {valueArray.map((value, idx) => (
          <div className={classes.accordionFields} key={idx}>
            <FieldsComponent
              index={idx}
              values={value}
              updateField={updateField}
              updateFields={updateFields}
              isActive={!equals(value, defaultValues)} // Design calls for only counting fields only when initial state is changed
            />
            {idx > 0 && (
              <div className={classes.operationLabel} onClick={() => removeValue(idx)}>
                <FontAwesomeIcon className={classes.icon} size="xl" solid>
                  circle-minus
                </FontAwesomeIcon>
                <Text variant="body2">{removeText}</Text>
              </div>
            )}
          </div>
        ))}
        <hr className={classes.divider} />
        <div className={classes.operationLabel} onClick={addNewValue}>
          <FontAwesomeIcon className={classes.icon} size="xl" solid>
            circle-plus
          </FontAwesomeIcon>
          <Text variant="body2">{addText}</Text>
        </div>
      </div>
    </Accordion>
  )
}

export default RepeatedFieldAccordion
