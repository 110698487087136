import React from 'react'
import PropTypes from 'prop-types'
import CloseButton from 'core/components/buttons/CloseButton'
import { makeStyles } from '@material-ui/styles'
import Progress from 'core/components/progress/Progress'
import { pick, keys } from 'ramda'
import Text from 'core/elements/Text'
import generateTestId from 'utils/test-helpers'
import { HeaderPrimaryActionPortal, HeaderTitlePortal } from 'core/elements/header/portals'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(5),
  },
  buttonBase: {
    textTransform: 'none',
  },
  gridHeader: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}))

const progressInterfaceKeys = [
  'loading',
  'overlay',
  'inline',
  'renderLoadingImage',
  'renderContentOnMount',
  'message',
  'loadingImage',
  'inlineClassName',
  'loadingImageHeight',
]

const FormWrapper = ({
  backUrl = undefined,
  children,
  title = '',
  className = undefined,
  isUpdateForm = false,
  ...rest
}) => {
  const classes = useStyles({ isUpdateForm })
  const progressProps = pick(progressInterfaceKeys, rest)

  return (
    <>
      <HeaderTitlePortal>
        <Text data-testid={generateTestId(title, 'grid', 'text')} variant="subtitle1">
          {title}
        </Text>
      </HeaderTitlePortal>
      {backUrl && (
        <HeaderPrimaryActionPortal>
          <CloseButton to={backUrl} />
        </HeaderPrimaryActionPortal>
      )}
      <Progress {...progressProps}>
        <div className={className}>{children}</div>
      </Progress>
    </>
  )
}

FormWrapper.propTypes = {
  backUrl: PropTypes.string,
  title: PropTypes.string,
  ...Progress.propTypes,
}

FormWrapper.defaultProps = {
  renderContentOnMount: true,
  message: 'Submitting form...',
}

export default FormWrapper
