import { AppPlugins, appUrlRoot } from 'app/constants'

import { Route } from 'core/plugins/route'

export const publicRoutes = {
  login: Route.register({
    url: `${appUrlRoot}/login`,
    name: 'Login',
  }),
  password: {
    reset: Route.register({ url: `${appUrlRoot}/reset/password`, name: 'Password:Reset' }),
  },
}
